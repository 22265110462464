import { CheckIcon, CloseIcon, SmallAddIcon } from '@chakra-ui/icons';
import { ButtonGroup, Editable, EditableInput, EditablePreview, HStack, IconButton, Input, Spinner, Text, Tooltip, useEditableControls } from '@chakra-ui/react';
import React, { useState } from 'react';

const AddNewOption = ({
  value = '',
  label = 'Add New',
  onSave,
  isLoading = false,
  options,
}) => {
  const [errorText, setErrorText] = useState('');

  const onDuplication = data => {
    if (options.findIndex(d => d.label === data) === -1) {
      setErrorText('')
    } else {
      setErrorText('Name is duplicated')
    }
  }
  function handleCreateNewOption(newOption) {
    const validateNewOption = newOption.trim();
    if (!Boolean(validateNewOption)) {
      setErrorText(`${label} or Skip`);
    } else if (onSave && typeof onSave === 'function') {
      onSave(newOption);
    }
  }

  function renderLeftIcon() {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <SmallAddIcon
        fontSize={'lg'}
        rounded={'full'}
        mt={2}
        mb={2}
        borderWidth={1}
        borderColor={'black'}
        color={'black'} />
    );
  }

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="xs" w="full" spacing={2} mt={2}>
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton
          icon={<CloseIcon boxSize={3} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : null;
  }

  return (
    <Editable
      fontSize={'sm'}
      defaultValue={value}
      // value={value}
      onSubmit={handleCreateNewOption}
      placeholder={label}
      isPreviewFocusable={true}
      selectAllOnFocus={false}>
      <HStack cursor={'pointer'} pl={3} borderWidth={1} borderRadius={'md'}>
        {renderLeftIcon()}
        <Tooltip label="Click to edit">
          <EditablePreview
            py={2}
            px={4}
          />
        </Tooltip>

        <Input
          fontSize={'sm'}
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderWidth: 0,
            borderColor: '#fff',
          }}
          borderColor={'#fff'}
          onKeyUp={e => {
            onDuplication(e.target.value);
          }}
          as={EditableInput}
        />
      </HStack>
      {errorText ? <Text color={'red'}>
        {errorText}
      </Text> : null}
      <EditableControls />
    </Editable>
  );
}

export default React.memo(AddNewOption);