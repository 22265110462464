import { useMediaQuery } from '@chakra-ui/react';

const useCQMediaQuery = props => {
  const [isLargeScreen, isMediumScreen, ] = useMediaQuery([
    '(min-width:1600px)',
    '(min-width:1200px)',
    '(min-width:800px)'
  ])

  if(isLargeScreen) {
    return props.lg;
  } else if(isMediumScreen) {
    return props.md;
  } else {
    return props.sm;
  }
}

export default useCQMediaQuery;
