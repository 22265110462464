/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Text, Tooltip } from '@chakra-ui/react';
import {
  CQReactTable,
  PrimaryButton,
} from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import { STATUS, UPLOAD_BREAKDOWN_STATUS } from '../../../constants/constants';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { DashboardContext } from '../../../providers/DashboardProvider';
import { defaultReactTableProp } from '../../../utils/my_audits.utils';
import { priceConverter } from '../../../utils/priceConverter';
import { replaceRoute } from '../../../utils/common.utils'

const UploadTable = ({ filterParams }) => {
  const navigate = useNavigate();

  const dashboardContext = useContext(DashboardContext);
  const columnHelper = createColumnHelper();

  const [ordering, setOrdering] = useState('');

  useEffect(() => {
    dashboardContext.getUploads.loadAPI();
  }, [dashboardContext.getUploads.showOrdering]);

  useEffect(() => {
    dashboardContext.getUploads.loadAPI();
  }, [filterParams]);

  useEffect(() => {
    dashboardContext.getUploads.setParams({ ordering });
  }, [ordering]);

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    let color = 'black';
    if (data.column.id === 'date' || data.column.id === 'accuracy') {
      color = AppColors.gray;
    }
    return {
      fontFamily: FONT_FAMILY.ptSans,
      color: color,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: 'black',
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'upload_id',
        cell: info => {
          // return <Text>{info.renderValue()}</Text>;

          const chartData = info.row.original;
          const cellValue = info.getValue();
          const isReceived = chartData.status === UPLOAD_BREAKDOWN_STATUS.RECEIVED;
          if (isReceived) {
            return (
              <Text
                _hover={{ textDecoration: 'underline' }}
                cursor={'pointer'}
                onClick={() => {
                  const batchDashboard = replaceRoute(ROUTES.BATCH_DASHBOARD, {
                    batchId: cellValue,
                  });
                  navigate(batchDashboard, {
                    state: { chartData: chartData },
                  });
                }}
              >
                {info.renderValue()}
              </Text>
            );
          }
          return (
            <Tooltip
              hasArrow
              backgroundColor={AppColors.secondary}
              label="Batch is in processing"
              placement="bottom"
            >
              <Text cursor={"pointer"} > {info.renderValue()}</Text>
            </Tooltip>
          );

        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'cq_score',
        header: () => <span>RI Score</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor(row => row.upload_date, {
        id: 'upload_date',
        header: () => <span>Upload Date</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return STATUS[cellValue];
        },
      }),
      columnHelper.accessor('opporturnity', {
        header: () => 'Opportunity',
        id: 'opporturnity',
        cell: info => {
          const chartData = info.row.original;
          const isReceived = chartData.status === UPLOAD_BREAKDOWN_STATUS.RECEIVED;
          const isProcessing = chartData.status === UPLOAD_BREAKDOWN_STATUS.PROCESSING;
          const cellValue = info.getValue();
          if (isProcessing && Number(cellValue) === 0) {
            return <Text color={AppColors.gray}>N/A</Text>;
          } else if (isReceived && Number(cellValue) > 0) {
            return <Text color={'#77C586'}>{priceConverter(cellValue)}</Text>;
          } else if (isReceived && Number(cellValue) === 0) {
            return <Text color={'#77C586'}>$0</Text>;
          } else if (isReceived && Number(cellValue) < 0) {
            return <Text color={'red'}>{`-${priceConverter(-cellValue)}`}</Text>;
          }
        },
      }),
      columnHelper.accessor('charts', {
        header: () => 'No. of Charts',
        id: 'charts',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('urgency', {
        header: () => 'Urgency',
        id: 'urgency',
        cell: info => {
          const cellValue = info.getValue();

          return cellValue ?? <Text color={AppColors.gray}>N/A</Text>;
        },
      }),

      columnHelper.accessor('upload_id', {
        id: 'actions',
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return (
            <PrimaryButton
              bg={AppColors.secondary}
              fontSize={'14px'}
              fontWeight="normal"
              size="md"
              borderRadius="3xl"
              px="10"
              onClick={() => {
                navigate(
                  ROUTES.VIEW_AUDIT_SHEET.replace(
                    ':batchId',
                    info.renderValue()
                  )
                );
              }}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
                outline: `2px solid ${AppColors.secondary}`,
              }}
            >
              View
            </PrimaryButton>
          );
        },
      }),
    ],
    [dashboardContext.isUploadsLoading]
  );

  function handlePreviousPage() {
    dashboardContext.getUploads.loadPrevious();
  }

  function handleNextPage() {
    dashboardContext.getUploads.loadNext();
  }

  function handleSizeChange(size) {
    dashboardContext.getUploads({ size });
  }

  return (
    <Box
      id="fixedTableHeader"
      maxHeight={'400px'}
      overflowY={'scroll'}
      className="tableWraper"
    >
      <CQReactTable
        isLoading={dashboardContext.isUploadsLoading}
        data={dashboardContext.uploads}
        columns={columns}
        {...defaultReactTableProp}
        // {...paginationProps(dashboardContext.getUploads)}
        // getRowProps={() => { }}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        getCellProps={getTableCellProps}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
      {dashboardContext.uploads?.length === 0 &&
        !dashboardContext.isUploadsLoading && (
          <Center>There is no data to display</Center>
        )}
    </Box>
  );
};
export default UploadTable;
