/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  HStack,
  Modal,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  Circle,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import { AddIcon } from '@chakra-ui/icons';
import { MyAccountContext } from '../../../providers/MyAccountProvider';
import {onlyAlphabetsRegex} from '../../../constants/constants';

const accountContactSchema = yup.object({
  is_primary: yup.bool().required(),
  first_name: yup
    .string()
    .required('First name required')
    .matches(onlyAlphabetsRegex, 'First Name must be valid')
    .when('is_primary', {
      is: true,
      then: yup.string().required('First Name is required'),
    }),
  last_name: yup
    .string()
    .required('Last name required')
    .matches(onlyAlphabetsRegex, 'Last Name must be valid')
    .when('is_primary', {
      is: true,
      then: yup.string().required('Last Name is required'),
    }),
  email: yup
    .string()
    .email('Enter valid email')
    .required('Email required')
    .when('is_primary', {
      is: true,
      then: yup.string().email().required('Email is required'),
    }),
});

const EditAccountsModal = props => {
  const {
    isOpen,
    onClose,
    providers,
    getData,
    accountData,
    setPrimaryAccountList,
    setAllAccountList,
    setAllProviderList,
    defaultCheckedAccountValues,
    id,
    user,
  } = props;


  const myAccountContext = useContext(MyAccountContext);

  const HEALTH_SYSTEM = 'HEALTH SYSTEM';
  const HOSPITAL = 'HOSPITAL';
  const DEPARTMENT = 'DEPARTMENT';
  const PHYSICIAN_GROUP = 'PHYSICIANS GROUP';
  const PROVIDER = 'PROVIDER';

  const createAccountSchema = yup.object({
    account_contact: yup.array().of(accountContactSchema).min(1),
  });



  function createNewContact(primary) {
    return {
      first_name: '',
      last_name: '',
      email: '',
      is_primary: primary,
    };
  }

  const form = useForm({
    resolver: yupResolver(createAccountSchema),
    defaultValues: {
      account_contact: !accountData?.length
        ? [createNewContact(true)]
        : accountData,
    },
  });
  const { control, handleSubmit, setValue, getValues, reset } = form;

  const getAccountContactData = () => {
    switch (user) {
      case HEALTH_SYSTEM:
        return myAccountContext.practiceInfoHealthSystem(id);
      case HOSPITAL:
        return myAccountContext.praticeInfoHospital(id);
      case DEPARTMENT:
        return myAccountContext.praticeInfoDepartment(id);
      case PHYSICIAN_GROUP:
        return myAccountContext.praticeInfoPhysicianGroup(id);
      case PROVIDER:
        return myAccountContext.practiceInfoProvider(id);
      default:
        return;
    }
  };

  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  async function submitAddAccount(formData) {
    const res = await myAccountContext.editUserData(formData);
    if (res) {
      const { account_contact } = await getAccountContactData();
      if (account_contact) {
        setAllAccountList(account_contact);
        reset({ account_contact: account_contact });
        onClose();
      }
    }
    // const primaryAccount = formData?.account_contacts.filter(p => {
    //   if (p.is_primary) return p;
    // });
    // setPrimaryAccountList(primaryAccount);
  }

  function renderCheckbox(index){
    return <Controller
       control={control}
       name={`account_contact.${index}.is_primary`}
       render={({ field }) => {
         return (
           <Checkbox {...field} isChecked={field.value} >
             <Text fontSize={'sm'} fontWeight="bold" color={AppColors.secondary}>
               Primary Account
             </Text>
           </Checkbox>
         );
       }}
     />;
   }

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <VStack display={'block'} mb={6}>
          <SimpleGrid
            columns={{ sm: 1, md: 4 }}
            spacing={5}
            alignItems={'baseline'}
          >
            <FormTextInput
              name={`account_contact.${index}.first_name`}
              control={control}
              placeholder={'First Name'}
              height="50px"
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.last_name`}
              control={control}
              placeholder={'Last Name'}
              height="50px"
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.email`}
              control={control}
              placeholder={'Email'}
              height="50px"
              size="md"
            />

            <Text
              onClick={() => contactField.remove(index)}
              cursor={'pointer'}
              color={AppColors.secondary}
              fontSize="sm"
            >
              Remove
            </Text>
          </SimpleGrid>
         {renderCheckbox(index)}
        </VStack>
      </React.Fragment>
    );
  }

  function renderAddIcon(_props) {
    return (
      <HStack mr={12} mb={5} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon
          m={1}
          color={AppColors.primary}
          boxSize={4}
          border={`1px solid ${AppColors.primary}`}
          borderRadius={'full'}
        />
      </HStack>
    );
  }

  function renderContacts() {
    return (
      <>
        <Text fontSize={'sm'} fontWeight={'bold'}>
          Account Contact
        </Text>
        {contactField.fields.map(renderAddContactForm)}
      </>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
      <ModalContent maxWidth={'50%'}>
        <ModalHeader textAlign={'center'}>
          Edit Account Contact
          {/* {!providers ? 'Edit Account Contact' : 'Edit Providers'} */}
        </ModalHeader>
        <Box
          style={{
            zIndex: '1',
          }}
          position={'absolute'}
          top={-10}
          right={-3}
        >
          <Circle>
            <ModalCloseButton
              size={'sm'}
              style={{ color: AppColors.white }}
              backgroundColor={'#8894A6'}
              p={3}
              onClick={onClose}
              borderRadius={50}
            />
          </Circle>
        </Box>
        <ModalBody>
          <Box
            maxHeight={300}
            overflow="scroll"
            overflowX={'hidden'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 10px lightGrey',
                width: '10px',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'grey',
                borderRadius: '10px',
              },
            }}
          >
            {renderContacts()}
          </Box>
          {renderAddIcon({
            onClick: () => {
              contactField.append(createNewContact(false));
            },
          })}
        </ModalBody>

        <ModalFooter justifyContent={'center'}>
          <Button
            mr={3}
            borderRadius={'full'}
            px={12}
            borderWidth={2}
            borderColor={AppColors.secondary}
            onClick={onClose}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.secondary,
              color: AppColors.white,
            }}
            variant={'outline'}
            fontWeight={'normal'}
          >
            <Text fontWeight={'normal'} fontSize={'sm'}>
              Cancel
            </Text>
          </Button>
          <Button
            bgColor={AppColors.primary}
            borderRadius={'full'}
            px={12}
            onClick={handleSubmit(submitAddAccount)}
            isLoading={myAccountContext.isUpdatingUserData}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.white,
              color: AppColors.primary,
            }}
            textColor={'#fff'}
            fontWeight={'normal'}
          >
            <Text color={'white'} fontSize={'sm'}>
              Update
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAccountsModal;
