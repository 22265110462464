import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import AccountSettingsTutorial from './AccountSettingsTutorial';
import UploadDocumentTutorial from './UploadDocumentTutorial';
import DownloadChartTutorial from './DownloadChartTutorial';
import WelcomeScreenTutorial from './WelcomeScreenTutorial';
import AppColors from '../../constants/AppColors';
import { CircleLeftIcon, CircleRightIcon } from '../../constants/IconData';
import './modal.css';
import { AuthContext } from '../../providers/AuthProvider';

const WELCOME_STEP = 0;
const UPLOAD_DOCUMENT_STEP = 1;
const DOWNLOAD_CHART_STEP = 2;
const ACCOUNT_SETTINGS_STEP = 3;

const TRANSITION_RIGHT = 'RIGHT';
const TRANSITION_LEFT = 'LEFT';

function WelcomeTutorialWrapper() {
  const authContext = useContext(AuthContext);
  const { iotState, isOpenTutorial } = authContext;

  const [tutorialState, setTutorialState] = useState({
    slideDirection: TRANSITION_RIGHT,
    tutorialStep: WELCOME_STEP,
  });
  // const [isOpenModal, iomState] = useBoolean(true);

  let showNavigationButton = false;

  const STEP = [
    WELCOME_STEP,
    UPLOAD_DOCUMENT_STEP,
    DOWNLOAD_CHART_STEP,
    ACCOUNT_SETTINGS_STEP,
  ];

  if (tutorialState.tutorialStep === WELCOME_STEP) {
    showNavigationButton = true;
  }
  const renderTutorialContent = () => {
    const slideRight = tutorialState.slideDirection === TRANSITION_RIGHT;
    switch (STEP[tutorialState.tutorialStep]) {
      case WELCOME_STEP:
        return <WelcomeScreenTutorial />;
      case UPLOAD_DOCUMENT_STEP:
        return <UploadDocumentTutorial slideRight={slideRight} />;
      case DOWNLOAD_CHART_STEP:
        return <DownloadChartTutorial slideRight={slideRight} />;
      case ACCOUNT_SETTINGS_STEP:
        return <AccountSettingsTutorial slideRight={slideRight} />;
      default:
        return <WelcomeScreenTutorial />;
    }
  };

  const onPreviousClick = () => {
    return setTutorialState({
      slideDirection: TRANSITION_LEFT,
      tutorialStep: tutorialState.tutorialStep - 1,
    });
  };

  const onNextClick = () => {
    if (tutorialState.tutorialStep === STEP.length - 1) {
      iotState.off();
      setTutorialState({
        slideDirection: TRANSITION_RIGHT,
        tutorialStep: WELCOME_STEP,
      });
    } else {
      setTutorialState({
        slideDirection: TRANSITION_RIGHT,
        tutorialStep: tutorialState.tutorialStep + 1,
      });
    }
  };

  const closeTutorial = () => {
    iotState.off();
    setTutorialState({
      slideDirection: TRANSITION_RIGHT,
      tutorialStep: WELCOME_STEP,
    });
  };

  function renderFooterContent() {
    return (
      <>
        <CircleLeftIcon
          style={{
            cursor: 'pointer',
            display: showNavigationButton ? 'none' : 'block',
          }}
          onClick={onPreviousClick}
        />
        <Button
          width={'190px'}
          height={'50px'}
          borderRadius={'full'}
          paddingInline={'20px'}
          marginLeft={'20px'}
          marginRight={'20px'}
          bgColor={'transparent'}
          style={{ border: `2px solid ${AppColors.primary}` }}
          _hover={{
            transition: 'all .1s ease',
            bgColor: AppColors.white,
            color: AppColors.primary,
            outline: `2px solid ${AppColors.primary}`,
          }}
          _focus={{ border: 'none' }}
          bg={'#fff'}
          variant="outline"
          onClick={onNextClick}
        >
          {STEP[tutorialState.tutorialStep] === ACCOUNT_SETTINGS_STEP
            ? 'Get Started'
            : ' Next'}
        </Button>
        <CircleRightIcon
          style={{
            cursor: 'pointer',
            display: showNavigationButton ? 'none' : 'block',
            visibility:
              STEP[tutorialState.tutorialStep] === ACCOUNT_SETTINGS_STEP
                ? 'hidden'
                : 'visible',
          }}
          onClick={() => {
            setTutorialState({
              slideDirection: TRANSITION_RIGHT,
              tutorialStep: tutorialState.tutorialStep + 1,
            });
          }}
        />
        <Text
          position={'absolute'}
          bottom={'-60px'}
          fontWeight={'bold'}
          color={'#fff'}
          cursor={'pointer'}
          onClick={closeTutorial}
        >
          Skip Tutorial?
        </Text>
      </>
    );
  }
  return (
    <>
      <Modal
        onClose={closeTutorial}
        isOpen={isOpenTutorial}
        size={'xl'}
        isCentered
      >
        <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
        <ModalContent
          margin={2}
          borderRadius={'18px'}
          p={15}
          height="653px"
          maxW="857px"
        >
          <ModalBody
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {renderTutorialContent()}
          </ModalBody>
          <ModalFooter position={'relative'} justifyContent={'center'}>
            {renderFooterContent()}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default WelcomeTutorialWrapper;
