import { API } from '../config/AppConfig';
import {
  CLIENT_LOGIN,
  FORGOT_PASSWORD,
  GET_ALL_DEPARTMENT,
  GET_DEPARTMENT_BY_ID,
  GET_DEPARTMENT_USERS,
  GET_HEALTH_SYSTEM,
  GET_HEALTH_SYSTEM_BY_ID,
  GET_HEALTH_SYSTEM_DEPARTMENT,
  GET_HEALTH_SYSTEM_HOSPITAL,
  GET_HOSPITAL,
  GET_HOSPITAL_BY_ID,
  GET_PHYSICIAN_GROUP,
  GET_PHYSICIAN_SPECIALTIES,
  GET_SPECIALTIES,
  HOSPITAL_DEPARTMENT_LIST,
  LOGOUT,
  PROVIDER_SEND_MAIL,
  REGISTER,
  USER_LOOKUP,
} from '../constants/Endpoints';
import { ACCESS_TOKEN } from '../constants/PreferenceKeys';
// import API from "../services/ApiService";

class AuthRepository {
  async login(payload) {
    const response = await API.post(CLIENT_LOGIN, {
      body: payload,
    });
    if (response.access_token) {
      localStorage.setItem(ACCESS_TOKEN, `Bearer ${response.access_token}`);

    }
    return response;
  }

  async userLookup() {
    const response = await API.get(USER_LOOKUP);
    return response;
  }
  async forgotPassword(payload) {
    const response = await API.post(FORGOT_PASSWORD, {
      body: payload,
    });
    return response;
  }

  async resetPassword(payload) {
    const response = await API.put(FORGOT_PASSWORD, {
      body: payload,
    });
    return response;
  }

  async logout(payload) {
    const response = await API.post(LOGOUT, {
      body: payload,
    });
    return response;
  }

  async register(payload) {
    const response = await API.post(REGISTER, {
      body: payload,
    });
    return response;
  }

  async getPhysicianSpecialties(hospital_id) {
    const response = await API.get(GET_PHYSICIAN_SPECIALTIES, {
      queryParams: { hospital_id },
    });
    return response;
  }

  async getSpecialties(queryParams) {
    const response = await API.get(GET_SPECIALTIES, {
      queryParams
    });
    return response.map(res => ({
      label: res.name,
      value: res.id ? res.id : res.name,
      id: res.id,
    }));
  }

  async getPhysicianGroup() {
    const response = await API.get(GET_PHYSICIAN_GROUP);
    return response;
  }
  async getHealthSystem() {
    const response = await API.get(GET_HEALTH_SYSTEM);
    return response;
  }
  async getHospital() {
    const response = await API.get(GET_HOSPITAL);
    return response;
  }

  async getDepartmentUsers() {
    const response = await API.get(GET_DEPARTMENT_USERS);
    return response;
  }

  async sentProviderMail(mailIds) {
    const response = await API.post(PROVIDER_SEND_MAIL, {
      body: mailIds,
    });
    return response;
  }

  async getHealthSystemHospitals(healthSystemId) {
    const response = await API.get(GET_HEALTH_SYSTEM_HOSPITAL, {
      pathParams: { healthSystemId },
    });
    return response;
  }

  async getHealthSystemDepartment(healthSystemId) {
    const response = await API.get(GET_HEALTH_SYSTEM_DEPARTMENT, {
      pathParams: { healthSystemId },
    });
    return response;
  }

  async getAllDepartment() {
    const response = await API.get(GET_ALL_DEPARTMENT);
    return response;
  }

  async getHealthSystemById(healthSystemId) {
    const response = await API.get(GET_HEALTH_SYSTEM_BY_ID, {
      pathParams: { healthSystemId },
    });
    return response;
  }

  async getHospitalById(hospitalId) {
    const response = await API.get(GET_HOSPITAL_BY_ID, {
      pathParams: { hospitalId },
    });
    return response;
  }

  async getDepartmentById(id) {
    const response = await API.get(GET_DEPARTMENT_BY_ID, {
      pathParams: { id },
    });
    return response;
  }

  async getHospitalDepartments(hospital_id, queryParams) {
    const response = await API.get(HOSPITAL_DEPARTMENT_LIST, {
      queryParams: { hospital_id, search: queryParams.search },
    });
    return response.map(res => ({
      label: res.name,
      value: res.id ? res.id : res.name,
      id: res.id,
    }));
  }

  async getSpecialtyOptions(queryParams) {
    const response = await API.get(GET_PHYSICIAN_SPECIALTIES, {
      queryParams
    });
    return response.map(res => ({
      label: res.name,
      value: res.id ? res.id : res.name,
      id: res.id,
    }));
  }
}

const authRepository = new AuthRepository();
export default authRepository;
