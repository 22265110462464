/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import BlankLayout from '../components/layout/BlankLayout';
import PageLayout from '../components/layout/PageLayout';
import NotApprovedPage from '../components/NotApprovedPage';
import { UNAPPROVED_PAGE } from '../constants/Routes';
import { withProvider } from '../hoc/withProvider';
import AuthProvider, { AuthContext } from '../providers/AuthProvider';

import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import ManagerRouter from './ManagerRouter';

const MainRouter = props => {
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated;
  
  React.useEffect(() => {
      authContext.userLookup();
  }, []);

  const renderUnAuthenticatedRoute = () => {
    return (
      <Route path={'/'} element={<BlankLayout />}>
        {AuthRouter.map(r => {
          return (
            <Route key={`auth-${r.route}`} path={r.route} element={r.element} />
          );
        })}
      </Route>
    );
  }

  const renderAuthenticatedRoute = () => {
    const notApprovedPage = !authContext.currentUser.is_approved

    return AppRouter.map(r => (
      <Route key={`app-${r.route}`} element={r.layout ? <r.layout /> : <PageLayout />}>
        <Route path={r.route} element={(notApprovedPage && !UNAPPROVED_PAGE.includes(r.route)) ? <NotApprovedPage /> : r.element} />
      </Route>
    ))
  }

  const renderRoutes = () => {
    if(isAuthenticated && authContext.isManagerToken){
      return renderManagerRoute();
    }
    else if (isAuthenticated) {
      return renderAuthenticatedRoute()
    }
  }

  const renderManagerRoute = () => {
    const notApprovedPage = !authContext.currentUser.is_approved

    return ManagerRouter.map(r => (
      <Route key={`app-${r.route}`} element={r.layout ? <r.layout /> : <PageLayout />}>
        <Route path={r.route} element={(notApprovedPage && !UNAPPROVED_PAGE.includes(r.route)) ? <NotApprovedPage /> : r.element} />
      </Route>
    ))
  }

  if (!authContext.isDetermined) {
    return <Spinner />
  }

  return (
    <Routes>
      {renderUnAuthenticatedRoute()}
      {renderRoutes()}

      {/* 404 page here */}
      <Route path={'*'} />
    </Routes>
  )
};

export default withProvider(AuthProvider, MainRouter);
