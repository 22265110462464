import CodeQuickShortIconData from '../assets/images/revintegrity-logo-no-bg.png';
import BellIconData from '../assets/icons/bell-icon.svg';
import MailIconData from '../assets/icons/mail-icon.svg';
import LockIconData from '../assets/icons/lock-icon.svg';
import EyeHiddenIconData from '../assets/icons/eye-hidden-icon.svg';
import EyeIconData from '../assets/icons/eye-icon.svg';
import UserIconData from '../assets/icons/user-icon.svg';
import HelpIconData from '../assets/icons/help-icon.svg';
import LogoutIconData from '../assets/icons/logout-icon.svg';
import ClientIconData from '../assets/images/revintegrity-logo-with-bg.jpg';
import WhiteBellIconData from '../assets/icons/white-bell-icon.svg';
import PracticeInfoIconData from '../assets/icons/practice-info-icon.svg';
import UploadIconData from '../assets/icons/upload-icon.svg';
import PinIconData from '../assets/icons/pin-icon.svg';
import CrossIconData from '../assets/icons/cross-icon.svg';
import TickIconData from '../assets/icons/tick-icon.svg';
import RedCrossIconData from '../assets/icons/cross-red-icon.svg';
import MarkIconData from '../assets/icons/mark-icon.svg';
import UpIconData from '../assets/icons/up-icon.svg';
import DownIconData from '../assets/icons/down-icon.svg';
import LeftIconData from '../assets/icons/left.svg';
import RightIconData from '../assets/icons/right.svg';
import SortIconData from '../assets/icons/sort-icon.svg';
import EyeAvatarIconData from '../assets/icons/eye-avatar-icon.svg';
import CodeQuickLogoData from '../assets/images/revintegrity-logo-no-bg.png';
import ListIconData from '../assets/icons/list-icon.svg';
import CircleUploadIconData from '../assets/icons/upload-icon-primary.svg';
import CircleMarkIconData from '../assets/icons/circle-mark-icon.svg';
import SettingsIconData from '../assets/icons/setting-icon.svg';
import CircleRightIconData from '../assets/icons/circle-right-icon.svg';
import CircleLeftIconData from '../assets/icons/circle-left-icon.svg';
import DepartmentHeadIconData from '../assets/icons/department-head-icon.svg';
import HealthSystemIconData from '../assets/icons/health-sysytem-icon.svg';
import PhysicianIconData from '../assets/icons/physician-icon.svg';
import ProviderIconData from '../assets/icons/provider-icon.svg';
import ExecutiveIconData from '../assets/icons/executive-icon.svg';
import CodeQuickTextIconData from '../assets/images/revintegrity-logo-with-bg.jpg';
import PersonIconData from '../assets/icons/person-icon.svg';
import HealthSystemShortIconData from '../assets/icons/health-system-short-icon.svg';
import CorrectIconData from '../assets/icons/correct-icon.svg';
import SuccessToastIconData from '../assets/icons/success-toast-icon.svg';
import NoteIconData from '../assets/icons/note-icon.svg';
import SheetEyeIconData from '../assets/icons/sheet-eye-icon.svg';
import CommentIconData from '../assets/icons/comment-icon.svg';
import BackIconData from '../assets/icons/back-icon.svg';
import LeftTriangleIconData from '../assets/icons/left-triangle-icon.svg';
import RightTriangleIconData from '../assets/icons/right-triangle-icon.svg';
import PlusIconData from '../assets/icons/plus-icon.svg';
import MinusIconData from '../assets/icons/minus-icon.svg';
import LinkIconData from '../assets/icons/link-icon.svg';
import TriangleRedIconData from '../assets/icons/triangle-red-icon.svg';
import XIconGrayData from '../assets/icons/x-icon-gray.svg';
import UncheckedSquareIconData from '../assets/icons/unchecked-square.svg';
import CheckedSquareIconData from '../assets/icons/checked-square.svg';
import RebuttalIconData from '../assets/icons/rebuttal-icon.svg';
import OpenIconData from '../assets/icons/open-icon.svg';
import CloseIconData from '../assets/icons/close-icon.svg';
import FilledPlusIconData from '../assets/icons/filled-plus-icon.svg';
import LocationIconData from '../assets/icons/location-icon.svg';
import PhysicianGroupIconData from '../assets/icons/physician_group_icon.svg';
import JustTickIconData from '../assets/icons/just-tick.svg';
import BackArrowIconData from '../assets/icons/back-arrow-icon.svg';
import DepartmentIconData from '../assets/icons/department-icon.svg';
import PatientPerMonthIconData from '../assets/icons/patient_per_month_icon.svg';
import CorrectFilledIconData from '../assets/icons/correct-filled-icon.svg';
import CodeQuickIconData from '../assets/images/revintegrity-name-logo-no-bg.png';
import CircleCheckedIconData from '../assets/icons/checked-circle.svg';
import PracticeIconData from '../assets/icons/practice-icon.svg';
import GroupIconData from '../assets/icons/group-icon.svg';
import WelcomeTutorialIconData from '../assets/icons/welcome-tutorial-icon.svg';
import TriangleIconData from '../assets/icons/triangle-icon.svg';
import CalendarIconData from '../assets/icons/calendar-icon.svg';
import BlueCalendarIconData from '../assets/icons/blue-calendar-icon.svg';
import ProfileAvatarIconData from '../assets/icons/profile-avatar-icon.svg';
import YellowTickIconData from '../assets/icons/yellow-tick-icon.svg';
import UpDownIconData from '../assets/icons/up-down-arrows.svg';
import SortUpIconData from '../assets/icons/up-arrow.svg';
import SortDownIconData from '../assets/icons/down-arrow.svg';
import DotMenuIconData from '../assets/icons/dot-menu-icon.svg';
import CirclePlusIconData from '../assets/icons/circle-plus-icon.svg';
import EditIconData from '../assets/icons/edit-icon.svg';
import RebuttalTickIconData from '../assets/icons/rebuttal-tick-icon.svg';
import WhiteCalendarIconData from '../assets/icons/white-calendar-icon.svg';
import PhysicianGroupShortIconData from "../assets/icons/physician-group-short-icon.svg"
import RevIntegrityNameWithNoBgData from "../assets/images/revintegrity-name-logo-no-bg.png"
import LeftEnableIconData from "../assets/icons/left-enable-icon.svg"
import RightEnableIconData from "../assets/icons/right-enable-icon.svg"
import GoogleDriveIconData from "../assets/icons/google-drive.svg"
import MsOneDriveIconData from "../assets/icons/ms-onedrive-svgrepo-com.svg"

import { Tooltip } from '@chakra-ui/react';

const CodeQuickIcon = props => (
  <img alt="code-quick-short-icon"  src={CodeQuickShortIconData} {...props} />
);
const BellIcon = props => (
  <img
    alt="bell-icon"
    src={BellIconData}
    style={{ width: 25, height: 25 }}
    {...props}
  />
);
const MailIcon = props => (
  <img
    alt="mail-icon"
    src={MailIconData}
    style={{ width: 18, height: 18}}
    {...props}
  />
);
const LockIcon = props => (
  <img
    alt="lock-icon"
    src={LockIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeHiddenIcon = props => (
  <img
    alt="eye-icon"
    src={EyeHiddenIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeIcon = props => (
  <img
    alt="eye-icon"
    src={EyeIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const UserIcon = props => (
  <img
    alt="eye-icon"
    src={UserIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const HelpIcon = props => (
  <img
    alt="eye-icon"
    src={HelpIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const LogoutIcon = props => (
  <img
    alt="eye-icon"
    src={LogoutIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const ClientIcon = props => (
  <img alt="code-quick-short-icon" src={ClientIconData} {...props} />
);
const WhiteBellIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={WhiteBellIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const PracticeInfoIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={PracticeInfoIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const UploadIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={UploadIconData}
    style={{ width: 80, height: 80 }}
    {...props}
  />
);
const PinIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={PinIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const CrossIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={CrossIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const TickIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={TickIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const RedCrossIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={RedCrossIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const MarkIcon = props => (
  <img
    alt="eye-icon"
    src={MarkIconData}
    style={{ width: 84, height: 84 }}
    {...props}
  />
);
const UpIcon = props => (
  <img
    alt="up-icon"
    src={UpIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const DownIcon = props => (
  <img
    alt="down-icon"
    src={DownIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const CQLogo = props => (
  <img
    alt="CQLogo"
    src={CodeQuickLogoData}
    style={{ width: 84, height: 94 }}
    {...props}
  />
);
const RightIcon = props => (
  <img
    alt="right-icon"
    src={RightIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const LeftIcon = props => (
  <img
    alt="left-icon"
    src={LeftIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const SortIcon = props => (
  <img
    alt="sort-icon"
    src={SortIconData}
    style={{ width: 14, height: 14 }}
    {...props}
  />
);
const EyeAvatarIcon = props => (
  <img
    alt="eye-avatar-icon"
    src={EyeAvatarIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const ListIcon = props => (
  <img
    alt="ListIcon"
    src={ListIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);

const PrimaryUploadIcon = props => (
  <img
    alt="Primary-Upload-Icon"
    src={CircleUploadIconData}
    style={{ width: 100, height: 100 }}
    {...props}
  />
);

const CircleMarkIcon = props => (
  <img
    alt="Primary-tick-icon"
    src={CircleMarkIconData}
    style={{ width: 100, height: 100 }}
    {...props}
  />
);

const AccountSettingsIcon = props => (
  <img
    alt="account-setting"
    src={SettingsIconData}
    style={{ width: 100, height: 100 }}
    {...props}
  />
);

const CircleRightIcon = props => (
  <img
    alt="CircleRightIcon"
    src={CircleRightIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const CircleLeftIcon = props => (
  <img
    alt="CircleLeftIcon"
    src={CircleLeftIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const DepartmentHeadIcon = props => (
  <img
    alt="department-head-icon"
    src={DepartmentHeadIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const PhysicianIcon = props => (
  <img
    alt="physician-icon"
    src={PhysicianIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const ExecutiveIcon = props => (
  <img
    alt="executive-icon"
    src={ExecutiveIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const HealthSystemIcon = props => (
  <img
    alt="health-system-icon"
    src={HealthSystemIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const ProviderIcon = props => (
  <img
    alt="provider-icon"
    src={ProviderIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const CodeQuickTextIcon = props => (
  <img alt="code-quick-text-icon" src={CodeQuickTextIconData} {...props} />
);
const PersonIcon = props => (
  <img
    alt="person-icon"
    src={PersonIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const HealthSystemShortIcon = props => (
  <img
    alt="health-system-short-icon"
    src={HealthSystemShortIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const CorrectIcon = props => (
  <img
    alt="correct-icon"
    src={CorrectIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const LocationIcon = props => (
  <img
    alt="location-icon"
    src={LocationIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SuccessToastIcon = props => (
  <img
    alt="success-toast-icon"
    src={SuccessToastIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const NoteIcon = props => (
  <img
    alt="Note-icon"
    src={NoteIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const SheetEyeIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={SheetEyeIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CommentIcon = props => (
  <img
    alt="comment-icon"
    src={CommentIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const BackIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={BackIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const LeftTriangleIcon = props => (
  <img
    alt="LeftTriangle-icon"
    src={LeftTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const RightTriangleIcon = props => (
  <img
    alt="right-triangle-icon"
    src={RightTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const PlusIcon = props => (
  <img
    alt="plus-icon"
    src={PlusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const MinusIcon = props => (
  <img
    alt="minus-icon"
    src={MinusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const LinkIcon = props => (
  <img
    alt="Link-icon"
    src={LinkIconData}
    style={{ width: 13, height: 13 }}
    {...props}
  />
);
const TriangleRedIcon = props => (
  <img
    alt="TriangleRed-icon"
    src={TriangleRedIconData}
    style={{ width: 13, height: 13 }}
    {...props}
  />
);
const XIconGray = props => (
  <img
    alt="X-icon-gray"
    src={XIconGrayData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CheckedSquareIcon = props => (
  <img
    alt="checked-square-icon"
    src={CheckedSquareIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const UncheckedSquareIcon = props => (
  <img
    alt="unchecked-square-icon"
    src={UncheckedSquareIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const RebuttalIcon = props => (
  <img
    alt="rebuttal-icon"
    src={RebuttalIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const OpenIcon = props => (
  <img
    alt="open-icon"
    src={OpenIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const CloseIcon = props => (
  <img
    alt="client-selection-icon"
    src={CloseIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);

const FilledPlusIcon = props => (
  <img
    alt="filled-plus-icon"
    src={FilledPlusIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const PhysicianGroupIcon = props => {
  return (
    <img
      alt="physician-group-icon"
      src={PhysicianGroupIconData}
      style={{ width: 18, height: 18 }}
      {...props}
    />
  );
};
const JustTickIcon = props => (
  <img
    alt="tick-icon"
    src={JustTickIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const BackArrowIcon = props => (
  <img
    alt="back-arrow-icon"
    src={BackArrowIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const DepartmentIcon = props => (
  <img
    alt="back-arrow-icon"
    src={DepartmentIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const PatientPerMonthIcon = props => (
  <img
    alt="back-arrow-icon"
    src={PatientPerMonthIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const CorrectFilledIcon = props => (
  <img
    alt="corret-filled-icon"
    src={CorrectFilledIconData}
    style={{ width: 80, height: 80 }}
    {...props}
  />
);
const CodeQuickLogotext = props => (
  <img
    alt="code-quick-icon-text"
    src={CodeQuickIconData}
    style={{ width: 48, height: 48 }}
    {...props}
  />
);
const CircleCheckedIcon = props => (
  <img
    alt="circle-checked-icon"
    src={CircleCheckedIconData}
    style={{ width: 48, height: 48 }}
    {...props}
  />
);
const PracticeIcon = props => (
  <img
    alt="practice-icon"
    src={PracticeIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const GroupIcon = props => (
  <img
    alt="group-icon"
    src={GroupIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const WelcomeTutorialIcon = props => (
  <img
    alt="group-icon"
    src={WelcomeTutorialIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const TriangleIcon = props => (
  <img
    alt="triangle-icon"
    src={TriangleIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const CalendarIcon = props => (
  <img
    alt="calendar-icon"
    src={CalendarIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const BlueCalendarIcon = props => (
  <img
    alt="blue-calendar-icon"
    src={BlueCalendarIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);

const ProfileAvatarIcon = props => (
  <img
    alt="profile-avatar-icon"
    src={ProfileAvatarIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const YellowTickIcon = props => (
  <img
    alt="yellow-tick-icon"
    src={YellowTickIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);

const UpDownIcon = props => (
  <img
    alt="up-down-icon"
    src={UpDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortUpIcon = props => (
  <img
    alt="sort-up-icon"
    src={SortUpIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortDownIcon = props => (
  <img
    alt="sort-down-icon"
    src={SortDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const DotMenuIcon = props => (
  <img
    alt="dot-menu-icon"
    src={DotMenuIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const RebuttalTickIcon = props => (
  <img
    alt="rebutals-tick-icon"
    src={RebuttalTickIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const CirclePlusIcon = props => (
  <img
    alt="circle-plus-icon"
    src={CirclePlusIconData}
    style={{ width: 35, height: 35 }}
    {...props}
  />
);
const EditIcon = props => (
  <img
    alt="edit-icon"
    src={EditIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);

const WhiteCalendarIcon = props => (
  <img
    alt="white-calendar-icon"
    src={WhiteCalendarIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);

const PhysicianGroupShortIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={PhysicianGroupShortIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const RevIntegrityNameWithNoBgDataIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={RevIntegrityNameWithNoBgData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const LeftEnableIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={LeftEnableIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const RightEnableIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={RightEnableIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const GoogleDriveIcon = props => (
  <Tooltip label="Google Drive" fontSize="md">
    <img
      alt="google-drive-icon"
      src={GoogleDriveIconData}
      style={{
        width: 40,
        height: 40,
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
        filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))',
      }}
      onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
      {...props}
    />
  </Tooltip>
);

const MsOneDriveIcon = props => (
  <Tooltip label="OneDrive" fontSize="md">
    <img
      alt="ms-one-drive-icon"
      src={MsOneDriveIconData}
      style={{
        width: 40,
        height: 40,
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
        filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))',
      }}
      onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
      {...props}
    />
  </Tooltip>
);




export {
  CodeQuickIcon,
  BellIcon,
  MailIcon,
  LockIcon,
  EyeHiddenIcon,
  EyeIcon,
  UserIcon,
  HelpIcon,
  LogoutIcon,
  ClientIcon,
  WhiteBellIcon,
  PracticeInfoIcon,
  UploadIcon,
  PinIcon,
  CrossIcon,
  TickIcon,
  RedCrossIcon,
  MarkIcon,
  UpIcon,
  DownIcon,
  LeftIcon,
  RightIcon,
  SortIcon,
  EyeAvatarIcon,
  CircleRightIcon,
  CircleLeftIcon,
  DepartmentHeadIcon,
  PhysicianIcon,
  HealthSystemIcon,
  ExecutiveIcon,
  ProviderIcon,
  CodeQuickTextIcon,
  PersonIcon,
  HealthSystemShortIcon,
  CQLogo,
  ListIcon,
  PrimaryUploadIcon,
  CircleMarkIcon,
  AccountSettingsIcon,
  CorrectIcon,
  SuccessToastIcon,
  NoteIcon,
  SheetEyeIcon,
  CommentIcon,
  BackIcon,
  LeftTriangleIcon,
  RightTriangleIcon,
  PlusIcon,
  MinusIcon,
  RebuttalIcon,
  LinkIcon,
  TriangleRedIcon,
  XIconGray,
  CheckedSquareIcon,
  UncheckedSquareIcon,
  CloseIcon,
  OpenIcon,
  FilledPlusIcon,
  LocationIcon,
  PhysicianGroupIcon,
  JustTickIcon,
  BackArrowIcon,
  DepartmentIcon,
  PatientPerMonthIcon,
  CorrectFilledIcon,
  CodeQuickLogotext,
  CircleCheckedIcon,
  PracticeIcon,
  GroupIcon,
  WelcomeTutorialIcon,
  TriangleIcon,
  CalendarIcon,
  BlueCalendarIcon,
  ProfileAvatarIcon,
  YellowTickIcon,
  UpDownIcon,
  SortUpIcon,
  SortDownIcon,
  DotMenuIcon,
  RebuttalTickIcon,
  CirclePlusIcon,
  EditIcon,
  WhiteCalendarIcon,
  PhysicianGroupShortIcon,
  RevIntegrityNameWithNoBgDataIcon,
  LeftEnableIcon,
  RightEnableIcon,
  GoogleDriveIcon,
  MsOneDriveIcon,
};
