import {
  Box,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  useDisclosure,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
  BackIcon,
  CodeQuickIcon,
  CommentIcon,
  SheetEyeIcon,
} from '../../../constants/IconData';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { format } from 'date-fns';
import AuditSheetFileViewer from './AuditSheetFileViewer';
import NotesDrawer from './NotesDrawer';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import BatchFileModal from '../../dashboard/components/BatchFileModal';
import ROUTES from '../../../constants/Routes';
import { AuthContext } from '../../../providers/AuthProvider';

export default function Topbar({
  onAuditSubmit,
  updateAuditSheet,
  view,
  disableSubmit,
  lastSaved,
  onChange,
  onRebuttalClick,
  batchId,
  onOpenNotes,
}) {
  const navigate = useNavigate();
  // context
  const auditSheetContext = useContext(AuditSheetContext);
  const authContext = useContext(AuthContext);
  const { currentUpload } = auditSheetContext;

  // other hooks
  const { isOpen, onToggle } = useDisclosure();
  const { state } = useLocation();
  // states
  const [currentBatch, setCurrentBatch] = useState({});
  // boolean
  const [isModalOpen, imoState] = useBoolean(false);

  const getTableData = async () => {
    const res = await auditSheetContext.getFileObjectByBatchId(batchId);
    return res;
  };

  async function onBack() {
    if (!view) {
      onChange(true);
      await updateAuditSheet();
      onChange(false);
    }
    navigate(-1);
  }

  async function onLogoClick() {
    if (!view) {
      onChange(true);
      await updateAuditSheet();
      onChange(false);
    }
    navigate(ROUTES.DASHBOARD);
  }

  return (
    <Box w={'100%'}>
      <Flex
        bg={AppColors.secondary}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        position="relative"
        _hover={{
          transition: 'all .1s ease',
          bgColor: AppColors.secondary,
          color: AppColors.white,
        }}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} alignItems={'center'}>
          <Box pl={4}>
            <CodeQuickIcon
              onClick={onLogoClick}
              style={{ height: 40, cursor: 'pointer' }}
            />
          </Box>
          <BackIcon
            style={{
              marginLeft: 20,
              width: 40,
              height: 40,
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={onBack}
          />
          <Text
            display={{ base: 'none', md: 'block' }}
            color={'white'}
            fontSize={'xl'}
            fontWeight={'bold'}
            mx={7}
          >
            {batchId ? batchId : currentUpload.upload_id}
          </Text>
        </Flex>

        <HStack alignItems={'center'} spacing={2} gap={2} mr={3}>
          <Text
            fontSize={'sm'}
            fontFamily={FONT_FAMILY.ptSans}
            color={'white'}
            fontWeight="bold"
          >
            {lastSaved
              ? ` Last saved ${format(
                  new Date(lastSaved),
                  'MMM dd, yyyy'
                )} at ${format(new Date(lastSaved), 'p')}`
              : null}
          </Text>

          <Tooltip hasArrow label="Mark as rebuttals" bg="white" color="black">
            <Box display="inline-block">
              <CommentIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
                onClick={() => onRebuttalClick()}
              />
            </Box>
          </Tooltip>
          {Object.keys(currentUpload).length ? (
            <AuditSheetFileViewer auditData={currentUpload} />
          ) : (
            <SheetEyeIcon
              style={{ cursor: 'pointer', height: 40, width: 40 }}
              onClick={() => {
                setCurrentBatch({ upload_id: batchId });
                imoState.on();
              }}
            />
          )}
          <NotesDrawer onOpenNotes={onOpenNotes} />
          {/* <Button
            mr={1}
            color={'white'}
            style={{ paddingInline: '20px', outlineColor: AppColors.secondary }}
            borderRadius="3xl"
            bgColor={AppColors.yellowColor}
            fontSize={'sm'}
            onClick={onAuditSubmit}
            disabled={view || disableSubmit}
          >
            Submit Audit
          </Button> */}
        </HStack>
      </Flex>
      <BatchFileModal
        isOpen={isModalOpen}
        onClose={imoState.off}
        currentBatch={currentBatch}
        getTableData={getTableData}
        isLoading={auditSheetContext.isFileLoading}
      />
    </Box>
  );
}
