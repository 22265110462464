/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  TextInput,
} from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import { TriangleIcon, TriangleRedIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { DashboardContext } from '../../../providers/DashboardProvider';
import { replaceRoute } from '../../../utils/common.utils';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../utils/my_audits.utils';

const ProvidersTable = () => {
  const dashboardContext = useContext(DashboardContext);
  const columnHelper = createColumnHelper();
  const { filterParams, } = dashboardContext;
  const [searchText, setSearchText] = useState('');
  const [ordering, setOrdering] = useState('');
  const navigate = useNavigate();

  const onSearchAction = () => {
    dashboardContext.setProviderFilter(prev => ({
      ...prev,
      search: searchText,
    }));
  };

  const onFilterChange = e => {
    if (e) {
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      // eslint-disable-next-line
      dashboardContext.getProviders();
    }
  }, [dashboardContext.providerFilter, dashboardContext.filterParams]);

  useEffect(() => {
    if (ordering) {
      dashboardContext.setProviderFilter(prev => ({ ...prev, ordering }));
    }
    // eslint-disable-next-line
  }, [ordering]);

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['provider_name'];

    let color = 'black';
    if (data.column.id === 'archived_date' || data.column.id === 'cq_score') {
      color = AppColors.gray;
    }
    return {
      fontFamily: FONT_FAMILY.ptSans,
      color: color,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['provider_name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: 'black',
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  const formatDate = date => {
    return format(date, 'MM/dd/yyyy');
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('provider_name', {
        header: () => 'Name',
        id: 'provider_name',
        cell: info => {
          return (
            <Text
              _hover={{ textDecoration: 'underline' }}
              onClick={() => {
                const route = replaceRoute(
                  ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
                  { physicianId: info.row.original.provider_id }
                );
                navigate(route);
              }}
              cursor={'pointer'}
            >
              {info.renderValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'cq_score',
        header: () => <span>Accuracy</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return <Text color={AppColors.black}>{cellValue}%</Text>;
        },
      }),
      columnHelper.accessor('power_ranking', {
        id: 'power_ranking',
        header: () => <span>Provider Power Rankings</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return (
            <HStack justifyContent={'center'}>
              {cellValue > 0 ? (
                <TriangleIcon style={{ width: 12, height: 12 }} />
              ) : (
                <TriangleRedIcon style={{ width: 12, height: 12 }} />
              )}
              <Text>{cellValue ? Math.abs(cellValue) : '0'}%</Text>
            </HStack>
          );
        },
      }),
      // columnHelper.accessor('archived_date', {
      //   header: () => 'Date',
      //   id: 'date',
      //   enableSorting: false,
      //   cell: info => {
      //     return filterParams.label === 'Day'
      //       ? formatDate(filterParams.start_date)
      //       : `${formatDate(filterParams.start_date)} - ${formatDate(
      //         filterParams.end_date
      //       )}`;
      //   },
      // }),

      // eslint-disable-next-line
    ],
    [dashboardContext.isProviderLoading, filterParams]
  );

  return (
    <Box mt={2}>
      <TextInput
        borderRadius={'md'}
        value={searchText}
        onChange={e => onFilterChange(e)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            onSearchAction();
          }
        }}
        rightIconProps={{ zIndex: 0 }}
        placeholder="Search Providers"
        style={{ fontFamily: FONT_FAMILY.ptSans }}
        fontSize="md"
        rightIcon={
          <SearchIcon onClick={onSearchAction} color={AppColors.gray} />
        }
      />
      <Box
        id="fixedTableHeader"
        className="tableWraper"
        maxHeight={'300px'}
        overflowY={'scroll'}
      >
        <CQReactTable
          isLoading={dashboardContext.isProviderLoading}
          data={dashboardContext.providers}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(dashboardContext.getProviders)}
          getHeaderCellProps={header => {
            return {
              ...getHeaderCellProps(header),
            };
          }}
          getCellProps={getTableCellProps}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
      {!dashboardContext.providers.length ? (
        <Center mt={10} fontFamily={FONT_FAMILY.ptSans}>
          {' '}
          No data available
        </Center>
      ) : null}
    </Box>
  );
};
export default ProvidersTable;
