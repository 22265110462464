/* eslint-disable eqeqeq */
import { SmallAddIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftAddon, InputRightAddon, Text, useBoolean } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import AppColors from '../../constants/AppColors';
import { DepartmentIcon } from '../../constants/IconData';

const FormMultiSelectModal = ({
  control,
  name,
  options = [],
  placeholder,
  renderFormModal = () => null,
}) => {
  const [isModalOpen, imoState] = useBoolean(false);

  const controller = useController({
    name,
    control,
    defaultValue: '',
  });

  const errorMessage = controller.fieldState?.error?.message;

  const valueText = useMemo(() => {
    const selectedValues = controller.field.value ?? [];
    if (selectedValues && selectedValues.length > 0) {
      const labels = [];
      selectedValues.slice(0, 2).forEach(sv => {
        const foundOption = options.find(o => o.value == sv)
        labels.push(foundOption?.label ?? 'Unknown')
      })

      let label = labels.join(', ');
      if (selectedValues.length > 2) {
        label += `+ ${selectedValues.length - 2} more...`
      }

      return label;
    } else {
      return ''
    }
  }, [controller.field.value, options])

  /** --- DATA HANDLING ---  */
  function handleModalSubmit(selectedValues) {
    controller.field.onChange(selectedValues)
  }

  return (
    <React.Fragment>
      <InputGroup>
        <InputLeftAddon bgColor={'inherit'}>
          <DepartmentIcon style={{ height: 15, width: 15 }} />
        </InputLeftAddon>
        <Input paddingLeft={0} borderRightWidth={0} borderLeftWidth={0} textOverflow={'ellipsis'} fontSize={'sm'} value={valueText} readOnly placeholder={placeholder} />
        <InputRightAddon bgColor={'inherit'} cursor={'pointer'} onClick={imoState.on}>
          <SmallAddIcon
            borderColor={AppColors.primary}
            color={AppColors.primary}
            fontSize={'lg'}
            borderRadius={'full'}
            borderWidth={1} />
        </InputRightAddon>
      </InputGroup>
      {errorMessage && (
        <Text color={'red.400'}>
          {errorMessage}
        </Text>
      )}

      {renderFormModal && renderFormModal({
        isOpen: isModalOpen,
        onClose: imoState.off,
        onSubmit: handleModalSubmit,
        selectedValues: controller.field.value ?? [],
        options,
      })}
    </React.Fragment>
  );

};

export default React.memo(FormMultiSelectModal);
