import { ScaleFade, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import AppColors from '../../../../../constants/AppColors';
import { CorrectFilledIcon } from '../../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../../constants/Theme';

function AccountCreationModal() {
  return (
    <>
      <ScaleFade initialScale={0.5} in={true}>
        <VStack>
          <CorrectFilledIcon style={{ width: 100, height: 100 }}/>
          <Text
            textAlign={'center'}
            fontSize={"xl"}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={"bolder"}
            color={AppColors.secondary}

          >
            Account Creation Successful
          </Text>
          <Text
            textAlign={'center'}
            color={'#222222'}
            fontFamily={FONT_FAMILY.ptSans}
          >
            Our RI representative will contact you within 24 hours to help you proceed further.
          </Text>
        </VStack>
      </ScaleFade>
    </>
  );
}

export default AccountCreationModal;
