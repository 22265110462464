/* eslint-disable array-callback-return */
import { Text } from "@chakra-ui/react";
import React from "react";
import AppColors from "../../../constants/AppColors";
import { STATUS, STATUS_MESSAGE } from "../constants";

const RenderMessage = (props) => {
    const obj = props.comment

    function appendUser(user) {
        if (!user) {
            return <em>unknown_user</em>;
        }

        const username = user.first_name + " " + user.last_name;
        return <span style={{ color: AppColors.darkGray, fontWeight: 'bold' }}>@{username}</span>;
    }

    if (STATUS.includes(obj.action) && !obj.comment) {
        return <Text fontSize={'sm'} whiteSpace='pre-wrap' as={STATUS.includes(obj.action) ? 'i' : 'p'} color={AppColors.darkGray}>
            {STATUS_MESSAGE[obj.action]}
        </Text>
    } else {
        let formattedMessage = [];
        obj.comment && obj.comment.split(" ").forEach((msg) => {
            const matchedExp = msg.match(/@\[(.\d*?)]/g)
            if (matchedExp && matchedExp.length > 1) {
                let textMessage = msg
                matchedExp.map((exp, i) => {
                    const matchedRegex = exp.match(/@\[(.\d*?)]/);

                    // Handle user mention
                    if (matchedRegex && matchedRegex.length > 0) {
                        const userId = matchedRegex[1];
                        const user = props.allUsers.find((u) => u.id === Number(userId));
                        const arr = textMessage.split(exp)
                        formattedMessage.push(arr[0])
                        formattedMessage.push(appendUser(user))
                        textMessage = arr[1]
                        if (i === matchedRegex.length - 1) {
                            formattedMessage.push(textMessage)
                        }
                    }
                }
                )
            } else {
                const matchedRegex = msg.match(/@\[(.\d*?)]/);

                // Handle user mention
                if (matchedRegex && matchedRegex.length > 0) {
                    const userId = matchedRegex[1];
                    const user = props.allUsers.find((u) => u.id === Number(userId));

                    formattedMessage.push(appendUser(user));

                    // Else save the existing message. TODO: Format the text
                } else {
                    formattedMessage.push(`${msg}`);
                }
            }

            // Add a space after appending text
            formattedMessage.push(" ");
        });
        return <Text fontSize={'sm'} whiteSpace='pre-wrap' as={STATUS.includes(obj.action) ? 'i' : 'p'} color={AppColors.darkGray}>
            {formattedMessage.length ? formattedMessage : ''}
        </Text>
    }

}
export default React.memo(RenderMessage)