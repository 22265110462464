import ROUTES from "../constants/Routes"
import { ClientType } from "./clientTypeUtils";

// helps in getting the next route for moving from one dashboard to it's sub dashboard.
const getSubDashboardRoute = (userType, hospitalId, departmentId, subViewId) => {
    if (userType == ClientType.UNDEFINED) {
        return [ClientType.UNDEFINED, ClientType.UNDEFINED];
    }
    // that means auth user is health system and moving from dept to phys dashboard
    if (hospitalId && departmentId) {
        return [ROUTES.PHYSICIAN_DASHBOARD, {
                                                hospitalId: hospitalId, 
                                                departmentId: departmentId, 
                                                physicianId: subViewId }];
    }

    // means auth user is health system and moving from hospital to dept dashboard
    if (hospitalId) {
        return [ROUTES.DEPARTMENT_DASHBOARD, {
                                                hospitalId: hospitalId, 
                                                departmentId: subViewId }];
    }

    // means auth user is hospital and moving from hosp_dept to hosp_phys dashboard
    if (departmentId) {
        return [ROUTES.HOSPITAL_PHYSICIAN_DASHBOARD, {
                                                        departmentId: departmentId,
                                                        physicianId: subViewId }];
    }

    // now it means no params are there. It's the dashboard, but we need to check what's the user auth type

    const noURLParamsSubDashboardMapping = Object.freeze({
        // auth user is health system and moving from dashboard to hosp dashboard
        [ClientType.HEALTH_SYSTEM]: [ROUTES.HOSPITAL_DASHBOARD, { hospitalId: subViewId }], 
        // auth user is hosp and moving from dashboard to hosp_dept dashboard
        [ClientType.HOSPITAL]: [ROUTES.HOSPITAL_DEPARTMENT_DASHBOARD, { departmentId: subViewId }],
            
        // auth user is dept and moving from dashboard to dept_phys dashboard
        [ClientType.DEPARTMENT]: [ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD, { physicianId: subViewId }],

        // auth user is physicians group (similar to health system) and moving from 
        // physician group dashboard to hosp dashboard
        [ClientType.PHYSICIANS_GROUP]: [ROUTES.HOSPITAL_DASHBOARD, { hospitalId: subViewId }]
    });

    return noURLParamsSubDashboardMapping[userType];
};

export { getSubDashboardRoute };