import {
  Box,
  Flex,
  Heading,
  Spacer,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CQFooter from '../../../../../components/layout/CQFooter';
import AppColors from '../../../../../constants/AppColors';
import { CodeQuickLogotext } from '../../../../../constants/IconData';
import ROUTES from '../../../../../constants/Routes';
import JoinCodeQuick from './JoinCodeQuick';
import ProviderLogin from './ProviderLogin';
import ProviderResetPassword from './ProviderResetPassword';

const JOIN_CODE_QUICK = 0;
const PROVIDER_LOGIN = 1;
const RESET_PASSWORD = 2;

function Providertutorialwrapper() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const [providerState, setProviderState] = useState(JOIN_CODE_QUICK);

  const STEP = [JOIN_CODE_QUICK, PROVIDER_LOGIN, RESET_PASSWORD];
  const renderProviderComponent = () => {
    switch (STEP[providerState]) {
      case JOIN_CODE_QUICK:
        return (
          <JoinCodeQuick
            renderProviderLogin={setProviderState}
            currentState={providerState}
            setEmail={setEmail}
            email={email}
          />
        );
      case PROVIDER_LOGIN:
        return (
          <ProviderLogin
            email={email}
            renderProviderLogin={setProviderState}
            currentState={providerState}
          />
        );
      case RESET_PASSWORD:
        return <ProviderResetPassword />;
      default:
        return <JoinCodeQuick />;
    }
  };

  return (
    <>
      <VStack
        bgColor={AppColors.loginFormBg}
        justifyContent={'space-between'}
        alignContent={'space-between'}
      >
        <Flex
          height={'100vh'}
          bgColor={AppColors.loginFormBg}
          justifyContent={'space-between'}
          flexDirection={'column'}
          alignContent={'space-between'}
          spacing={0}
          w={'100%'}
        >
          <Box w={'100%'}>
            <Flex
              bg={useColorModeValue(AppColors.white)}
              color={useColorModeValue(AppColors.secondary, 'white')}
              minH={'60px'}
              align={'center'}
            >
              <Flex flex={{ base: 1 }}>
                <Box m={1} />
                <Flex
                  flex={{ base: 1 }}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                <Box bg={"#fff"} p={1} borderRadius={"sm"}>
                <CodeQuickLogotext
                    onClick={() => {
                      navigate(ROUTES.REGISTER);
                    }}
                    style={{
                      height: '40px',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                  <Heading fontSize="md" ml="2">
                    - Provider
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Spacer />
          <Box>{renderProviderComponent()}</Box>
          <Spacer />
          <CQFooter />
        </Flex>
      </VStack>
    </>
  );
}

export default Providertutorialwrapper;
