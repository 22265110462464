import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Text, Tooltip } from "@chakra-ui/react";
import AppColors from "../../constants/AppColors";
import { WhiteCalendarIcon } from "../../constants/IconData";
import { FONT_FAMILY } from "../../constants/Theme";
import { useComponentVisible } from "../../hooks";
import { parseDateInUTC } from "../../utils/common.utils";
import NewDatePickerPopup from "../date_picker_popup/NewDatePickerPopup";
import { useEffect, useState } from "react";
import { useToastr } from '@laxmimanogna/code-quick-components';

const CustomDateFilter = ({ filterParams, openDateFilter, isOpenDateFilter, onDateClose, onDateSubmit, isLoading, onSelectClick, objectWithDate }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const toast = useToastr();
    const [dateRangeFromBackend, setDateRangeFromBackend] = useState({start_date:null, 
        end_date:null});
    const [userDataDateRange, setUserDataDateRange] = useState({latestDataServiceDate:null, firstDataServiceDate: null});
    const [tooltipLabel, setTooltipLabel] = useState("");
    const [isEmpty, setIsEmplty] = useState(false);

    useEffect(() => {
        //Set date range as per backend objectWithDate
        //objectWithDate['filter_dates'] = {start_date,end_date,...}
        if(objectWithDate && objectWithDate['filter_dates']){
            setDateRangeFromBackend(objectWithDate['filter_dates']);
            if (userDataDateRange.latestDataServiceDate !== objectWithDate['filter_dates']['latest_data_service_date']) {
                setUserDataDateRange({
                    latestDataServiceDate: objectWithDate['filter_dates']['latest_data_service_date'],
                    firstDataServiceDate: objectWithDate['filter_dates']['first_data_service_date'],
                });
            }
        }
        else if(objectWithDate && objectWithDate['message']){
            setIsEmplty(true);
            setUserDataDateRange({latestDataServiceDate:null, firstDataServiceDate: null});
        }
        if(objectWithDate && objectWithDate['current_cq_score'] === 0){
            toast.showError({
                description: 'No data available for this time range.',
              });
        }
      }, [objectWithDate]);

      useEffect(() => {
        //For fiter date toast popup
        if(userDataDateRange.latestDataServiceDate && userDataDateRange.firstDataServiceDate){
            toast.showInfo({ description: `Data available from ${parseDateInUTC(userDataDateRange.firstDataServiceDate)} to ${parseDateInUTC(userDataDateRange.latestDataServiceDate)}` });
            setTooltipLabel(`Data available from ${parseDateInUTC(userDataDateRange.firstDataServiceDate)} to ${parseDateInUTC(userDataDateRange.latestDataServiceDate)}`);
        }else{
            setTooltipLabel("");
        }
    }, [userDataDateRange.latestDataServiceDate, userDataDateRange.firstDataServiceDate])

    const getDateFormat = () => { 
        if(dateRangeFromBackend['start_date'] && dateRangeFromBackend['end_date']){
            return parseDateInUTC(dateRangeFromBackend['start_date']) + '-' + parseDateInUTC(dateRangeFromBackend['end_date']);
        }
        else{
            return "Loading please wait...";
        }
    }
    
    return (
        <Box position='relative'>
            <Tooltip 
                label={tooltipLabel} 
                bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0"
            >
            <Button
                color={'white'}
                _hover={{
                    bg: AppColors.secondary,
                }}
                ml={2}
                bg={AppColors.secondary}
                borderRadius={'md'}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight="normal"
                onClick={() => {
                    //open date modal

                    setIsComponentVisible(prev => !prev)
                }}

                
            >
                <HStack>
                    <Text>
                        {isEmpty ? "No data present": getDateFormat()}
                    </Text>
                    <WhiteCalendarIcon />
                    <ChevronDownIcon/>
                </HStack>
            </Button>
            </Tooltip>
            
            <Box ref={ref}
            >
                <NewDatePickerPopup
                    onClose={onDateClose}
                    onSubmit={onDateSubmit}
                    isLoading={isLoading}
                    selectedValue={filterParams?.label?.toLowerCase()}
                    onSelectClick={onSelectClick}
                    isComponentVisible={isComponentVisible}
                    setIsComponentVisible={setIsComponentVisible}
                    filterParams={filterParams}
                />
            </Box>
        </Box>
    );
};
export default CustomDateFilter