import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomDateFilter from '../../../../components/dashboard_components/DashboardFilter';
import AppColors from '../../../../constants/AppColors';
import { BackArrowIcon } from '../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';
import { downloadReport } from '../../../../utils/downloadReport';
import { getDateInterval } from '../../getDateInterval';

function BatchDashboardHeader({  pageRefData, page2RefData, objectWithDate }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { batchId } = useParams();
  const { filterParams, handleApplyFilter } = useContext(BatchContext)
  const uploadDate = location?.state?.chartData?.upload_date;
  const [isOpenDateFilter, iodfState] = useBoolean(false);

  return (
    <HStack width={'100%'} justifyContent={'space-between'}>
      <HStack>
        <BackArrowIcon
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: 'pointer' }}
        />
        <Stack>
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            paddingLeft={3}
            color={AppColors.gray}
          >
            {uploadDate ? format(new Date(uploadDate), 'dd/MM/yyyy') : ''}
          </Text>
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontWeight={600}
            paddingLeft={3}
          >
            {batchId}
          </Text>
        </Stack>
      </HStack>
      <HStack justifyContent={'end'}>
        <Button
          borderRadius={'full'}
          borderColor={AppColors.secondary}
          borderWidth={2}
          color={AppColors.secondary}
          variant="outline"
          onClick={() => {
            downloadReport(
              {
                fileName: 'report.pdf',
                header: ' Report',
                page1Ref: pageRefData,
                page2Ref: page2RefData,
              },
              []
            );
          }}
        >
          Download PDF
        </Button>
        <CustomDateFilter
          filterParams={filterParams}
          objectWithDate={objectWithDate}
          onDateSubmit={dates => {
            const dateObject = {};
            if (Object.keys(dates)?.length) {
              Object.keys(dates).forEach(key => {
                dateObject[key] = dates[key] ? new Date(dates[key]) : '';
              });
            }
            handleApplyFilter({
              ...filterParams,
              ...dateObject,
              label: 'custom',
            });
            localStorage.setItem('customDateFilter', JSON.stringify({ ...dateObject }));
            iodfState.off();
          }}
          openDateFilter={() => iodfState.on()}
          isOpenDateFilter={isOpenDateFilter}
          onDateClose={() => iodfState.off()}
          isLoading={false}
          onSelectClick={filter => {
            if (filter.value !== 'custom') {
              const data = getDateInterval(filter.value);
              handleApplyFilter({
                ...filterParams,
                label: filter.value,
                ...data,
              });
              localStorage.setItem(
                'selectedFilter',
                JSON.stringify(filter?.value?.toLowerCase())
              );
              window.localStorage.removeItem('customDateFilter');
              iodfState.off();
            }
          }}
        />
      </HStack>
    </HStack>
  );
}

export default BatchDashboardHeader;
