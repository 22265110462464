import { AUDIT_SHEET_KEY } from "./constants"

export const getRowColArray = (selection, sheet, batch) => {
    const { start, end } = selection
    const row = []
    const column = []
    const uploadIds = []

    //get row
    for (let i = start.row; i <= end.row; i++) {
        row.push(sheet.data[i].id)
        if (!uploadIds.includes(sheet.data[i].chart_id)) {
            uploadIds.push(sheet.data[i].chart_id)
        }
    }

    for (let i = start.col; i <= end.col; i++) {
        column.push(batch ? AUDIT_SHEET_KEY[i] : AUDIT_SHEET_KEY[i + 1])
    }

    return { row, column, uploadIds }
}
export const getRowColIndex = (selection, sheet) => {
    const { start, end } = selection
    const rows = []
    const columns = []
    //get row
    for (let i = start.row; i <= end.row; i++) {
        rows.push(i)
    }
    for (let i = start.col; i <= end.col; i++) {
        columns.push(AUDIT_SHEET_KEY[i])
    }
    return { rows, columns, }
}