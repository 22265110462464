import { Box, HStack, Text } from '@chakra-ui/react';
import { TriangleIcon, TriangleRedIcon } from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';
import { convertToPositiveDollar } from '../utils/common.utils';
import { WarningIcon } from '@chakra-ui/icons';

const RevenueBreakdownTile = ({ totalUpcoded, totalDowncoded, description }) => {

  return (
    <Box alignItems="left" mt={4}>

      <Box mb={4}>
        <Text fontSize="sm" fontWeight="bold" color="gray.700">
          Total Downcoded Revenue
        </Text>
        <HStack mt={1}>
          {(typeof totalDowncoded === 'string' && totalDowncoded === 'N/A') ? (
            <WarningIcon />
          ) : (
            <TriangleIcon />
          )}
          <Text fontSize="5xl" fontWeight="bold" fontFamily={FONT_FAMILY.ptSans} color="black">
            {convertToPositiveDollar(totalDowncoded)}
          </Text>
        </HStack>
      </Box>

      <Box mb={2}>
        <Text fontSize="sm" fontWeight="bold" color="gray.700">
          Total Upcoded Revenue
        </Text>
        <HStack mt={1}>
          {(typeof totalUpcoded === 'string' && totalUpcoded === 'N/A') ? (
            <WarningIcon />
          ) : (
            <TriangleRedIcon />
          )}
          <Text fontSize="5xl" fontWeight="bold" fontFamily={FONT_FAMILY.ptSans} color="black">
            {convertToPositiveDollar(totalUpcoded)}
          </Text>
        </HStack>
      </Box>

      <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm" mt={3}>
        {description}
      </Text>
    </Box>
  );
};
export default RevenueBreakdownTile;
