import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FONT_FAMILY } from '../constants/Theme';
import annotationPlugin from 'chartjs-plugin-annotation';
import AppColors from '../constants/AppColors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);
const CQLineChart = ({ chartData, xLabel, yLabel, xAxis, x2Axis, showComparison,  optionProps, chartProps = {}, maxTicksLimit }) => {

  const options = {
    animation: {
      duration: 0,
    },
    // maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        title: {
          display: true,
          text: yLabel,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 12,
            weight: 'bold',
          },
        },
      },
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxTicksLimit ? maxTicksLimit : 8,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        title: {
          display: true,
          text: xLabel,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 12,
            weight: 'bold',
          },
        },
        labels: xAxis, // Set the labels for the primary x-axis
      },
      x2: {
        display: showComparison,
        position: 'top',
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxTicksLimit ? maxTicksLimit : 8,
          color: AppColors.secondary,
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        labels: x2Axis, // Set the labels for the secondary x-axis
      },
    },
    elements: {
      point: {
        pointRadius: (data) => {
          return data?.parsed?.y === 0 ? 1 : 3
        }
      }
    },
    ...optionProps,
  };

  if (!chartData || !chartData.length) {
    return null;
  }

  const data = {
    labels: xAxis,
    datasets: chartData,
  };

  if (x2Axis) {
    const primaryDataset = {
      ...chartData[0],
      xAxisID: 'x', // Bind this dataset to the primary x-axis
    };
  
    const comparisonDataset = showComparison && chartData[1]
      ? {
          ...chartData[1],
          xAxisID: 'x2', // Bind this dataset to the secondary x-axis
        }
      : null;
  
    data.datasets = [primaryDataset];
  
    if (showComparison && comparisonDataset) {
      data.datasets.push(comparisonDataset);
    }
  }

  return (
    <>
      <Line options={options} data={data} {...chartProps} />
    </>
  );
};
export default CQLineChart;
