const getCurrentUserTypeId = currentUser => {
  switch (currentUser?.user_type) {
    case 'HEALTH SYSTEM':
      return {
        health_system_id: currentUser.health_system_id?.id,
      };
    case 'HOSPITAL':
      return {
        hospital_id: currentUser.hospital_id?.id,
      };
    case 'DEPARTMENT':
      return {
        department_id: currentUser.department_id?.id,
      };
    case 'PROVIDER':
      return {
        provider_id: currentUser.provider_id?.providers__id,
      };

    case 'PHYSICIANS GROUP':
      return {
        health_system_id: currentUser.physicians_group_id?.id,
      };
    default:
      return {};
  }
};
const getCurrentUserTypeName = currentUser => {
  switch (currentUser?.user_type) {
    case 'HEALTH SYSTEM':
      return currentUser.health_system_id?.name || '';

    case 'HOSPITAL':
      return currentUser.hospital_id?.name || '';

    case 'DEPARTMENT':
      return currentUser.department_id?.specialty__name || '';

    case 'PROVIDER':
      return currentUser.provider_id?.name || '';

    case 'PHYSICIANS GROUP':
      return currentUser.physicians_group_id?.name || '';
    default:
      return '';
  }
};

export { getCurrentUserTypeId, getCurrentUserTypeName };
