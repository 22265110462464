/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { withMultiProviders, withProvider } from '../../../hoc/withProvider';
import AuthProvider from '../../../providers/AuthProvider';
import MyAccountProvider, {
  MyAccountContext,
} from '../../../providers/MyAccountProvider';
import UploadProvider from '../../../providers/UploadProvider';
import DepartmentPracticeInfo from './DepartmentPracticeInfo';
import HealthSystemPracticeInfo from './HealthSystemPracticeInfo';
import HospitalPracticeInfo from './HospitalPracticeInfo';
import PhysicianGroupPracticeInfo from './PhysicianGroupPracticeInfo';
import ProviderPracticeInfo from './ProviderPracticeInfo';

const PracticeInfoWrapper = () => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUser = myAccountContext.currentUserType;

  useEffect(() => {
    myAccountContext.userTypeLookup();
  }, []);

  const HEALTH_SYSTEM_TYPE = 'HEALTH SYSTEM';
  const HOSPITAL_TYPE = 'HOSPITAL';
  const DEPARTMENT_TYPE = 'DEPARTMENT';
  const PHYSICIAN_GROUP_TYPE = 'PHYSICIANS GROUP';
  const PROVIDER_TYPE = 'PROVIDER';

  const renderComponentByUser = () => {
    switch (currentUser?.type) {
      case HEALTH_SYSTEM_TYPE:
        return (
          <HealthSystemPracticeInfo
            id={currentUser?.health_system_id?.id}
            user={currentUser.type}
            status={currentUser.is_active}
          />
        );
      case HOSPITAL_TYPE:
        return (
          <HospitalPracticeInfo
            id={currentUser?.hospital_id?.id}
            user={currentUser.type}
            status={currentUser.is_active}
          />
        );
      case DEPARTMENT_TYPE:
        return (
          <DepartmentPracticeInfo
            id={currentUser?.department_id?.id}
            user={currentUser.type}
            status={currentUser.is_active}
          />
        );
      case PHYSICIAN_GROUP_TYPE:
        return (
          <PhysicianGroupPracticeInfo
            id={currentUser?.physicians_group?.id}
            user={currentUser.type}
            status={currentUser.is_active}
          />
        );
      case PROVIDER_TYPE:
        return (
          <ProviderPracticeInfo
            id={currentUser?.id}
            user={currentUser.type}
            status={currentUser.is_active}
          />
        );

      default:
        return;
    }
  };

  return <>{renderComponentByUser()}</>;
};

export default withMultiProviders([MyAccountProvider, UploadProvider, AuthProvider], PracticeInfoWrapper);
