import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import AppColors from '../constants/AppColors';

const PasswordStrength = ({ strength }) => {
  return (
    <HStack spacing={'6px'} m={1}>
      <Box
        bgColor={strength >= 1 ? AppColors.secondary : 'gray.200'}
        height={'5px'}
        width='25%'
        borderRadius={10}
      />
      <Box
        bgColor={strength >= 2 ? AppColors.secondary : 'gray.200'}
        height={'5px'}
        width='25%'
        borderRadius={10}
      />
      <Box
        bgColor={strength >= 3 ? AppColors.secondary : 'gray.200'}
        height={'5px'}
        width='25%'
        borderRadius={10}
      />
      <Box
        bgColor={strength >= 4 ? AppColors.secondary : 'gray.200'}
        height={'5px'}
        width='25%'
        borderRadius={10}
      />
    </HStack>
  );
};
export { PasswordStrength };
