import { Box, useBoolean } from '@chakra-ui/react';
import React from 'react';
import FileViewer from '../../../components/pdf_viewer/FileViewer';
import { SheetEyeIcon } from '../../../constants/IconData';

const AuditSheetFileViewer = ({ auditData, icon }) => {
  const [isFileOpen, ifoState] = useBoolean(false);

  return (
    <React.Fragment>
      {icon ? <Box onClick={ifoState.on}
        style={{
          width: 40,
          height: 40,
          cursor: 'pointer',
          display: 'inline-block',
        }}> {icon}</Box> : <SheetEyeIcon
        onClick={ifoState.on}
        style={{
          width: 40,
          height: 40,
          cursor: 'pointer',
          display: 'inline-block',
        }}
      />}
      <FileViewer
        isOpen={isFileOpen}
        currentFile={auditData?.file_obj}
        onClose={ifoState.off}
      />
    </React.Fragment>
  );
};

export default AuditSheetFileViewer;
