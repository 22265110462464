/* eslint-disable array-callback-return */
const separateIdNames = (data, key_name) => {
  if (data === undefined) return;
  const obj = {
    exist_ids: [],
    [key_name]: [],
  };
  if (data.length) {
    data.map(d => {
      if (d && Number(d)) {
        obj.exist_ids.push(Number(d));
      } else if (d) {
        obj[key_name].push(d);
      } else {
        return;
      }
    });
  }
  return obj;
};
export { separateIdNames };
