import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import AppColors from '../../constants/AppColors';
import CQFooter from './CQFooter';
import CQTopbar from './CQTopbar';

const PageLayout = props => {

  return <VStack spacing={0} h={'100vh'} bgColor={AppColors.loginFormBg} justifyContent={'space-between'}>

    <VStack bgColor={AppColors.loginFormBg} spacing={0} w={'100%'}>
      <CQTopbar />

      <Box w={'100%'} h={'100%'}>
        <Outlet />
      </Box>
    </VStack>

    <CQFooter />
  </VStack>
};

export default PageLayout;
