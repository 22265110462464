import { ScaleFade, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { CQLogo } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';

function WelcomeScreenTutorial() {
  return (
    <>
      <ScaleFade initialScale={0.5} in={true}>
        <VStack>
          <CQLogo />
          <Text
            textAlign={'center'}
            size={'36px'}
            fontSize={40}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={600}
            style={{marginTop:"20px"}}
          >
            Welcome to RevIntegrity
          </Text>
          <Text
            textAlign={'center'}
            color={'#222222'}
            fontFamily={FONT_FAMILY.ptSans}
          >
            Here’s quick tutorial to help you get started…
          </Text>
        </VStack>
      </ScaleFade>
    </>
  );
}

export default WelcomeScreenTutorial;
