import {
  Box,
  Button,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Th,
  Tr,
  VStack,
  Tooltip,
  Tfoot,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import './Tablecell.css';
import PropTypes from 'prop-types';
import { priceConverter } from '../../utils/priceConverter';
import InfoTooltip from '../InfoTooltip';

function OutstandingRevenueComponent({
  opporturnityBreakdown,
  isFetching,
  defaultStartDate,
  defaultEndDate,
  filter,
}) {
  const [isDollarView, setIsDollarView] = useState(true);

  const toggleView = () => {
    setIsDollarView(prev => !prev);
  };

  const renderTableValues = (data, header = '') => {
    const isNumber = typeof data === 'number' && !isNaN(data);

    if ((header === 'amount' || header === 'opportunity') && isNumber) {
      return priceConverter(data);
    }
    else if (data !== null && data !== undefined) {
      return <Text>{data}</Text>;
    }
    return <Text>-</Text>;
  };

  const renderCustomTable = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        {/* <Box flexGrow={1} overflowY="scroll" className="orc_table"> */}
        <Box className="orc_table" maxHeight={'400px'} overflowY={'scroll'}>
          <Table>
            <Thead
            >
              <Tr>
                <Th>Code</Th>
                <Th>Amount</Th>
                <Th>Client Count</Th>
                <Th>RI Count</Th>
                <Th>Errors</Th>
                <Th>{isDollarView ? 'Opportunity($)' : 'RVU'}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!opporturnityBreakdown?.result?.length ? (
                <Tr>
                  <Td colSpan={6}>No data available</Td>
                </Tr>
              ) : (
                opporturnityBreakdown?.result?.map((value, i) => (
                  <Tr key={i}
                  style={{
                    backgroundColor: i % 2 === 0 ? '#f7f7fa' : 'inherit',
                  }}
                  >
                    <Td>{renderTableValues(value?.srvc_no)}</Td>
                    <Td>{renderTableValues(value?.amount, 'amount')}</Td>
                    <Td>{renderTableValues(value?.count_provider_cq[0])}</Td>
                    <Td>{renderTableValues(value?.count_provider_cq[1])}</Td>
                    <Td>
                      <Tooltip
                        label={
                          <Box>
                            <Text>Upcoded: {value?.upcoded}</Text>
                            <Text>Downcoded: {value?.downcoded}</Text>
                          </Box>
                        }
                        aria-label="Error split details"
                        placement="right"
                        margin="0 0 0 -20px"
                        bg='gray.100' color='black'
                      >
                        <Text cursor="pointer"
                          _hover={{
                            textDecoration: 'Underline',
                          }}
                        >{renderTableValues(value?.upcoded + value?.downcoded)}</Text>
                      </Tooltip>
                    </Td>
                    <Td minWidth="70px" color={isDollarView && value?.opportunity >= 0 ? '#77C586' : isDollarView ? AppColors.red : 'initial'}>
                      {isDollarView ? (
                        <Tooltip
                          label={
                            <Box>
                              <Text>Audited: {renderTableValues(value?.opportunity_provider_cq[1], 'opportunity')}</Text>
                              <Text>Billed: {renderTableValues(value?.opportunity_provider_cq[0], 'opportunity')}</Text>
                            </Box>
                          }
                          aria-label="Opportunity details"
                          placement="right"
                          margin="0 0 0 -25px"
                          bg='gray.100' color='black'
                        >
                          <Text
                            cursor="pointer"
                            _hover={{
                              textDecoration: 'underline',
                            }}
                          >
                            {renderTableValues(value?.opportunity, 'opportunity')}
                          </Text>
                        </Tooltip>
                      ) : (
                        renderTableValues(value?.rvu)
                      )}
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
            {opporturnityBreakdown?.result?.length && (
              <Tfoot margin="10px 10px 0 0">
                <Tr>
                  <Td>
                    <Text fontWeight={'bold'}>Total</Text>
                  </Td>
                  <Td>
                    <Text fontWeight={'bold'}>
                      {renderTableValues(opporturnityBreakdown?.total_amount, 'amount')}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontWeight={'bold'}>
                      {renderTableValues(opporturnityBreakdown?.client_count_total)}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontWeight={'bold'}>
                      {renderTableValues(opporturnityBreakdown?.ri_count_total)}
                    </Text>
                  </Td>
                  <Td>
                    <Tooltip
                      label={
                        <Box>
                          <Text>Upcoded: {opporturnityBreakdown?.total_upcoded}</Text>
                          <Text>Downcoded: {opporturnityBreakdown?.total_downcoded}</Text>
                        </Box>
                      }
                      aria-label="Error split details"
                      placement="right"
                      margin="0 0 0 -20px"
                      bg='gray.100' color='black'
                    >
                      <Text cursor="pointer"
                        fontWeight={'bold'}
                        _hover={{
                          textDecoration: 'Underline',
                        }}
                      >{opporturnityBreakdown?.total_upcoded + opporturnityBreakdown?.total_downcoded}</Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    {isDollarView ? (
                      <Tooltip
                        label={
                          <Box>
                            <Text>Audited: {renderTableValues(opporturnityBreakdown?.audited_opportunity_total, 'opportunity')}</Text>
                            <Text>Billed: {renderTableValues(opporturnityBreakdown?.billed_opportunity_total, 'opportunity')}</Text>
                          </Box>
                        }
                        aria-label="Opportunity details"
                        placement="right"
                        margin="0 0 0 -25px"
                        bg='gray.100' color='black'
                      >
                        <Text
                          cursor="pointer"
                          fontSize="1.1em"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          color={opporturnityBreakdown?.opportunity_total >= 0 ? '#77C586' : AppColors.red}
                        >
                          {renderTableValues(opporturnityBreakdown?.opportunity_total, 'opportunity')}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text fontWeight={'bold'}>{renderTableValues(opporturnityBreakdown?.rvu_total)}</Text>
                    )}
                  </Td>
                </Tr>
              </Tfoot>
            )}
          </Table>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent="center" h="100%" textAlign="center">
        <Text>Something went wrong. Please try again later.</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!opporturnityBreakdown) {
      return renderFailedPage();
    } else {
      return renderCustomTable();
    }
  };

  return (
    <Box
      height="100%"
      id={'outstandingRevenue'}
      bg={AppColors.white}
      borderRadius="md"
      padding={4}
      display="flex"
      flexDirection="column"
    >
      {/* header */}
      <HStack justifyContent="space-between" mb={4}>
        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
          Outstanding Revenue Opportunity Breakdown
        </Text>
        <HStack>
          <Button onClick={toggleView} size={'sm'}>
            {isDollarView ? 'RVU View' : '$ View'}
          </Button>
          <InfoTooltip
            infoContent={
              <>
                <p>
                  <b>Outstanding Revenue Opportunity Breakdown</b> provides an in-depth breakdown by code of what was billed versus what was audited by our team. Here, you can see the potential revenue by code, helping you identify specific areas where you can recover lost revenue.
                </p>
              </>
            }
          />
        </HStack>
      </HStack>
      {/* content */}
      {renderMainContent()}
    </Box>
  );
}

OutstandingRevenueComponent.propTypes = {
  opporturnityBreakdown: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default OutstandingRevenueComponent;

