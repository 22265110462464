/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import { ProfileAvatarIcon } from '../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../constants/Theme';
import physicianRepository from '../../../../repositories/PhysiciansRepository';
import { toTitleCase } from '../../../../utils/common.utils';

function PhysicianProfileSection() {
  const { departmentId, physicianId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [physicianData, setPhysicianData] = React.useState(null);

  const getProfileStatistics = async () => {
    try {
      ifState.on();
      const filterparams = {};
      filterparams.provider_id = physicianId;
      const response = await physicianRepository.getPhysician(
        filterparams,
        departmentId
      );
      setPhysicianData(response);
      return response;
    } catch (error) {
      setPhysicianData(null);
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    getProfileStatistics();
  }, []);

  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          bg={AppColors.white}
          borderRadius={'md'}
          p={10}
          display={'flex'}
          flexDirection={'column'}
        >
          <HStack mb="2">
            <ProfileAvatarIcon style={{ width: 80, height: 80 }} />
            <Stack pl={3}>
              <Text
                fontSize="lg"
                textTransform={'capitalize'}
                fontWeight={'semibold'}
              >
                Dr.{' '}
                {toTitleCase(
                  `${physicianData?.first_name} ${physicianData?.last_name}`
                )}
              </Text>
              <Text fontSize='sm' color={AppColors.gray}>
                {physicianData?.specialties}
              </Text>
            </Stack>
          </HStack>
          <VStack mt={3} spacing={4} align="stretch">
            <Text fontSize='sm' color={AppColors.gray}>
                Provider Statistics
            </Text>
          </VStack>
          <Stack mt={2} maxW={'250px'}>
            {renderPhysicianStatistics()}
          </Stack>
        </Box>
      </ScaleFade>
    );
  };

  const renderPhysicianStatistics = () => {
    return (
      <>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>Uploads(Last 30 days)</Text>
          <Text>
            {physicianData.uploads_last_30days
              ? physicianData.uploads_last_30days
              : '-'}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>Uploads(All time)</Text>
          <Text>
            {physicianData.uploads_all_time
              ? physicianData.uploads_all_time
              : '-'}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>RI Industry Score</Text>
          <Text>
            {physicianData.cq_industry_score
              ? physicianData.cq_industry_score
              : '-'}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>RI Accuracy</Text>
          <Text>
            {physicianData.cq_accuracy ? physicianData.cq_accuracy : '-'}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>Organization Rank</Text>
          <Text>
            {physicianData.organization_rank
              ? `${physicianData.organization_rank?.rank}/${physicianData.organization_rank?.total_provider}`
              : '-'}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='sm' fontFamily={FONT_FAMILY.ptSans}>Education</Text>
          <Text>{physicianData.education ? physicianData.education : '-'}</Text>
        </HStack>
      </>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!physicianData) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };

  return renderMainContent();
}

export default PhysicianProfileSection;
