import {
    CQReactTable,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
    Box,
    HStack,
    Text,
    Button,
} from '@chakra-ui/react';
import AppColors from '../../../../constants/AppColors';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { FONT_FAMILY } from '../../../../constants/Theme';
import InfoTooltip from '../../../../components/InfoTooltip';
import MyAccountProvider, { MyAccountContext } from '../../../../providers/MyAccountProvider';
import { withProvider } from '../../../../hoc/withProvider';
import { useParams } from 'react-router-dom';
const columnHelper = createColumnHelper();

const EducationClassTable = () => {
    const params = useParams();
    const { physicianId } = params;
    const [isRegisteredInMoodle, setIsRegisteredInMoodle] = useState(false);
    const accountsContext = useContext(MyAccountContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const providerData = await accountsContext.getProvider(physicianId);
                if (providerData?.moodle_user_id) {
                    setIsRegisteredInMoodle(true);
                }
            } catch (error) {
                console.error('Error fetching provider data:', error);
            }
        };
        if (physicianId) {
            fetchData();
        }
    }, [physicianId]);

    const data = [
        { course: 'Coding 101', call: 'https://calendly.com/education-sessions/coding-101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
        { course: 'Documentation 101', call: 'https://calendly.com/education-sessions/documentation-101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
        { course: 'MDM 101', call: 'https://calendly.com/education-sessions/mdm101', link: 'https://revintegrity.moodlecloud.com/login/index.php' },
    ];

    const columns = React.useMemo(
        () => [
            columnHelper.accessor('course', {
                header: () => 'Course',
                id: 'course',
                cell: info => {
                    return (
                        <Text >
                            {info.renderValue()}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('action', {
                header: () => <span>Action</span>,
                enableSorting: false,
                cell: info => {
                    const link = info?.row?.original?.link;
                    const call = info?.row?.original?.call;

                    return (
                        <HStack spacing={4} justifyContent="center" alignItems="center">
                            <Button
                                bg={AppColors.secondary}
                                fontSize={'14px'}
                                fontWeight="normal"
                                color={'white'}
                                size="sm"
                                borderRadius="3xl"
                                px="10"
                                onClick={() => window.open(link)}
                            >
                                Enroll
                            </Button>
                            <Text
                                color={AppColors.primary}
                                fontSize={'14px'}
                                fontWeight="normal"
                                cursor="pointer"
                                textDecoration="underline"
                                _hover={{
                                    color: AppColors.secondary,
                                }}
                                onClick={() => window.open(call)}
                            >
                                Schedule Meeting
                            </Text>
                        </HStack>
                    );
                },
            }),
            // columnHelper.accessor('completion_status', {
            //     header: () => 'Completion Status',
            //     enableSorting: true,
            //     id: 'completion_status',
            //     cell: info => {
            //         return (
            //             <Flex alignItems="center" justifyContent="center" padding={2}>
            //                 <Text width="24px" marginRight={2} textAlign="center">No</Text>
            //                 <Checkbox
            //                     onChange={()=>{}}
            //                     colorScheme='green'
            //                     size="lg"
            //                 />
            //             </Flex>
            //         );
            //     },
            // }),
        ],
        // eslint-disable-next-line
        []
    );

    const getTableCellProps = (cell, data) => {
        const startingHeaderId = ['course',];

        let color = 'black';
        if (data.column.id === 'date' || data.column.id === 'accuracy') {
            color = AppColors.gray;
        }
        return {
            fontFamily: FONT_FAMILY.ptSans,
            color: color,
            textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',

        };
    };

    function getHeaderCellProps(header) {
        const startingHeaderId = ['course',];
        return {
            textTransform: 'none',
            fontFamily: FONT_FAMILY.ptSans,
            fontWeight: 'bold',
            color: 'black',
            justifyContent: startingHeaderId.includes(header.id)
                ? 'flex-start'
                : 'center',
        };
    }

    return <>
        <Box
            marginTop={3}
            width={'100%'}
            bg={AppColors.white}
            padding={4}
            borderRadius={'lg'}
            shadow={'md'}
        >
            <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
                <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
                    Education Class
                </Text>
                <InfoTooltip infoContent={
                    <>
                        <p>
                            <b>Education Class</b> section allows providers to sign up for group education sessions or engage with our self-paced education program. These educational resources are designed to enhance your knowledge and skills, improving overall coding accuracy and compliance.
                        </p>
                    </>} />
            </HStack>
            <CQReactTable
                isLoading={false}
                data={data}
                columns={columns}
                {...defaultReactTableProp}
                getHeaderCellProps={header => {
                    return {
                        ...getHeaderCellProps(header),
                    };
                }}
                getCellProps={getTableCellProps}

            />
            {isRegisteredInMoodle ? (
                <>
                    <Box mt={8} mb={4} >
                        <hr style={{ borderColor: AppColors.lightGray, borderWidth: '1px' }} />
                    </Box>
                    <Box
                        padding={4}
                        borderRadius={'md'}
                        textAlign={'center'}
                    >
                        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.secondary}>
                            Provider Moodle Account is Created.
                        </Text>
                    </Box>
                </>
            ) : (null)}
        </Box>
    </>
}
export default withProvider(MyAccountProvider, EducationClassTable);