import { jwtDecode } from "jwt-decode";
import { ACCESS_TOKEN, MANAGER_CLIENT_ACCESS_TOKEN } from "../constants/PreferenceKeys";


export const isManagerCreatedToken = (token) => {
    if(token){
        const decodedPayload = jwtDecode(token);
        if(decodedPayload.isManager === "MANAGER"){
            return true;
        }
    }
    return false;
}

export const whichTokenToUse = () => {
    if(sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN) && sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN) === localStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN)){
        if(sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN) && isManagerCreatedToken(sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN))){
            return MANAGER_CLIENT_ACCESS_TOKEN;
        }
    }
    else{
        localStorage.removeItem(MANAGER_CLIENT_ACCESS_TOKEN);
        sessionStorage.removeItem(MANAGER_CLIENT_ACCESS_TOKEN);
        if(localStorage.getItem(ACCESS_TOKEN) &&  !isManagerCreatedToken(localStorage.getItem(ACCESS_TOKEN))){
            return ACCESS_TOKEN;
        }else{
            localStorage.removeItem(ACCESS_TOKEN);
        }
    }
    return ACCESS_TOKEN;
}

export const isManagerView = () => {
    return isManagerCreatedToken(localStorage.getItem(whichTokenToUse()));
}
