import { API } from '../config/AppConfig';
import {
  CHART_ACCURACY_BREAKDOWN,
  RVU_OPP,
  DASHBOARD_RVU_OPP_BREAKDOWN,
  GET_DASHBOARD_CQ_SCORE,
  GET_HOSPITAL_DASHBOARD,
  HOSPITAL_DEPARTMENTS,
  HOSPITAL_PROVIDER,
  DASHBOARD_RVU_OPPORTURINTY,
  UPDATE_ACCURACY_REVIEW_STATUS,
} from '../constants/Endpoints';

class HospitalDashboardRepository {
  async getRevenueOpporturnityScore(queryParams) {
    const response = await API.get(DASHBOARD_RVU_OPPORTURINTY, {
      queryParams,
    });
    return response;
  }

  async getHospitalForDashboard(hospitalId, queryParams) {
    const response = await API.get(GET_HOSPITAL_DASHBOARD, {
      queryParams,
      pathParams: { hospitalId },
    });
    return response;
  }

  async getHospitalCqscore(queryParams) {
    const response = await API.get(GET_DASHBOARD_CQ_SCORE, {
      queryParams,
    });
    return response;
  }

  async getHOspitalprovider(queryParams) {
    const response = await API.get(HOSPITAL_PROVIDER, {
      queryParams,
    });
    return response;
  }

  async getHospitalDepartments(queryParams) {
    const response = await API.get(HOSPITAL_DEPARTMENTS, {
      pathParams: { hospitalId: queryParams?.hospital_id },
      queryParams,
    });
    return response;
  }

  async getChartAccuracyData(queryParams) {
    const response = await API.get(CHART_ACCURACY_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getOutstandingRevenue(queryParams) {
    const response = await API.get(DASHBOARD_RVU_OPP_BREAKDOWN, {
      queryParams,
    });
    return response;
  }
  
  async getRevenueOpportunity(queryParams) {
    const response = await API.get(RVU_OPP, {
      queryParams,
    });
    return response;
  }

  async updateAccuracyReviewStatus(rowData) {
    try {
        // const { chart_id, location, review } = rowData;
        const response = await API.put(UPDATE_ACCURACY_REVIEW_STATUS, {
            body: rowData
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to update review status');
    }
  }
}

const hospitalDashboardRepository = new HospitalDashboardRepository();
export default hospitalDashboardRepository;
