/* eslint-disable react-hooks/exhaustive-deps */
import { Box, HStack } from "@chakra-ui/layout"
import React, { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import Thermometer from "../../../components/thermometer/Thermometer"

const AIRiskMonitor = ({ tile, data }) => {
    let [maxValue, setMaxValue] = useState(100)
    let [width, setWidth] = useState(0)

    const thermometerRef = useRef()
    useEffect(() => {
        if (data.data) {
            const tempData = data.data.length ? data.data.reduce((p, c) => p.risk_temperature > c.risk_temperature ? p : c) : { risk_temperature: 100 }
            setMaxValue(tempData.risk_temperature > 100 ? tempData.risk_temperature : 100)
        }
    }, [data])

    useEffect(() => {
        if (thermometerRef.current) {
            // setWidth(Math.floor(thermometerRef.current.getBoundingClientRect().width))
            setWidth(thermometerRef.current.clientWidth)
        }
    }, [])

    return <>
        <Box ref={thermometerRef}>
            <HStack justifyContent='center'>
                <Thermometer
                    theme="light"
                    value={data.current_risk_temperature}
                    risk_temperature_diff={data.risk_temperature_diff}
                    max={maxValue > 100 ? ((Math.ceil((maxValue + 1) / 10) * 10) + 10) : 100}
                    steps="5"
                    format="°F"
                    size="large"
                    height={tile ? "450" : '300'}
                    limit={{
                        above: data.top_parameter,
                        below: data.bottom_parameter,
                        goal: data.goal
                    }}
                    list={data.data}
                    tile={tile}
                    width={width}
                />
            </HStack>
        </Box>

    </>
}
export default AIRiskMonitor