import AppColors from '../../../../../constants/AppColors';
import {
  Box,
  Heading,
  VStack,
  HStack,
  useColorModeValue,
  Circle,
  Text,
} from '@chakra-ui/react';
import { FONT_FAMILY } from '../../../../../constants/Theme';
import Footer from './Footer';
import { CodeQuickTextIcon, HelpIcon, RevIntegrityNameWithNoBgDataIcon } from '../../../../../constants/IconData';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../constants/Routes';
import CQContainer from '../../../../../components/layout/CQContainer';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import { USER_TYPE } from '../../../../../constants/constants';
import TooltipContent from './TooltipContent';
import { useContext } from 'react';
import { AuthContext } from '../../../../../providers/AuthProvider';

const Layout = ({ currentPage, title, header, form, children }) => {
  const navigate = useNavigate();
  const [isHelpOpen, ihoState] = useBoolean(false);

  const authContext = useContext(AuthContext);
  const {setCurrentPage, setOnboardingObject} = authContext;

  const resetPage = () => {
    setCurrentPage(0);
    setOnboardingObject({})
  };

  return (
    <VStack
      spacing={0}
      h={'100vh'}
      bgColor={AppColors.loginFormBg}
      justifyContent={'space-between'}
    >
      <VStack bgColor={AppColors.loginFormBg} spacing={14} w={'100%'} pb={14}>
        <HStack
          bg={useColorModeValue(AppColors.white)}
          color={useColorModeValue(AppColors.secondary, 'white')}
          minH={'60px'}
          width="100%"
          justifyContent={'center'}
          alignItems="center"
        >
          <RevIntegrityNameWithNoBgDataIcon
            onClick={() => {
              header
                ? navigate(ROUTES.REGISTER)
                : navigate(ROUTES.LOGIN_SCREEN);
              resetPage();
            }}
            style={{ height: 40, cursor: 'pointer' }}
          />
          {/* <Box
            ml={5}
            borderRadius={'md'}
            height={'60%'}
            opacity={0.5}
            border={'1px solid #fff '}
            mr={5}
          />
          <Text
            letterSpacing={'1px'}
            color={'#fff'}
            fontWeight={'normal'}
            fontSize={'xx-large'}
            fontFamily={FONT_FAMILY.baiJamurjee}
          >
            RevIntegrity
          </Text> */}

          {header ? (
            <Heading pt={2} fontSize={'18px'} ml="2">
              - {header}
            </Heading>
          ) : null}
        </HStack>
        <CQContainer bg={'white'} width={'60%'} borderRadius={'lg'} p={3}>
          <HStack
            bg={AppColors.loginFormBg}
            py={10}
            justifyContent="center"
            spacing={3}
            borderRadius={'lg'}
          >
            <Heading
              color={AppColors.secondary}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontSize={'2xl'}
            >
              {title}
            </Heading>
            {title === 'Select User Type' ? (
              <Circle
                bg={'transparent'}
                size={5}
                borderWidth={2}
                borderColor={AppColors.secondary}
                position="relative"
                onMouseEnter={() => {
                  ihoState.on();
                }}
                onMouseLeave={() => {
                  ihoState.off();
                }}
              >
                <HelpIcon />
                {isHelpOpen ? <TooltipContent data={USER_TYPE} /> : null}
              </Circle>
            ) : null}
          </HStack>
          <Box w={'100%'} my={20} px={10}>
            {children}
          </Box>
        </CQContainer>
      </VStack>
      <Footer />
    </VStack>
  );
};
export default Layout;
