import { useBoolean } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import ChartAccuracyComponents from '../../../../components/dashboard_components/ChartAccuracyComponents';
import { CUSTOM } from '../../../../constants/constants';
import physicianRepository from '../../../../repositories/PhysiciansRepository';
import { dateFormat } from '../../../../utils/common.utils';

function PhysicianChartAccuracy({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
  selectedDates,
}) {
  const { physicianId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [cqScore, setCqScore] = React.useState(null);

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchCqScore = async () => {
    try {
      ifState.on();
      let filterParams = {};
      filterParams.filter = filter.toLowerCase();

      if (filter === CUSTOM.toLowerCase()) {
        await setCustomDates(filterParams);

        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );

        delete filterParams.filter;
      }
      filterParams.provider_id = physicianId;
      const response = await physicianRepository.getPhysicianCQScore(
        filterParams
      );
      setCqScore(response);
      return response;
    } catch (error) {
      setCqScore(null);
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchCqScore();
    // eslint-disable-next-line
  }, [defaultStartDate, defaultEndDate, startDate, endDate, filter]);

  const uiStartDate = dateFormat(defaultStartDate, 'MM/dd/yyyy');
  const uiEndDate = dateFormat(defaultEndDate, 'MM/dd/yyyy');

  return (
    <ChartAccuracyComponents
      isFetching={isFetching}
      cq_score={cqScore}
      defaultStartDate={uiStartDate}
      defaultEndDate={uiEndDate}
      filter={filter}
    />
  );
}

export default PhysicianChartAccuracy;
