import { format, parseISO, getWeekOfMonth } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

const getWeekInNum = (date) => {
    const num = getWeekOfMonth(date);
    if (num > 3 && num < 21) return num + 'th';
    switch (num % 10) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";
      default:
        return num + "th";
    }
  };
  

/* eslint-disable array-callback-return */
const getChartData = (filter, chartData, unitType, previousChartData, showComparison) => {
    const data = []
    const label = []
    const comparisonLabel = []
    const dateInUTC = date => utcToZonedTime(new Date(date));
    switch (unitType) {
        case 'days':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key) => {
                    if (key) {
                        label.push(format(dateInUTC(key), 'd-MMM-yy'))
                        data.push(chartData[key])
                    }
                })
                if (showComparison) {
                    if (Object.keys(previousChartData).length) {
                        Object.keys(previousChartData).map((key) => {
                            if (key) {
                                comparisonLabel.push(format(dateInUTC(key), 'd-MMM-yy'))
                            }
                        })
                    }
                }
            }
            break;
        case 'weeks':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key) => {
                    if (key) {
                        label.push(`${getWeekInNum(dateInUTC(key))}-${format(dateInUTC(key), 'MMM-yy')}`);
                        data.push(chartData[key])
                    }
                })
                if (showComparison) {
                    if (Object.keys(previousChartData).length) {
                        Object.keys(previousChartData).map((key) => {
                            if (key) {    
                                comparisonLabel.push(`${getWeekInNum(dateInUTC(key))}-${format(dateInUTC(key), 'MMM-yy')}`)
                            }
                        })
                    }
                }
            }
            break;
        case 'months':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key, index) => {
                    if (key) {
                        label.push(format(dateInUTC(key), 'MMM-yy'))
                        data.push(chartData[key])
                    }
                })
                if (showComparison) {
                    if (Object.keys(previousChartData).length) {
                        if (Object.keys(previousChartData).length) {
                            Object.keys(previousChartData).map((key) => {
                                if (key) {
                                    comparisonLabel.push(format(dateInUTC(key), 'MMM-yy'))
                                }
                            })
                        }
                    }
                }
            }
            
        break;
        default: return {};
    }
    if(showComparison){
        return { label, data, comparisonLabel } 
    }
    return { data, label }
}

export { getChartData }