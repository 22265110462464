import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';

export const downloadReport = async (reportFile, selectedErrors) => {
  const { fileName, header, page1Ref, page2Ref } = reportFile;
  const pdf = new jsPDF('p', 'pt', 'a4');

  pdf.text(header, 230, 20);
  await Promise.all(
    page1Ref.map(async (r, i) => {
      var h = r.height(selectedErrors.length);
      var w = r.width;
      const dataUrl = await htmlToImage.toPng(r?.ref?.current);
      pdf.addImage(dataUrl, 'PNG', 20, r.paddingTop, w, h);
    })
  );
  if (page2Ref) {
    pdf.addPage("a4", "p")
    await Promise.all(
      page2Ref.map(async (r, i) => {
        var h = r.height(selectedErrors.length);
        var w = r.width;
        const dataUrl = await htmlToImage.toPng(r?.ref?.current);
        pdf.addImage(dataUrl, 'PNG', 20, r.paddingTop, w, h);
      })
    );
  }
  pdf.save(`${fileName}`);
};
