/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import CQLineChart from '../../../../components/CQLineChart';
import AppColors from '../../../../constants/AppColors';
import { CUSTOM, XAXISLABEL } from '../../../../constants/constants';
import { TriangleIcon, TriangleRedIcon } from '../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { PhysicianContext } from '../../../../providers/PhysicianProvider';
import physicianRepository from '../../../../repositories/PhysiciansRepository';
import {
  convertToPositiveDollar,
  dateFormat,
  toTitleCase,
} from '../../../../utils/common.utils';
import { getChartData } from '../../getChartData';
import { utcToZonedTime } from 'date-fns-tz';
import InfoTooltip from '../../../../components/InfoTooltip';

function PCQScoreChart({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
  selectedDates,
}) {
  const { physicianId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [graphData, setGraphData] = React.useState(null);
  const physicianContext = useContext(PhysicianContext);
  const { showComparison } = physicianContext;

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchCQscore = async () => {
    try {
      ifState.on();
      const filterParams = {};
      filterParams.filter = filter.toLowerCase();

      if (filter === CUSTOM.toLowerCase()) {
        setCustomDates(filterParams);
        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );
        delete filterParams.filter;
      }
      filterParams.provider_id = physicianId;

      const response = await physicianRepository.getPhysicianCQscore(
        filterParams
      );

      setGraphData(response);
      physicianContext.setCQScore(response);
      return response;
    } catch (error) {
      setGraphData(null);
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchCQscore();
  }, [defaultStartDate, defaultEndDate, startDate, endDate, filter]);

  const renderCQscoreChart = () => {
    const {
      current_graph: graph,
      current_cq_score,
      previous_graph,
      unit_type,
      filter_dates,
    } = graphData;
    let label = filter;

    const chartLabel =
      label === CUSTOM.toLowerCase() ? 'custom' : label;

    let chartData = { label: [], data: [] };
    let previousChartData = { label: [], data: [] };

    let legendDate = graph && Object.keys(graph);

    if (graph) {
      chartData = getChartData(
        chartLabel,
        graph,
        unit_type,
        previous_graph,
        showComparison
      );
      previousChartData = getChartData(
        chartLabel,
        previous_graph,
        unit_type,
        [],
        showComparison
      );
    }
    const previous_cq_score = graphData['previous_cq_score '];

    const startDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['start_date']) : new Date())
    );

    const endDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['end_date']) : new Date())
    );

    let chartDataProp = [
      {
        label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
          new Date(endDateUTC),
          'MMM dd, yyyy'
        )} (${unit_type? "In "+unit_type : "No Data"})`,
        data: chartData.data,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
        tension: 0.4,
      },
    ];

    if (filter.toLowerCase() !== 'all' && showComparison) {
      chartDataProp = [
        {
          label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
            new Date(endDateUTC),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: chartData.data,
          borderColor: AppColors.primary,
          backgroundColor: AppColors.primary,
          tension: 0.4,
        },
        {
          label: `${format(
            new Date(filter_dates.previous_start_date),
            'MMM dd, yyyy'
          )} - ${format(
            new Date(filter_dates.previous_end_date),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: previousChartData?.data,
          borderColor: AppColors.secondary,
          backgroundColor: AppColors.secondary,
          tension: 0.4,
        },
      ];
    }
    return (
      <Box mt={5}>
        <CQLineChart
          chartData={chartDataProp}
          yLabel="Score"
          xLabel={XAXISLABEL[chartLabel]}
          xAxis={chartData.label}
          x2Axis={chartData.comparisonLabel}
          showComparison={showComparison}
          maxTicksLimit={7}
          optionProps={{
            plugins: {
              legend: {
                onClick: () => { },
                display: true,
                position: 'bottom',
                align: 'end',
                labels: {
                  boxWidth: 20,
                  boxHeight: 0.5,
                  color: 'black',
                  font: {
                    size: 10,
                    family: FONT_FAMILY.ptSans,
                  },
                },
              },
              annotation: {
                annotations: {
                  label1: {
                    type: 'label',
                    backgroundColor: 'transparent',
                    color: 'rgba(3, 49, 122, 0.4)',
                    content:
                      typeof previous_cq_score === 'number' && showComparison
                        ? [`${previous_cq_score}%`]
                        : [],
                    font: {
                      size: 25,
                      weight: 'bold',
                    },
                    xAdjust: 80,
                  },
                  label2: {
                    type: 'label',
                    backgroundColor: 'transparent',
                    color: 'rgba(34, 175, 168, 0.4)',
                    content:
                      typeof current_cq_score === 'number'
                        ? [`${current_cq_score}%`]
                        : [],
                    font: {
                      size: 25,
                      weight: 'bold',
                    },
                    xAdjust:
                      typeof previous_cq_score === 'number' && showComparison
                        ? -80
                        : 0,
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: context => {
                    return context.parsed.y
                  },
                  title: data => {
                    const context = data[0]
                    if (context.datasetIndex === 1 && chartData.comparisonLabel.length) {
                      return chartData.comparisonLabel[context.parsed.x]
                    }
                    return context.label
                  }
                },
              },
            },
          }}
        />
      </Box>
    );
  };

  const renderCQScoreContainer = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {/* header */}
          <HStack justifyContent={'space-between'} gap={'50px'}>
            <Text
              fontSize="sm"
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.primary}
            >
              RI Score
            </Text>
            <InfoTooltip infoContent={
              <>
                <p>
                  <b>Revenue Integrity (RI) Score</b> shows changes in accuracy over time. The RI Score reflects the overall accuracy of your coding and billing processes, helping you monitor improvements or identify areas needing attention.
                </p>
              </>} />
          </HStack>
          {/* content */}

          {renderCQscoreChart()}
          {/* footer */}
          <Box mb={2} ml={10}>
            <HStack>
              {graphData?.cq_score_diff > 0 ? (
                <TriangleIcon />
              ) : (
                <TriangleRedIcon />
              )}
              <Text
                fontSize="15px"
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={600}
              >
                {convertToPositiveDollar(graphData.cq_score_diff)}
              </Text>
            </HStack>

            <Text
              fontFamily={FONT_FAMILY.ptSans}
              fontSize={'xx-large'}
              fontWeight={'semibold'}
            >
              {graphData.grade}
            </Text>
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!graphData) {
      return renderFailedPage();
    } else {
      return renderCQScoreContainer();
    }
  };

  return renderMainContent();
}

export default PCQScoreChart;