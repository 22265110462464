/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, HStack } from '@chakra-ui/react';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useEffect } from 'react';
import { MailIcon, PersonIcon } from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGISTER } from './validation';
import NavigationFooter from '../common_files/NavigationFooter';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { REGISTER_ERROR_PAGE } from '../../../../../constants/constants';

const SignupForm = ({ onPreviousClick, onNextClick }) => {
  const {
    onboardingObject,
    setOnboardingObject,
    registerError,
    currentPage,
    registerBody,
  } = useContext(AuthContext);
  const form = useForm({
    resolver: yupResolver(REGISTER),
  });
  const { control, handleSubmit, reset, setError, clearErrors } = form;

  const onSubmitData = data => {
    setOnboardingObject({ ...onboardingObject, ...data });
    onNextClick();
  };
  const clearErrorData = (key, data) => {
    if (registerBody[key] && data !== registerBody[key]) {
      clearErrors(key);
    } else if (data === registerBody[key] && registerError[key]) {
      setError(key, { type: 'custom', message: registerError[key] });
    }
  };

  useEffect(() => {
    if (onboardingObject && Object.keys(onboardingObject)?.length) {
      reset({
        first_name: onboardingObject.first_name,
        last_name: onboardingObject.last_name,
        email: onboardingObject.email,
      });
    }
    if (registerError && Object.keys(registerError)?.length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage]?.map(key => {
        if (
          registerErrorKeys.includes(key) &&
          onboardingObject[key] === registerBody[key]
        ) {
          setError(key, { type: 'custom', message: registerError[key] });
        }
      });
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <HStack spacing={7} display={'flex'} alignItems="baseline">
          <FormTextInput
            name="first_name"
            id="first_name"
            placeholder="First Name"
            style={{ opacity: '1' }}
            leftIcon={<PersonIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
            onChange={e => {
              clearErrorData('first_name', e.target.value);
            }}
          />
          <FormTextInput
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            style={{ opacity: '1' }}
            leftIcon={<PersonIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
            onChange={e => {
              clearErrorData('last_name', e.target.value);
            }}
          />
        </HStack>
        <HStack mt={5}>
          <FormTextInput
            name="email"
            id="email"
            placeholder="name@gmail.com"
            style={{ opacity: '1' }}
            leftIcon={<MailIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
            disabled={onboardingObject.primaryEmailOnboarding}
            onChange={e => {
              clearErrorData('email', e.target.value);
            }}
          />
        </HStack>
        <Box mt={14} />
        <NavigationFooter
          onPreviousClick={onPreviousClick}
          currentPageTitle={onboardingObject.userType}
        />
      </form>
    </>
  );
};
export default SignupForm;
