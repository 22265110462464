import {
  Box,
  Button,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import PropTypes from 'prop-types';
import { priceConverter } from '../../utils/priceConverter';
import { convertToPositiveDollar } from '../../utils/common.utils';
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData';
import InfoTooltip from '../InfoTooltip';

function AiRevenueComponent({
  defaultStartDate,
  defaultEndDate,
  revenueOpporturintyScore,
  isFetching,
  filter,
}) {
  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          m={2}
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
        >
          {/* header */}
          <HStack justifyContent={'space-between'}>
            <Text
              fontSize="sm"
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.primary}
            >
              Outstanding Revenue Opportunity
            </Text>
            <InfoTooltip infoContent={
              <>
                <p>
                  <b>Outstanding Revenue Opportunity</b> shows the dollar value of potential revenue that your practice is missing out on. Identifying this outstanding revenue opportunity can help focus on areas where improvements lead to significant financial gains.
                </p>
              </>} />
          </HStack>
          <HStack mt={4}>
            {revenueOpporturintyScore.difference >= 0 ? <TriangleIcon /> : <TriangleRedIcon />}
            <Text
              fontSize={'xs'}
              fontFamily={FONT_FAMILY.ptSans}
              color={revenueOpporturintyScore.difference >= 0 ? 'black' : 'red'}
            >
              {convertToPositiveDollar(revenueOpporturintyScore.difference
                ? priceConverter(revenueOpporturintyScore.difference)
                : '$0')}
            </Text>
          </HStack>
          {/* content */}

          <Box
            mt={10}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
          >
            <Stack>
              <Text mb={2} fontSize={'5xl'} fontFamily={FONT_FAMILY.ptSans}>
                {revenueOpporturintyScore?.cq_outstanding_rev_opp ? priceConverter(revenueOpporturintyScore?.cq_outstanding_rev_opp) : '$0'}
              </Text>
            </Stack>

            {/* footer */}
            <Box mt={10}>
              <Button borderWidth={1}
                borderColor="black"
                bg={'white'}
                mt={5}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight="normal"
                _hover={{ borderColor: AppColors.primary }}
                fontSize={'sm'}>
                <a href="#outstandingRevenue">Chart Details</a>
              </Button>
            </Box>
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!revenueOpporturintyScore) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };

  return renderMainContent();
}

AiRevenueComponent.propTypes = {
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  revenueOpporturintyScore: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default AiRevenueComponent;
