import { Box, Text } from "@chakra-ui/react"

const NotApprovedPage = () => {
    return <Box pos={'absolute'} bg={'transparent'} height={'80%'} width={'100%'} top={'10%'} right={'1%'}
        display='flex'
        alignItems='center'
        justifyContent={'center'}
    >
        <Box textAlign='center' borderRadius={'xl'} p={3}>
            <Text>Your account is not approved yet.</Text>
            <Text> Please contact our RevIntegrity representative if your account is not approved in 24hrs.</Text>
        </Box>
    </Box>
}
export default NotApprovedPage