import { Box, HStack, Text } from '@chakra-ui/react';
import { TriangleIcon, TriangleRedIcon } from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';
import { convertToPositiveDollar } from '../utils/common.utils';

const CollectedRevenueTile = ({ totalUpcoded, totalDowncoded, totalUpcodedReviewed, totalDowncodedReviewed, description }) => {

    return (
        <Box alignItems={'left'} >

            <HStack mt={4} spacing={2} alignItems="center">
                <TriangleIcon />
                <Text
                    fontSize="xs"
                    fontFamily={FONT_FAMILY.ptSans}
                    color="green"
                    mr={1}
                >
                    {convertToPositiveDollar(totalDowncoded)}
                </Text>

                <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="gray.700"
                    ml={2}
                >
                    -  Lost Revenue Recovered
                </Text>
            </HStack>

            <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontSize={'5xl'}
                fontWeight="bold"
                mt={1}
            >
                {convertToPositiveDollar(totalDowncodedReviewed)}
            </Text>
            <HStack mt={4} spacing={2} alignItems="center">
                <TriangleRedIcon />
                <Text
                    fontSize="xs"
                    fontFamily={FONT_FAMILY.ptSans}
                    color="red"
                    mr={1}
                >
                    {convertToPositiveDollar(totalUpcoded)}
                </Text>
                <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="gray.700"
                    ml={2}
                >
                    - Revenue Justified
                </Text>
            </HStack>
            <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontSize={'5xl'}
                fontWeight="bold"
                mt={1}
            >
                {convertToPositiveDollar(totalUpcodedReviewed)}
            </Text>
            <Text fontFamily={FONT_FAMILY.ptSans} fontSize={'sm'} mt={3}>
                {description}
            </Text>
        </Box>
    );
};
export default CollectedRevenueTile;
