import { Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import AppColors from '../../../constants/AppColors';
import ROUTES from '../../../constants/Routes';

const ErrorComponent = () => {
  const navigate = useNavigate();

  return (
    <CQContainer
      textAlign={'center'}
      bgColor={AppColors.white}
      borderRadius={'lg'}
      boxShadow={'md'}
    >
      <Text>The User Type does not exist ;-(</Text>
      <Button
        borderRadius={'full'}
        color={AppColors.white}
        bgColor={AppColors.primary}
        onClick={() => navigate(ROUTES.DASHBOARD)}
        _hover={{
          transition: 'all .1s ease',
          bgColor: AppColors.white,
          color: AppColors.primary,
          outline: `2px solid ${AppColors.primary}`,
        }}
      >
        Go to dashboard
      </Button>
    </CQContainer>
  );
};

export default ErrorComponent;
