import { API } from '../config/AppConfig';
import { AUDIT_RESULT, MY_CHARTS } from '../constants/Endpoints';

class MyChartRepository {
  async getCharts(query) {
    const response = await API.get(MY_CHARTS, {
      queryParams: query,
    });
    return response;
  }

  async getAuditResults(query) {
    const response = await API.get(AUDIT_RESULT, {
      queryParams: query,
    });
    return response;
  }
}

const myChartRepository = new MyChartRepository();
export default myChartRepository;
