/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import {
  DepartmentIcon,
  FilledPlusIcon,
  GroupIcon,
  HealthSystemShortIcon,
  LocationIcon,
  PracticeIcon,
} from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PRACTICE_INFO } from './validation';
import NavigationFooter from '../common_files/NavigationFooter';
import AppColors from '../../../../../constants/AppColors';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { FONT_FAMILY } from '../../../../../constants/Theme';
import { components } from 'react-select';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { REGISTER_ERROR_PAGE, STATES } from '../../../../../constants/constants';
import AsyncSelect from 'react-select/async';
import authRepository from '../../../../../repositories/AuthRepository';
import StateDropDown from '../../../../dashboard/practice_info/forms/StateDropDown';


const PracticeInfo = ({ onPreviousClick, onNextClick }) => {
  const authContext = useContext(AuthContext);
  const {
    onboardingObject,
    setOnboardingObject,
    healthSystemDropdown,
    setHealthSystemDropdown,
    practiceNameDropdown,
    setPracticeNameDropdown,
    registerError,
    currentPage,
    registerBody,
    clearErrors,
    specialtiesDropdown,
    setSpecialtiesDropdown,
  } = useContext(AuthContext);

  const [dataValue, setDataValue] = useState({});
  const [newHealthSystem, setNewHealthSystem] = useState(false);
  const [newPracticeName, setNewPracticeName] = useState(false);
  const [practiceDataValue, setPracticeDataValue] = useState({});
  const [departmentDataValue, setDepartmentDataValue] = useState({});

  const form = useForm({
    resolver: yupResolver(PRACTICE_INFO),
  });

  const { control, handleSubmit, setValue, setError, formState:{ errors }, getValues,clearErrors:clearError } = form;

  const onSubmitData = data => {
    setOnboardingObject({ ...onboardingObject, ...data });
    onNextClick();
  };

  useEffect(() => {
    if (Object.keys(dataValue).length) {
      if (dataValue.value !== dataValue.label) {
        authContext.getHeathSystemHospitals(dataValue.value);
      } else {
        setPracticeNameDropdown([]);
      }
      if (!dataValue.back) {
        setPracticeDataValue({});
        setDepartmentDataValue({});
      }
    }
  }, [dataValue]);

  useEffect(() => {
    if (Object.keys(practiceDataValue).length) {
      if (practiceDataValue.id) {
        authContext.getHospitalDepartments(practiceDataValue.id);
      } else {
        authContext.getSpecialties();
      }
      if (!practiceDataValue.back) {
        setDepartmentDataValue({});
      }
    }
  }, [practiceDataValue]);

  useEffect(() => {
    authContext.getHealthSystem();
    if (
      Object.keys(onboardingObject).length &&
      !onboardingObject.primaryEmailOnboarding
    ) {
      if (!onboardingObject.new_health_system) {
        setNewHealthSystem(false);
        if (onboardingObject.health_system_name) {
          setDataValue({
            label: onboardingObject.health_system_name,
            value: onboardingObject.health_system_id,
            back: true,
          });
        }
        setValue('health_system_name', onboardingObject.health_system_name);
        setValue('health_system_id', onboardingObject.health_system_id);
        setValue('new_health_system', onboardingObject.new_health_system);
      } else {
        if (onboardingObject.health_system_name) {
          setNewHealthSystem(true);
          if (onboardingObject.health_system_name) {
            setDataValue({
              label: onboardingObject.health_system_name,
              value: onboardingObject.health_system_name,
              back: true,
            });
          }
          setValue('health_system_name', onboardingObject.health_system_name);
          setValue('health_system_id', '');
          setValue(
            'health_system_address',
            onboardingObject.health_system_address
          );
          setValue('new_health_system', onboardingObject.new_health_system);
        }
      }
      if (onboardingObject.new_hospital) {
        setNewPracticeName(true);
        if (onboardingObject.practice_name) {
          setPracticeDataValue({
            label: onboardingObject.practice_name,
            value: onboardingObject.practice_name,
            back: true,
          });
        }
        setValue('practice_name', onboardingObject.practice_name);
        setValue('state', onboardingObject.state);
        setValue('patients_per_month', onboardingObject.patients_per_month);
        setValue('new_hospital', onboardingObject.new_hospital);
      } else {
        if (onboardingObject.practice_name) {
          setNewPracticeName(false);
          if (onboardingObject.practice_name) {
            setPracticeDataValue({
              label: onboardingObject.practice_name,
              value: onboardingObject.practice_id,
              id: onboardingObject.practice_id,
              back: true,
            });
          }
          setValue('practice_name', onboardingObject.practice_name);
          setValue('practice_id', onboardingObject.practice_id);
          setValue('new_hospital', onboardingObject.new_hospital);
        }
      }
      if (onboardingObject.department_id) {
        setValue('department_name', onboardingObject.department_name);
        if (onboardingObject.department_name) {
          setDepartmentDataValue({
            label: onboardingObject.department_name,
            id: onboardingObject.department_id,
            value: onboardingObject.department_id,
          });
        }
      } else {
        if (onboardingObject.department_name) {
          setValue('department_name', onboardingObject.department_name);
          setDepartmentDataValue({
            label: onboardingObject.department_name,
            value: onboardingObject.department_name,
          });
        }
      }
    }
    if (Object.keys(registerError).length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage].map(key => {
        if (registerErrorKeys.includes(key)) {
          if (key === 'health_system') {
            setError('health_system_name', {
              type: 'custom',
              message: registerError[key],
            });
          } else if (key === 'department') {
            setError('department_name', {
              type: 'custom',
              message: registerError[key],
            });
          } else {
            setError(key, { type: 'custom', message: registerError[key] });
          }
        }
      });
    }
  }, []);

  const getData = async () => {
    if (onboardingObject.primaryEmailOnboarding) {
      const data = await authContext.getHealthSystemById(
        onboardingObject.health_system_id
      );
      const hospitalData = await authContext.getHospitalById(
        onboardingObject.practice_id
      );
      const departmentData = await authContext.getDepartmentById(
        onboardingObject.department_id
      );

      setHealthSystemDropdown([
        {
          label: data.name,
          value: onboardingObject.health_system_id,
          address: data.address,
        },
      ]);
      setPracticeNameDropdown([
        {
          label: hospitalData.name,
          value: onboardingObject.practice_id,
          address: hospitalData.address,
          id: onboardingObject.practice_id,
        },
      ]);
      setSpecialtiesDropdown([
        {
          label: departmentData.name,
          value: onboardingObject.department_id,
          id: onboardingObject.department_id,
        },
      ]);
      setDataValue({
        label: data.name,
        value: onboardingObject.health_system_id,
        address: data.address,
      });
      setPracticeDataValue({
        label: hospitalData.name,
        value: onboardingObject.practice_id,
        address: hospitalData.address,
      });
      setDepartmentDataValue({
        label: departmentData.name,
        value: onboardingObject.department_id,
      });
      setValue('health_system_name', data.name);
      setValue('practice_name', hospitalData.name);
      setValue('department_name', departmentData.name);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatCreateLabel = inputValue => {
    return (
      <Box>
        <Text
          fontSize={'small'}
          color={AppColors.gray}
        >{` No results matching " ${inputValue} "`}</Text>
        <HStack bg={'rgba(67,218,212,0.07)'} p={1} fontSize={'xs'}>
          <FilledPlusIcon style={{ height: 15, width: 15 }} />
          <span color={AppColors.secondary}>
            {' '}
            Add <span style={{ fontWeight: 'bolder' }}>{inputValue}</span> to
            RevIntegrity{' '}
          </span>
        </HStack>
      </Box>
    );
  };

  const formatOptionLabel = props => {
    return (
      <Box>
        <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm">
          {props.label}
          <span
            style={{ fontSize: 10, color: AppColors.darkGray, marginLeft: 5 }}
          >
            {props.address}
          </span>
        </Text>
      </Box>
    );
  };

  const clearErrorData = (key, data) => {
    if (registerBody[key] && data !== registerBody[key]) {
      clearErrors(key);
    } else if (data === registerBody[key] && registerError[key]) {
      setError(key, { type: 'custom', message: registerError[key] });
    }
  };

  const handleHelthSystemCreate = inputValue => {
    setNewHealthSystem(true);
    setHealthSystemDropdown(prev => [
      ...prev,
      { label: inputValue, value: inputValue, address: '' },
    ]);
    setDataValue({ label: inputValue, value: inputValue });
    setOnboardingObject(prev => ({
      ...prev,
      health_system_name: inputValue,
      health_system_id: '',
      new_health_system: true,
    }));
    setValue('health_system_name', inputValue);
    setValue('new_health_system', true);
  };

  const handlePracticeNameCreate = inputValue => {
    setNewPracticeName(true);
    setPracticeNameDropdown(prev => [
      ...prev,
      { label: inputValue, value: inputValue, address: '' },
    ]);
    setPracticeDataValue({ label: inputValue, value: inputValue, address: '' });
    setOnboardingObject(prev => ({ ...prev, practice_name: inputValue }));
    setValue('practice_name', inputValue);
    setValue('new_hospital', true);
  };

  const promiseOptions = async inputValue => {
    if (practiceDataValue.id) {
      return authRepository.getHospitalDepartments(practiceDataValue.id, {
        search: inputValue,
      });
    } else {
      return authRepository.getSpecialties({ search: inputValue });
    }
  };
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {props.selectProps.inputValue
            ? 'We don’t currently offer your specialty'
            : 'No options'}
        </span>
      </components.NoOptionsMessage>
    );
  };

  const renderOldHospitalFields = () => {
    return (
      <AsyncSelect
        name="department_name"
        id="department_name"
        control={control}
        formatOptionLabel={formatOptionLabel}
        loadOptions={promiseOptions}
        cacheOptions
        defaultOptions={specialtiesDropdown}
        onChange={newValue => {
          setDepartmentDataValue(newValue);
          setValue('department_name', newValue.label);
          if (!newValue.id) {
            setOnboardingObject(prev => ({
              ...prev,
              department_name: newValue.label,
            }));
          } else {
            setOnboardingObject(prev => ({
              ...prev,
              department_name: newValue.label,
              department_id: newValue.value,
            }));
          }
          clearErrorData('department_name', newValue.label);
        }}
        styles={{
          noOptionsMessage: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
          }),
          menu: props => ({
            ...props,
            zIndex: 10,
          }),
        }}
        components={{
          Control: ({ children, ...props }) => {
            return (
              <components.Control {...props}>
                <span style={{ marginLeft: 10 }}>
                  {/* <PracticeIcon /> */}
                  <DepartmentIcon style={{ height: 20, width: 20 }} />
                </span>
                {children}
              </components.Control>
            );
          },
          NoOptionsMessage: NoOptionsMessage,
        }}
        placeholder="Department Name"
        value={
          departmentDataValue && Object.keys(departmentDataValue).length === 0
            ? ''
            : departmentDataValue
        }
      />
    );
  };
  const renderPracticeInfoFields = () => {
    return (
      <>
        <Box mt={5}>
          <StateDropDown
            value={STATES.find(state => state.value === getValues('state'))}
            name="state"
            width={'100%'}
            id="state"
            onChange={e => {
              setValue('state', e.value);
              clearError('state');
            }}
            control={control}
            errors={errors}
          />
          {/* <FormTextInput
            name="state"
            id="state"
            placeholder="Practice Address"
            style={{ opacity: '1' }}
            leftIcon={<LocationIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            control={control}
          /> */}
        </Box>
        <Box mt={5}>
          <HStack>
            <Box w={'sm'}>
              <FormTextInput
                name="patients_per_month"
                id="patients_per_month"
                placeholder="No. of patients / Month"
                style={{ opacity: '1' }}
                leftIcon={<GroupIcon />}
                leftIconProps={{ height: '50px' }}
                height="50px"
                control={control}
              />
            </Box>
            <Box w={'sm'}>{renderOldHospitalFields()}</Box>
          </HStack>
        </Box>
      </>
    );
  };

  const renderNewHealthSystemFields = () => {
    return (
      <StateDropDown
        value={STATES.find(state => state.value === getValues('health_system_address'))}
        name="health_system_address"
        width={'100%'}
        id="health_system_address"
        onChange={e => {
          setValue('health_system_address', e.value);
          clearError('health_system_address');
        }}
        control={control}
        errors={errors}
      />
      // <FormTextInput
      //   name="health_system_address"
      //   id="health_system_address"
      //   placeholder="Health System Address"
      //   style={{ opacity: '1' }}
      //   leftIcon={<LocationIcon />}
      //   leftIconProps={{ height: '50px' }}
      //   height="50px"
      //   control={control}
      // />
    );
  };

  //  health system
  const hsFilterOptions = inputValue => {
    return healthSystemDropdown.filter(healthSystem => {
      const healthSystemName = healthSystem.label?.toLowerCase();
      const searchedValue = inputValue.toLowerCase();
      return healthSystemName.startsWith(searchedValue);
    });
  };

  const hsPromiseOptions = inputValue => {
    if (inputValue.length >= 5) {
      return new Promise(resolve => {
        resolve(hsFilterOptions(inputValue));
      });
    }
  };

    //  hospital
    const hospitalFilterOptions = inputValue => {
      return practiceNameDropdown.filter(healthSystem => {
        const healthSystemName = healthSystem.label?.toLowerCase();
        const searchedValue = inputValue.toLowerCase();
        return healthSystemName.startsWith(searchedValue);
      });
    };
  
    const hospitalPromiseOptions = inputValue => {
      if (inputValue.length >= 5) {
        return new Promise(resolve => {
          // setTimeout(() => {
          resolve(hospitalFilterOptions(inputValue));
          // }, 100);
        });
      }
    };

  return (
    <Box justifyContent={'center'} display={'flex'}>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Box w={'2xl'}>
          {!newPracticeName && !newHealthSystem && (
            <Text size={'md'} fontSize={'lg'} align="center">
              Enter your{' '}
              <span style={{ color: AppColors.primary }}>
                practice's details
              </span>{' '}
              below:
            </Text>
          )}
          {newHealthSystem && !newPracticeName && (
            <Text size={'md'} fontSize={'lg'} align="center">
              Which{' '}
              <span style={{ color: AppColors.primary }}>Health system</span> do
              you belong to
            </Text>
          )}
          <Box mt={5} zIndex={999}>
            <AsyncCreatableSelect
              name="health_system_name"
              id="health_system_name"
              control={control}
              formatCreateLabel={formatCreateLabel}
              onChange={newValue => {
                setDataValue(newValue);
                setValue('health_system_name', newValue.label);
                if (!newValue.id) {
                  setNewHealthSystem(true);
                  setValue('new_health_system', true);
                  setValue('health_system_id', '');
                  setOnboardingObject(prev => ({
                    ...prev,
                    health_system_name: newValue.label,
                  }));
                } else {
                  setNewHealthSystem(false);
                  setValue('health_system_id', newValue.value);
                  setValue('new_health_system', false);
                  setOnboardingObject(prev => ({
                    ...prev,
                    health_system_name: newValue.label,
                    health_system_id: newValue.value,
                  }));
                }
                clearErrorData('health_system_name', newValue.label);
              }}
              isSearchable={true}
              placeholder="Health System Name ( Enter minimum 5 characters to search )"
              // options={healthSystemDropdown}
              defaultOptions={[]}
              loadOptions={hsPromiseOptions}
              value={
                dataValue && Object.keys(dataValue).length === 0
                  ? ''
                  : dataValue
              }
              styles={{
                noOptionsMessage: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                menu: props => ({
                  ...props,
                  zIndex: 10,
                }),
              }}
              formatOptionLabel={formatOptionLabel}
              components={{
                Control: ({ children, ...props }) => {
                  return (
                    <components.Control {...props}>
                      <span style={{ marginLeft: 10 }}>
                        <HealthSystemShortIcon />
                      </span>
                      {children}
                    </components.Control>
                  );
                },
              }}
              onCreateOption={handleHelthSystemCreate}
            />
          </Box>
          <Box mt="5" zIndex={99}>
            {newHealthSystem ? renderNewHealthSystemFields() : null}
          </Box>
          <Box mt="5" zIndex={9}>
            {' '}
            <AsyncCreatableSelect
              name="practice_name"
              id="practice_name"
              control={control}
              formatCreateLabel={formatCreateLabel}
              onChange={newValue => {
                setPracticeDataValue(newValue);
                setValue('practice_name', newValue.label);
                if (!newValue.id) {
                  setNewPracticeName(true);
                  setValue('new_department', true);
                  setValue('practice_id', '');

                  setOnboardingObject(prev => ({
                    ...prev,
                    practice_name: newValue.label,
                  }));
                } else {
                  setNewPracticeName(false);
                  setValue('new_hospital', false);
                  setValue('practice_id', newValue.value);
                  setOnboardingObject(prev => ({
                    ...prev,
                    practice_name: newValue.label,
                    practice_id: newValue.value,
                  }));
                }
                clearErrorData('practice_name', newValue.label);
              }}
              isSearchable={true}
              placeholder="Practice/Hospital Name ( Enter minimum 5 characters to search )"
              defaultOptions={[]}
              loadOptions={hospitalPromiseOptions}
              // options={practiceNameDropdown}
              value={
                practiceDataValue && Object.keys(practiceDataValue).length === 0
                  ? ''
                  : practiceDataValue
              }
              styles={{
                noOptionsMessage: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                menu: props => ({
                  ...props,
                  zIndex: 10,
                }),
              }}
              formatOptionLabel={formatOptionLabel}
              components={{
                Control: ({ children, ...props }) => {
                  return (
                    <components.Control {...props}>
                      <span style={{ marginLeft: 10 }}>
                        <PracticeIcon />
                      </span>
                      {children}
                    </components.Control>
                  );
                },
              }}
              onCreateOption={handlePracticeNameCreate}
            />
          </Box>
          <Box mt="5">
            {Object.keys(practiceDataValue).length
              ? practiceDataValue.id
                ? renderOldHospitalFields()
                : renderPracticeInfoFields()
              : null}
          </Box>
          <Box mt={14} />
          <NavigationFooter
            onPreviousClick={() => {
              if (
                !onboardingObject.primaryEmailOnboarding &&
                !onboardingObject.primary_owner_email
              ) {
                onPreviousClick(1);
              } else {
                onPreviousClick();
              }
            }}
            currentPageTitle={onboardingObject.userType}
          />
        </Box>
      </form>
    </Box>
  );
};
export default PracticeInfo;
