import { Stack, Image, Box } from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { QWatermark } from '../../../constants/ImageData';
import { useContext } from 'react';
import { AuthContext, AUTH_FORMS } from '../../../providers/AuthProvider';
import { renderCoverImage } from '../../../components/AuthCoverImage';
import { CQForgotPasswordMessage } from '@laxmimanogna/code-quick-components';

export default function ConfirmationContainer() {
  const authContext = useContext(AuthContext);
  const formServerError = authContext.forms[AUTH_FORMS.FORGOT_PASSWORD]?.error;

  return (
    <Box bgColor={AppColors.primary} py={'5%'} px={'20%'} minH={'100vh'}>
      <Image
        top={'5vh'}
        left={30}
        style={{ height: '95vh' }}
        position={'absolute'}
        src={QWatermark}
        alt="q-watermark"
      />
      <Stack spacing={-8} direction={{ base: 'column', md: 'row' }}>
        {renderCoverImage()}
        <CQForgotPasswordMessage
          forgotPasswordEmail={authContext.forgotPasswordEmail}
          onForgotPassword={authContext.onForgotPassword}
          formServerError={formServerError}
        />
      </Stack>
    </Box>
  );
}
