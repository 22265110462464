/* eslint-disable react-hooks/exhaustive-deps */
import { useBoolean } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { withProvider } from '../../../hoc/withProvider';
import MyAccountProvider, {
  MyAccountContext,
} from '../../../providers/MyAccountProvider';
import EditAccountsModal from './EditAccountsModal';

const HealthSystemMyAccount = ({ getData }) => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUser = myAccountContext.currentUserType;

  useEffect(() => {
    myAccountContext.userTypeLookup();
  }, []);

  const [isAccountModalOpen, setIsAccountModalOpen] = useBoolean(false);
  return (
    <>
      <EditAccountsModal
        isOpen={isAccountModalOpen}
        onClose={setIsAccountModalOpen.off}
        accountData={currentUser?.account_contact}
        getData={getData}
      />
    </>
  );
};

export default withProvider(MyAccountProvider, HealthSystemMyAccount);
