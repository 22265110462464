import { Box, Circle, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react"
import AppColors from "../../../constants/AppColors"

const TilePopup = ({ renderBody, isOpen, onClose }) => {
    return <>
        <Modal
            isOpen={isOpen}
            size={'lg'}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay bg={AppColors.modalOverlayColor} />
            <ModalContent alignItems={'center'} minW={'600px'}p={3}>
                <Box
                    style={{
                        zIndex: '1',
                    }}
                    position={'absolute'}
                    top={-10}
                    right={-10}
                >
                    <Circle>
                        <ModalCloseButton
                            size={'sm'}
                            style={{ color: AppColors.white }}
                            backgroundColor={'#8894A6'}
                            p={3}
                            onClick={onClose}
                            borderRadius={50}
                        />
                    </Circle>
                </Box>
                <ModalBody width={'100%'}  height={'100%'}>
                    {renderBody(true)}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}

export default TilePopup