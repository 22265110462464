export const CLIENT = 'CLIENT';
export const SELF = 'self';
export const RECEIVED = 'RECEIVED';
export const AWAITING_AUDIT = 'AWAITING AUDIT';
export const STATUS = {
  RECEIVED: 'Received',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
};

export const UPLOAD_BREAKDOWN_STATUS = {
  RECEIVED: 'RECEIVED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
};

export const REQUIRED_FIELDS = [
  'encounter_no',
  'rendering',
  'enc_dt',
  'srvcs_no',
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
  'audited_code',
  'audited_rvu',
  'audited_dollar_value',
];
export const ROLES_VALUES = {
  QA: 'QA',
  AUDITOR: 'Auditor',
  MANAGER: 'Manager',
  CLIENT: 'Client',
};
export const USER_TYPE = [
  {
    title: 'Health System C-Level',
    description:
      'The highest-ranking executives in a hospital, such as the CEO, CFO, COO, CCO, and CMO. These individuals are responsible for the overall strategic direction, compliance and financial performance of the hospital.',
  },
  {
    title: 'Hospital Executive',
    description:
      'Senior-level leaders in a hospital, as well as other senior vice presidents and directors. These individuals are responsible for overseeing the day-to-day operations of the hospital and ensuring that it meets its goals.',
  },
  {
    title: 'Department Head',
    description:
      'Department Head is a role that is responsible for overseeing the operations of a specific department within a hospital, such as the Emergency Department, the Operating Room, or the Radiology Department. These individuals are responsible for ensuring that the department meets its goals and objectives, and that it provides high-quality care to patients.',
  },
  {
    title: "Physician's Group",
    description:
      ' These groups are typically organized by specialty, such as internal medicine, primary care, or surgery. If you work within a physician group, this is your best option.',
  },
  {
    title: 'Provider',
    description:
      'This includes physicians, nurses, hospitals, and other healthcare providers. Providers are responsible for providing high-quality care to patients, as well as for managing the financial and administrative aspects of their practice.',
  },
];
export const PRIMARY_OWNER_TOOLTIP = [
  {
    title: 'What is primary account owner?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc efficitur tellus ante, at rutrum velit vestibulum a. ',
  },
];
export const CREATE_ACCOUNT = 'Create Account';
export const PRIMARY_OWNER = 'Primary Owner';
export const REGISTER = 'Register / Sign Up';
export const PHYSICIAN_INFO = "Physician's Group Info";
export const REGISTER_ERROR_PAGE = [
  ['first_name', 'last_name', 'email'],
  ['primary_owner_email'],
  ['health_system', 'specialty', 'department'],
  [],
  ['email', 'password'],
  ['health_system', 'practice_name'],
];
export const PRACTICE_INFO = 'Practice Info';
export const PRACTICE = 'Practice';
export const XAXISLABEL = {
  day: 'Hours',
  week: 'Days',
  last_week: 'Days',
  month: 'Dates',
  last_month: 'Dates',
  year: 'Months',
  last_year: 'Months',
};

export const CUSTOM = 'custom';
export const DAY = 'day';
export const NOV = 'new_office_visits';
export const EOV = 'established_office_visits';
export const TOTAL_UPLOADS = 'TOTAL UPLOADS';
export const CQ_SCORE = 'RI SCORE';
export const RVU_OPPORTUNITY = 'RVU OPPORTUNITY';
export const AI_RISK = 'AI RISK';

export const HEALTH_SYSTEM_TYPE = 'HEALTH SYSTEM';
export const HOSPITAL_TYPE = 'HOSPITAL';
export const DEPARTMENT_TYPE = 'DEPARTMENT';
export const PHYSICIAN_GROUP_TYPE = 'PHYSICIANS GROUP';
export const PROVIDER_TYPE = 'PROVIDER';

export const defaultColumns = [
  'provider_education',
  'number_complexity',
  'amount_complexity',
  'risk_complications',
];

export const addionalAttributesKeys = [
  'srvcs_no_',

  'icd_agree',
  'icd_disagree',

  'billed_cpt',
  'billed_cpt_',

  'billed_modifier',
  'billed_modifier_',

  'audited_icd_',

  'audited_cpt',
  'audited_cpt_',

  'audited_modifier',
  'audited_modifier_',

  ...defaultColumns,
];

export const firstSetColumnKeyOrder = [
  'missing_info',
  'file_name',
  'encounter_no',
  'rendering',
  'enc_dt',
];

export const billedIctColumn = ['srvcs_no'];

export const billedCptColumn = ['billed_cpt'];

export const billedModifierColumn = ['billed_modifier'];

export const rvuColumnsOrder = [
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
  'icd_agree',
  'icd_disagree',
];

export const auditedIctColumn = ['audited_icd'];

export const auditedCptColumn = ['audited_cpt'];

export const auditedModifierColumn = ['audited_modifier'];

export const auditedRuvColumns = [
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];

export const defaultDataProps = ['file_name', 'chart_id', 'id', 'row_id'];

export const dynamicColumnKeywords = [
  'srvcs_no',
  'billed_cpt',
  'billed_modifier',
  'audited_icd',
  'audited_cpt',
  'audited_modifier',
];

export const defaultColNames = [
  'missing_info',
  'file_name',
  'encounter_no',
  'rendering',
  'enc_dt',
  'srvcs_no',
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];

export const myChartsTabsIndexs = {
  0: 'ALL',
  1: 'ARCHIVED',
};



export const STATES = [
  {
    label: 'Alabama, AL',
    value: 'AL',
  },
  {
    label: 'Alaska, AK',
    value: 'AK',
  },
  {
    label: 'Arizona, AZ',
    value: 'AZ',
  },
  {
    label: 'Arkansas, AR',
    value: 'AR',
  },
  {
    label: 'California, CA',
    value: 'CA',
  },
  {
    label: 'Colorado, CO',
    value: 'CO',
  },
  {
    label: 'Connecticut, CT',
    value: 'CT',
  },
  {
    label: 'Delaware, DE',
    value: 'DE',
  },
  {
    label: 'Florida, FL',
    value: 'FL',
  },
  {
    label: 'Georgia, GA',
    value: 'GA',
  },
  {
    label: 'Hawaii, HI',
    value: 'HI',
  },
  {
    label: 'Idaho, ID',
    value: 'ID',
  },
  {
    label: 'Illinois, IL',
    value: 'IL',
  },
  {
    label: 'Indiana, IN',
    value: 'IN',
  },
  {
    label: 'Iowa, IA',
    value: 'IA',
  },
  {
    label: 'Kansas, KS',
    value: 'KS',
  },
  {
    label: 'Kentucky, KY',
    value: 'KY',
  },
  {
    label: 'Louisiana, LA',
    value: 'LA',
  },
  {
    label: 'Maine, ME',
    value: 'ME',
  },
  {
    label: 'Maryland, MD',
    value: 'MD',
  },
  {
    label: 'Massachusetts, MA',
    value: 'MA',
  },
  {
    label: 'Michigan, MI',
    value: 'MI',
  },
  {
    label: 'Minnesota, MN',
    value: 'MN',
  },
  {
    label: 'Mississippi, MS',
    value: 'MS',
  },
  {
    label: 'Missouri, MO',
    value: 'MO',
  },
  {
    label: 'Montana, MT',
    value: 'MT',
  },
  {
    label: 'Nebraska, NE',
    value: 'NE',
  },
  {
    label: 'Nevada, NV',
    value: 'NV',
  },
  {
    label: 'New Hampshire, NH',
    value: 'NH',
  },
  {
    label: 'New Jersey, NJ',
    value: 'NJ',
  },
  {
    label: 'New Mexico, NM',
    value: 'NM',
  },
  {
    label: 'New York, NY',
    value: 'NY',
  },
  {
    label: 'North Carolina, NC',
    value: 'NC',
  },
  {
    label: 'North Dakota, ND',
    value: 'ND',
  },
  {
    label: 'Ohio, OH',
    value: 'OH',
  },
  {
    label: 'Oklahoma, OK',
    value: 'OK',
  },
  {
    label: 'Oregon, OR',
    value: 'OR',
  },
  {
    label: 'Pennsylvania, PA',
    value: 'PA',
  },
  {
    label: 'Rhode Island, RI',
    value: 'RI',
  },
  {
    label: 'South Carolina, SC',
    value: 'SC',
  },
  {
    label: 'South Dakota, SD',
    value: 'SD',
  },
  {
    label: 'Tennessee, TN',
    value: 'TN',
  },
  {
    label: 'Texas, TX',
    value: 'TX',
  },
  {
    label: 'Utah, UT',
    value: 'UT',
  },
  {
    label: 'Vermont,VT',
    value: 'VT',
  },
  {
    label: 'Virginia,VA',
    value: 'VA',
  },
  {
    label: 'Washington,WA',
    value: 'WA',
  },
  {
    label: 'West Virginia,WV',
    value: 'WV',
  },
  {
    label: 'Wisconsin,WI',
    value: 'WI',
  },
  {
    label: 'Wyoming,WY',
    value: 'WY',
  },
  {
    label: 'Armed Forces (the) Americas, AA',
    value: 'AA',
  },
  {
    label: 'Armed Forces Europe, AE',
    value: 'AE',
  },
  {
    label: 'Armed Forces Pacific, AP',
    value: 'AP',
  },
  {
    label: 'American Samoa, AS',
    value: 'AS',
  },
  {
    label: 'District of Columbia, DC',
    value: 'DC',
  },
  {
    label: 'Guam, GU',
    value: 'GU',
  },
  {
    label: 'Northern Mariana Islands, MP',
    value: 'MP',
  },
  {
    label: 'Puerto Rico, PR',
    value: 'PR',
  },
  {
    label: 'Virgin Islands, VI',
    value: 'VI',
  },
  {
    label: 'National totals, XX',
    value: 'XX',
  },
  {
    label: 'Generic field, ZZ',
    value: 'ZZ',
  },
];

export const headingValidationRegex = /^(?!^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$)(?!^[\s]*$)(?!^[0-9]*$).+$/ ;

export const onlyAlphabetsRegex = /^[a-zA-Z]+$/;
