import { API } from "../config/AppConfig";
import { AUDITSHEET_USER, AUDIT_SHEET, AUDIT_SHEET_INDUSTRY, AUDIT_SHEET_PROVIDER_DROPDOWN, AUDIT_UPLOAD, CLIENT_AUDIT_SHEET, GET_ALL_USER_API, GET_CLIENT_COMMENT, GET_CLIENT_COMMENT_FLAG, GET_COMMENT, GET_COMMENT_FLAG, MANAGE_COMMENT, MONITOR_HOUR, POST_COMMENT, UPDATE_ACTIVITY } from "../constants/Endpoints";

class AuditSheetRepository {
    async getAuditUpload(uploadId) {
        const response = await API.get(AUDIT_UPLOAD, {
            pathParams: { uploadId },
        });
        return response;
    }

    async getBatchAuditSheet(batchId) {
        const response = await API.get(CLIENT_AUDIT_SHEET, {
            pathParams: { batchId },
        });
        return response;
    }
    async getAuditSheet(uploadId) {
        const response = await API.get(AUDIT_SHEET, {
            pathParams: { uploadId },
        });
        return response;
    }
    async createAuditSheet(payload, uploadId) {
        const response = await API.post(AUDIT_SHEET, {
            pathParams: { uploadId },
            body: payload
        });
        return response;
    }

    async updateAuditSheet(payload, uploadId) {
        const response = await API.put(AUDIT_SHEET, {
            pathParams: { uploadId },
            body: payload
        });
        return response;
    }

    async auditHourMonitor(uploadId) {
        const response = await API.post(MONITOR_HOUR, {
            pathParams: { uploadId },
        });
        return response;
    }

    async recentAuditImageUpload(uploadId, data) {
        const response = await API.put(MONITOR_HOUR, {
            pathParams: { uploadId },
            body: data
        });
        return response;
    }

    async getIndustryCodes() {
        const response = await API.get(AUDIT_SHEET_INDUSTRY);
        return response;
    }

    async getUsers() {
        const response = await API.get(AUDITSHEET_USER);
        return response;
    }

    async updateActivity(data) {
        const response = await API.post(UPDATE_ACTIVITY, {
            body: data
        });
        return response;
    }

    async getComments(params) {
        const { uploadId, batchId, ...queryParams } = params
        const response = await API.get(uploadId ? GET_COMMENT : GET_CLIENT_COMMENT, {
            queryParams,
            pathParams: { uploadId, batchId }
        });
        return response;
    }

    async getAllUsers() {
        const response = await API.get(GET_ALL_USER_API);
        return response;
    }
    async getCommentFlag({ uploadId, batchId }) {
        const response = await API.get(uploadId ? GET_COMMENT_FLAG : GET_CLIENT_COMMENT_FLAG, {
            pathParams: { uploadId, batchId }
        });
        return response;
    }
    async deleteComment(commentId) {
        const response = await API.delete(MANAGE_COMMENT, {
            pathParams: { commentId },
        });
        return response;
    }
    async editComment(commentId, payload) {
        const response = await API.put(MANAGE_COMMENT, {
            pathParams: { commentId },
            body: payload
        });
        return response;
    }
    async postComment(data) {
        const response = await API.post(POST_COMMENT, {
            body: data
        });
        return response;
    }
    async getProviderDropdown(chartId) {
        const response = await API.get(AUDIT_SHEET_PROVIDER_DROPDOWN, {
            pathParams: { chartId },
        });
        return response;
    }
}

const auditSheetRepository = new AuditSheetRepository();
export default auditSheetRepository;