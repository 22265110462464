import { add } from 'date-fns';

const getDateInterval = key => {
  const filterDate = {};
  const yesterday = add(new Date(), {
    days: -1,
  });
  switch (key.toLowerCase()) {
    case 'day':
      filterDate.start_date = add(new Date(), {
        days: -1,
      });
      filterDate.end_date = new Date();
      filterDate.previous_start_date = add(new Date(), {
        days: -2,
      });
      filterDate.previous_end_date = add(new Date(), {
        days: -1,
      });
      return filterDate;
    case 'week':
      filterDate.start_date = add(yesterday, {
        weeks: -1,
      });
      filterDate.end_date = yesterday;
      filterDate.previous_start_date = add(yesterday, {
        weeks: -2,
      });
      filterDate.previous_end_date = add(yesterday, {
        weeks: -1,
      });
      return filterDate;
    case 'month':
      filterDate.start_date = add(yesterday, {
        days: -30,
      });
      filterDate.end_date = yesterday;
      filterDate.previous_start_date = add(yesterday, {
        days: -60,
      })
      filterDate.previous_end_date = add(yesterday, {
        days: -30,
      })
      return filterDate;
    case 'year':
      filterDate.start_date = add(yesterday, {
        years: -1,
      });
      filterDate.end_date = yesterday;
      filterDate.previous_start_date = add(yesterday, {
        years: -2,
      })
      filterDate.previous_end_date = add(yesterday, {
        years: -1,
      })
      return filterDate;

    default:
      return {};
  }
};
export { getDateInterval };
