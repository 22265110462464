import { Tooltip, Box, Icon } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import AppColors from '../constants/AppColors';

const InfoTooltip = ({ infoContent }) => {
  return (
    infoContent ? (
      <Tooltip p={3} bg="whiteAlpha.900"
        color="black"
        borderRadius="md"
        border="1px solid #43dde0" label={
          <Box>
            {infoContent}
          </Box>
        } placement="top-end">
        <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
      </Tooltip>
    ) : null
  );
}

export default InfoTooltip;
