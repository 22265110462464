/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { MyAccountContext } from '../../../providers/MyAccountProvider';
import { DepartmentInfoComponent } from './CommonMyAccountComponents';

const ProviderMyAccount = () => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUser = myAccountContext.currentUserType;

  useEffect(() => {
    myAccountContext.userTypeLookup();
  }, []);

  return (
    <>
      <DepartmentInfoComponent departments={currentUser.department_id} />
    </>
  );
};

export default ProviderMyAccount;
