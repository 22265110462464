/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  VStack,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AddIcon } from '@chakra-ui/icons';
import * as yup from 'yup';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { MyAccountContext } from '../../../providers/MyAccountProvider';
import { onlyAlphabetsRegex } from '../../../constants/constants';


const accountContact = yup.object({
  is_primary: yup.bool().required(),
  first_name: yup.string()
    .matches(onlyAlphabetsRegex, 'First Name must be valid')
    .when('is_primary', {
      is: true,
      then: yup.string().required('First Name is required'),
    }),
  last_name: yup.string()
    .matches(onlyAlphabetsRegex, 'Last Name must be valid')
    .when('is_primary', {
      is: true,
      then: yup.string().required('Last Name is required'),
    }),
  email: yup.string().when('is_primary', {
    is: true,
    then: yup.string().email('Email is required').required('Email is required'),
  }),
});

const createAccountSchema = yup.object({
  account_contact: yup.array().of(accountContact).min(1),
});


const EditProviderAccounts = (props) => {

  // props
  const { isOpen, onClose, accountData, setAllProviderList } = props;

  const myAccountContext = useContext(MyAccountContext);

  const GRID_SPACING = 5;

  function createNewContact(primary) {
    return {
      first_name: '',
      last_name: '',
      email: '',
      is_primary: primary,
    };
  }

  const form = useForm({
    resolver: yupResolver(createAccountSchema),
    mode: 'onSubmit',
    defaultValues: {
      account_contact: !accountData?.length
        ? [createNewContact(true)]
        : accountData,
    },
  });

  const { control, handleSubmit, setValue, getValues, reset } = form;
  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  async function submitAddAccount(formData) {
    const accountBody = { ...formData, provider_flag: true };
    const res = await myAccountContext.editUserData(accountBody);
    if (res) {
      setAllProviderList(res.providers_account_contact);
      reset({ account_contact: res.providers_account_contact });
      onClose();
    }
  }
  function renderCheckbox(index){
   return <Controller
      control={control}
      name={`account_contact.${index}.is_primary`}
      render={({ field }) => {
        return (
          <Checkbox {...field} isChecked={field.value} >
            <Text fontSize={'sm'} fontWeight="bold" color={AppColors.secondary}>
              Primary Account
            </Text>
          </Checkbox>
        );
      }}
    />;
  }


  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <VStack display={'block'} mb={6}>
          <SimpleGrid
            columns={{ sm: 1, md: 4 }}
            spacing={GRID_SPACING}
            alignItems={'baseline'}
          >
            <FormTextInput
              name={`account_contact.${index}.first_name`}
              control={control}
              placeholder={'First Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.last_name`}
              control={control}
              placeholder={'Last Name'}
              size="md"
            />

            <FormTextInput
              name={`account_contact.${index}.email`}
              control={control}
              placeholder={'Email'}
              size="md"
            />
            {index !== 0 && (
              <Text
                onClick={() => {
                  contactField.remove(index);
                  accountData?.splice(index, 1);
                  setAllProviderList(accountData);
                }}
                cursor={'pointer'}
                color={AppColors.secondary}
                fontSize="sm"
              >
                Remove
              </Text>
            )}
          </SimpleGrid>
          <Box display={'flex'} alignItems={'center'} gap={'2'}>
            {renderCheckbox(index)}
          </Box>
        </VStack>
      </React.Fragment>
    );
  }

  function renderAddIcon() {
    return (
      <HStack mr={12} mb={5} _hover={{ cursor: 'pointer' }} onClick={()=>{
        contactField.append(createNewContact(false));
      }}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon
          m={1}
          color={AppColors.primary}
          boxSize={4}
          border={`1px solid ${AppColors.primary}`}
          borderRadius={'full'}
        />
      </HStack>
    );
  }

  function renderContacts() {
    return (
      <PerfectScrollbar style={{ maxHeight: '300px' }}>
        <Text>Account Contact</Text>
        {contactField.fields.map(renderAddContactForm)}
        {renderAddIcon()}
      </PerfectScrollbar>
    );
  }
  return (
    <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay style={{ backgroundColor: '#001A41' }} />
      <ModalContent mx={4} px={8}>
        <ModalHeader fontFamily={FONT_FAMILY.baiJamurjee} textAlign={'center'}>
          Edit Providers
        </ModalHeader>
        <Box
          style={{ zIndex: '1' }}
          position={'absolute'}
          top={-10}
          right={-3}
        >
          <Circle>
            <ModalCloseButton
              size={'sm'}
              style={{ color: AppColors.white }}
              backgroundColor={'#8894A6'}
              p={3}
              onClick={onClose}
              borderRadius={50}
            />
          </Circle>
        </Box>
        <ModalBody p={6} px={10}>
          {renderContacts()}
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <Button
            mr={3}
            borderRadius={'full'}
            px={12}
            borderWidth={2}
            borderColor={AppColors.secondary}
            onClick={onClose}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.secondary,
              color: AppColors.white,
            }}
            variant={'outline'}
            fontWeight={'normal'}
          >
            <Text fontWeight={'normal'} fontSize={'sm'}>
              Cancel
            </Text>
          </Button>
          <Button
            bgColor={AppColors.primary}
            borderRadius={'full'}
            px={12}
            isLoading={myAccountContext.isUpdatingUserData}
            outline={`2px solid ${AppColors.primary}`}
            color={AppColors.white}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.white,
              color: AppColors.primary,
            }}
            onClick={handleSubmit(submitAddAccount)}
          >
            <Text fontSize={'sm'}>Update</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default EditProviderAccounts;
