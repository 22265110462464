/**
 *
 * @param {Array} sheetData
 */

import { auditedRuvColumns, defaultColumns, defaultDataProps, firstSetColumnKeyOrder, rvuColumnsOrder } from "../constants/constants";

export function arrangeData(sheetData) {
    let firstSetDatas = {};
  
    let billedIcddatas = {};
    let billedCptDatas = {};
    let billedModifierDatas = {};
  
    let agreeColumns = {};
  
    let auditedIcdData = {};
    let auditedCptData = {};
    let auditedModifierData = {};
  
    let auditedRvuColumns = {};
  
    let lastSetColumns = {};
  
    let defaultprops = {};
  
    const finalData = [];
  
    sheetData.forEach(item => {
      for (const key in item) {
        if (firstSetColumnKeyOrder.includes(key)) {
          Object.assign(firstSetDatas, { [key]: item[key] });
        } else if (rvuColumnsOrder.includes(key)) {
          Object.assign(agreeColumns, { [key]: item[key] });
        } else if (auditedRuvColumns.includes(key)) {
          Object.assign(auditedRvuColumns, { [key]: item[key] });
        } else if (defaultColumns.includes(key)) {
          Object.assign(lastSetColumns, { [key]: item[key] });
        } else if (key.match('srvcs_no')) {
          Object.assign(billedCptDatas, { [key]: item[key] });
        } else if (key.match('billed_icd')) {
          Object.assign(billedIcddatas, { [key]: item[key] });
        } else if (key.match('billed_modifier')) {
          Object.assign(billedModifierDatas, { [key]: item[key] });
        } else if (key.match('audited_icd')) {
          Object.assign(auditedIcdData, { [key]: item[key] });
        } else if (key.match('audited_cpt')) {
          Object.assign(auditedCptData, { [key]: item[key] });
        } else if (key.match('audited_modifier')) {
          Object.assign(auditedModifierData, { [key]: item[key] });
        } else if (defaultDataProps.includes(key)) {
          Object.assign(defaultprops, { [key]: item[key] });
        }
      }
  
      const billedColumns = {
        ...sortData(billedIcddatas),
        ...sortData(billedCptDatas),
        ...sortData(billedModifierDatas),
      };
  
      const auditedColumns = {
        ...sortData(auditedIcdData),
        ...sortData(auditedCptData),
        ...sortData(auditedModifierData),
      };
  
      const col = {
        ...firstSetDatas,
        ...billedColumns,
        ...agreeColumns,
        ...auditedColumns,
        ...auditedRvuColumns,
        ...lastSetColumns,
        ...defaultprops,
      };
  
      Object.assign(firstSetDatas, {});
      Object.assign(agreeColumns, {});
      Object.assign(auditedRvuColumns, {});
      Object.assign(lastSetColumns, {});
  
      Object.assign(billedIcddatas, {});
      Object.assign(billedCptDatas, {});
      Object.assign(billedModifierDatas, {});
  
      Object.assign(auditedIcdData, {});
      Object.assign(auditedCptData, {});
      Object.assign(auditedModifierData, {});
      Object.assign(defaultprops, {});
  
      finalData.push(col);
    });
  
    return finalData;
  }
  
  function sortData(obj) {
    return Object.keys(obj)
      .sort((a, b) => {
        const hasNumberA = /\d+$/.test(a);
        const hasNumberB = /\d+$/.test(b);
  
        if (hasNumberA && hasNumberB) {
          const numberA = parseInt(a.match(/\d+$/)[0]);
          const numberB = parseInt(b.match(/\d+$/)[0]);
          return numberA - numberB;
        } else if (hasNumberA) {
          return 1;
        } else if (hasNumberB) {
          return -1;
        } else {
          return a.localeCompare(b);
        }
      })
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  }