import { InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  Icon,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from '@chakra-ui/react';
import { TextInput, useTabStorage } from '@laxmimanogna/code-quick-components';
import React, { useContext, useState, useEffect } from 'react';
import { CustomTab } from '../../components/CustomTab';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import useCQMediaQuery from '../../hooks/useCQMediaQuery';
import MyChartProvider, {
  MyChartContext,
} from '../../providers/MyChartProvider';
import AllCharts from './components/tables/AllCharts';
import ArchivedCharts from './components/tables/ArchivedCharts';

const MyChartsContainer = props => {
  const myChartsTab = [
    {
      title: 'ALL',
      header: 'Audit Uploads',
      component: AllCharts,
    },
    {
      title: 'ARCHIVED',
      header: 'Archived',
      component: ArchivedCharts,
    },
  ];
  const myChartContext = useContext(MyChartContext);
  const { handleApplyFilter, filterParams, getAllCharts, getArchivedCharts } =
    myChartContext;

  const tabStorage = useTabStorage();
  const [selectedTab, setSelectedTab] = useState(tabStorage.getTab() || 0);
  const [onSearchText, setSearchText] = useState('');

  useEffect(() => {tabStorage.setTab(selectedTab)}, [selectedTab]);

  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      getAllCharts.setParams({ page: 1 });
      getArchivedCharts.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  return (
    <>
      <Box mx={marginHorizontal}>
        <HStack spacing={2} ><Heading my={5} as="h5" fontSize="24px">
          My Charts
        </Heading>
          <Tooltip p={3} bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0" label={
              <Box>
                <>
                  <p>
                    <b>My Charts</b> tab displays only the charts that you have personally uploaded. This helps you manage and review your individual contributions separately.
                  </p>
                </>
              </Box>
            } placement="top-end">
            <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
          </Tooltip></HStack>
        <Box
          shadow={'md'}
          bg="white"
          p={4}
          w="100%"
          style={{ borderRadius: '12px' }}
        >
          <Tabs
            fontFamily={FONT_FAMILY.baiJamurjee}
            onChange={setSelectedTab}
            index={selectedTab}
          >
            <TabList>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack spacing={4} px={4}>
                  {myChartsTab.map((aa, index) => {
                    return (
                      <CustomTab
                        key={`my-charts-tab-${index}`}
                        _selected={{
                          fontWeight: 'bold',
                          color: AppColors.secondary,
                        }}
                        color={'black'}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                      >
                        {aa.title}
                      </CustomTab>
                    );
                  })}
                </HStack>
                <Box>
                  <Box>
                    <TextInput
                      borderRadius={'md'}
                      value={onSearchText}
                      onChange={e => onFilterChange(e)}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                      }}
                      mb="2"
                      rightIconProps={{ zIndex: 0 }}
                      placeholder="Search"
                      rightIcon={
                        <SearchIcon
                          onClick={onSearchAction}
                          color={AppColors.gray}
                        />
                      }
                    />
                  </Box>
                </Box>
              </HStack>
            </TabList>

            <TabPanels>
              {myChartsTab.map((aa, index) => {
                return (
                  <TabPanel p={0} key={`my-charts-panel-${index}`}>
                    <aa.component />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default withProvider(MyChartProvider, MyChartsContainer);
