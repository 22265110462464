import {
    Button,
    HStack,
    Box
} from '@chakra-ui/react';
import AppColors from '../../../../../constants/AppColors';
import { CircleLeftIcon, CircleRightIcon } from '../../../../../constants/IconData';

const NavigationFooter = ({ onPreviousClick, currentPageTitle, pageTitle, isLoading }) => {
    return <Box><HStack justifyContent={'center'} mb={5}>
        <CircleLeftIcon
            style={{
                cursor: 'pointer',
                height: 40, width: 40,
            }}
            onClick={() => onPreviousClick()}
        />
        <Button
            type={'submit'}
            borderColor={AppColors.primary}
            borderWidth={2}
            borderRadius='3xl'
            px={14}
            bg={'transparent'}
            _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.primary,
                color: AppColors.white,
              }}
            isLoading={isLoading || false}
        >
            {pageTitle === "CREATE_ACCOUNT" ? 'Finished, Let\'s Code' : 'Next'}
        </Button>
        <Button
            type={'submit'}
            p={0}
            bg={'transparent'} _focus={{ bg: 'transparent' }} _hover={{ bg: 'transparent' }}>
            <CircleRightIcon
                style={{
                    cursor: 'pointer',
                    height: 40, width: 40,
                }}
            />
        </Button>

    </HStack>
    </Box>
}
export default NavigationFooter