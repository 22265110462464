/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
function equalTo(ref, msg) {
    return this.test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            return value === this.resolve(ref)
        }
    })
};

yup.addMethod(yup.string, 'equalTo', equalTo);
export const REGISTER = yup.object({
    first_name: yup.string().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for first name "),
    last_name: yup.string().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for last name "),
    email: yup.string().email().required('Email is required'),
})
export const PRIMARY_OWNER = email =>
  yup.object({
    primary_owner_email: yup.string().test(
      'primary-email-validation',
      d => `Primary Email should not be same as Email`,
      value => value == null || value !== email
    ),
  });
export const PRACTICE_INFO = yup.object({
    physician_group_name: yup.string().required('Physician group is required'),
    physician_group_id: yup.string(),
    new: yup.bool(),
    specialties: yup.array().of(yup.string()).required().min(1),
    state: yup.string().when('new', {
        is: true,
        then: yup.string().required('Must Select State'),
    }),
    patients_per_month: yup.string().when('new', {
        is: true,
        then: yup.string().required('Must enter patient per month'),
    })

})
export const CREATE_ACCOUNT_SCHEMA = yup.object({
    new_email: yup.string().email().required('Email is required'),
    new_password: yup.string().required('Password is required'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Confirm password must be same as new password')
      .when('password_flag', {
        is: true,
        then: yup.string().required('Must enter confirm password'),
      }),
})