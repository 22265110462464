/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Spinner, Text, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import ROUTES from '../../../constants/Routes';

export const AccessDenied = ({ loading, accessDenied, children }) => {
    const navigate = useNavigate();
    const goToDashboard = () => {
        navigate(ROUTES.DASHBOARD); // Replace with your actual dashboard path
      };

    if (loading) {
        return (
            <VStack height="100vh" justify="center" align="center">
                <Spinner size="xl" />
                <Text>Loading...</Text>
            </VStack>
        );
    }

    if (accessDenied) {
        return (
            <Box 
                height="100vh" 
                display="flex"
                flexDirection="column"
                justifyContent="center" 
                alignItems="center" 
                bg="red.100" 
                color="red.800"
                textAlign="center"
                p={4}
            >
                <Text fontSize="2xl">You do not have permission to access this resource.</Text>
                <Button 
                    colorScheme="blue" 
                    variant="solid" 
                    onClick={goToDashboard}
                >
                    Dashboard
                </Button>
            </Box>
        );
    }
    return <>{children}</>;
};



