import { Box, Text } from '@chakra-ui/react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AppColors from '../../constants/AppColors';
import { DepartmentIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';


const SearchDropDown = ({ promiseOptions, setDataValue, dataValue, options, placeholder }) => {

    const formatOptionLabel = props => {
        return (
            <Box>
                <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm">
                    {props.label}
                    <span
                        style={{ fontSize: 10, color: AppColors.darkGray, marginLeft: 5 }}
                    >
                        {props.address}
                    </span>
                </Text>
            </Box>
        );
    };

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">{props.selectProps.inputValue ? 'We don’t currently offer your specialty' : 'No options'}</span>
            </components.NoOptionsMessage>
        );
    };
    return <Box px={2}> <AsyncSelect
        onBlur={true}
        name="department_name"
        id="department_name"
        formatOptionLabel={formatOptionLabel}
        loadOptions={promiseOptions}
        cacheOptions
        defaultOptions={options ? options : []}
        // onMenuClose={() => setDataValue({})}
        value={dataValue && Object.keys(dataValue).length > 0 ? dataValue : "" }
        onChange={newValue => {
            setDataValue(newValue)
        }}
        styles={{
            noOptionsMessage: (provided, state) => ({
                ...provided,
                backgroundColor: 'white',
            }),
            menu: props => ({
                ...props,
                zIndex: 10,
            }),
        }}
        components={{
            Control: ({ children, ...props }) => {
                return (
                    <components.Control {...props}>
                        <span style={{ marginLeft: 10 }}>
                            <DepartmentIcon style={{ height: 20, width: 20 }} />
                        </span>
                        {children}
                    </components.Control>
                );
            },
            NoOptionsMessage: NoOptionsMessage
        }}
        placeholder={placeholder ? "Search dept. name" : placeholder}
    /></Box>
}
export default SearchDropDown