/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, Text, HStack, Circle } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormTextInput,
  useBoolean,
} from '@laxmimanogna/code-quick-components';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AppColors from '../../../../../constants/AppColors';
import {
  PRIMARY_OWNER_TOOLTIP,
  REGISTER_ERROR_PAGE,
} from '../../../../../constants/constants';
import { HelpIcon, MailIcon } from '../../../../../constants/IconData';
import { AuthContext } from '../../../../../providers/AuthProvider';
import NavigationFooter from '../common_files/NavigationFooter';
import TooltipContent from '../TooltipContent';
import { PRIMARY_OWNER } from './validation';

const PrimaryOwner = ({ onPreviousClick, onNextClick, email }) => {
  const [isHelpOpen, ihoState] = useBoolean(false);
  const {
    onboardingObject,
    setOnboardingObject,
    registerError,
    currentPage,
    registerBody,
  } = useContext(AuthContext);
  const form = useForm({
    resolver: yupResolver(PRIMARY_OWNER(email)),
  });
  const { control, handleSubmit, reset, setError, clearErrors } = form;

  const onSubmitData = data => {
    setOnboardingObject({ ...onboardingObject, ...data });
    if (!data.primary_owner_email) {
      onNextClick(1);
    } else {
      onNextClick();
    }
  };

  useEffect(() => {
    if (Object.keys(onboardingObject).length) {
      reset({ primary_owner_email: onboardingObject.primary_owner_email });
    }
    if (Object.keys(registerError).length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage].map(key => {
        if (
          registerErrorKeys.includes(key) &&
          onboardingObject[key] === registerBody[key]
        ) {
          setError(key, { type: 'custom', message: registerError[key] });
        }
      });
    }
  }, []);

  const clearErrorData = (key, data) => {
    if (registerBody[key] && data !== registerBody[key]) {
      clearErrors(key);
    } else if (data === registerBody[key] && registerError[key]) {
      setError(key, { type: 'custom', message: registerError[key] });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <HStack justifyContent={'center'}>
          <Text fontSize={'large'}>
            Will you be the primary owner of this account?
          </Text>
          <Circle
            bg={'transparent'}
            size={5}
            borderWidth={2}
            borderColor={AppColors.secondary}
            position="relative"
            onMouseEnter={() => {
              ihoState.on();
            }}
            onMouseLeave={() => {
              ihoState.off();
            }}
          >
            <HelpIcon />
            {isHelpOpen ? (
              <TooltipContent data={PRIMARY_OWNER_TOOLTIP} />
            ) : null}
          </Circle>
        </HStack>
        <Text
          fontSize={'large'}
          color={AppColors.primary}
          align={'center'}
          mt={3}
        >
          If not, enter the primary owner’s email below and we will send the
          recipient a link to sign up.
        </Text>
        <HStack justifyContent="center" mt={7}>
          <Box w="xs">
            <FormTextInput
              name="primary_owner_email"
              id="primary_owner_email"
              placeholder="Email"
              style={{ opacity: '1' }}
              leftIcon={<MailIcon />}
              leftIconProps={{ height: '50px' }}
              height="50px"
              size="md"
              borderRadius="lg"
              control={control}
              onChange={e => {
                clearErrorData('primary_owner_email', e.target.value);
              }}
            />
          </Box>
        </HStack>
        <Box mt={14} />
        <NavigationFooter
          onPreviousClick={onPreviousClick}
          currentPageTitle={onboardingObject.userType}
        />
      </form>
    </Box>
  );
};
export default PrimaryOwner;
