import AppColors from '../constants/AppColors';
import {
  DownIcon,
  LeftIcon,
  RightIcon,
  SortIcon,
  UpIcon,
  LeftEnableIcon,
  RightEnableIcon,
} from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';

export const defaultReactTableProp = {
  renderSortUpIcon,
  renderSortIcon,
  renderSortDownIcon,
  getCellProps,
  getHeaderCellProps,
  getRowProps,

  RightIcon: <RightIcon />,
  LeftIcon: <LeftIcon />,
};
export function paginationProps(pagination) {

    const canGoBack = pagination?.currentPage !== 1;
    const canGoNext = pagination?.totalPage !== pagination.currentPage;

  return {
    total:pagination.totalCount,
    pageCount: pagination.totalPage,
    size: pagination.showSize,
    currentPage:  pagination.currentPage,
    LeftIcon: canGoBack ? <LeftEnableIcon /> : <LeftIcon />,
    RightIcon: canGoNext ? <RightEnableIcon /> : <RightIcon />
  };
}

export function renderSortUpIcon() {
  return (
    <UpIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortDownIcon() {
  return (
    <DownIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortIcon() {
  return (
    <SortIcon
      style={{
        width: 15,
        height: 15,
        marginLeft: 1,
      }}
    />
  );
}

export function getHeaderCellProps(header) {
  const startingHeaderId = ['assigned_auditor', 'name'];
  const tableHeader = [
    'client',
    'upload_date',
    'updated_date',
    'chart_id',
    'audited_date',
  ];
  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'black',
    width: tableHeader.includes(header.id) ? '125%' : '100%',
    justifyContent: startingHeaderId.includes(header.id)
      ? 'flex-start'
      : 'center',
  };
}

export function getRowProps(row, options = { ignoreUrgent: false }) {
  const backgroundColor = 'white';

  return {
    style: { backgroundColor },
  };
}

export function getCellProps(cell) {
  let fontWeight = 'normal';

  return {
    style: {
      fontFamily: FONT_FAMILY.ptSans,
      color: AppColors.secondary,
      fontSize: '16px',
      fontWeight: fontWeight,
      textAlign: 'center',
    },
  };
}
