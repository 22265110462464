import { Heading, Image, Text, VStack, Divider, Stack } from '@chakra-ui/react';
import AppColors from '../constants/AppColors';
import { CQLoginImage, LoginCardWatermark } from '../constants/ImageData';
import { FONT_FAMILY } from '../constants/Theme';

function renderCoverImage() {
  return (
    <VStack
      bgImage={LoginCardWatermark}
      display={{ base: 'none', md: 'flex' }}
      py={32}
      px={8}
      flex={1}
      textAlign={'center'}
      spacing={8}
      justifyContent={'center'}
      bgColor={AppColors.secondary}
      zIndex={1}
      borderRadius={'3xl'}
    >
      <Image boxSize={'8rem'} alt={'cq-image-logo'} src={CQLoginImage} />
      <Text
        fontFamily={FONT_FAMILY.baiJamurjee}
        fontSize={'lg'}
        letterSpacing={'1.5px'}
        fontWeight={'bold'}
        textTransform={'uppercase'}
        color={'#fff'}
      >
        RevIntegrity
      </Text>
      <Divider w={'35%'} />

      <Heading color={'white'} fontSize={'xl'}>
        Client Portal
      </Heading>

      <Stack px={10}>
        <Text
          mt={3}
          opacity={0.5}
          fontSize={'18px'}
          fontFamily={FONT_FAMILY.ptSans}
          color={'white'}
        >
          Welcome to our client portal!
          <Text>
            This portal is designed to provide a convenient view of revenue
            opportunity and coding accuracy.
          </Text>
        </Text>
      </Stack>
    </VStack>
  );
}
export { renderCoverImage };
