/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Center,
  Circle,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  ClientIcon,
  LogoutIcon,
  PracticeInfoIcon,
  UserIcon,
  WelcomeTutorialIcon,
  WhiteBellIcon,
} from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import ROUTES from '../../constants/Routes';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import React, { useContext, useEffect } from 'react';
import { FONT_FAMILY } from '../../constants/Theme';
import WelcomeTutorialWrapper from '../welcome_tutorial/WelcomeTutorialWrapper';
import { add, formatDistanceStrict, isBefore } from 'date-fns';
import { replaceRoute, toTitleCase } from '../../utils/common.utils';
import NotificationProvider, {
  NotificationsContext,
} from '../../providers/NotificationProvider';
import { withProvider } from '../../hoc/withProvider';
import { useInterval } from '../../hooks/useInterval';

const RenderTutorial = () => {
  const authContext = useContext(AuthContext);

  const { iotState, isOpenTutorial } = authContext;

  const dateToCompare = add(new Date(authContext.currentUser.created_date), {
    days: 90,
  });
  const showWelcome = isBefore(new Date(), dateToCompare);

  return (
    <>
      {showWelcome ? (
        <MenuItem
          icon={<WelcomeTutorialIcon style={{ width: 15, height: 15 }} />}
          onClick={iotState.on}
          height={'50px'}
          fontSize={'16px'}
        >
          Welcome Tutorial
        </MenuItem>
      ) : null}
      <WelcomeTutorialWrapper isOpen={isOpenTutorial} onClose={iotState.off} />
    </>
  );
};

function CTopbar() {
  const { isOpen, onToggle } = useDisclosure();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const notificationContext = useContext(NotificationsContext);
  const {
    notifications,
    unReadNotification,
    setNotifications,
    setUnReadNotification,
    setNotificationCount,
  } = notificationContext;

  const isInitialNotificationData = React.useRef(null);
  const [notificationData, setNotificationData] = React.useState({});
  const [isHaveNewNotification, setIsHaveNewNotification] = React.useState({});
  const [newNotifications, setNewNotification] = React.useState(notifications);

  useEffect(() => {
    notificationContext.getNotifications(1);
    isInitialNotificationData.current = true;
  }, []);

  useEffect(() => {
    setNewNotification(notifications);
  }, [notifications]);

  const loadNotification = async () => {
    const response = await notificationContext.loadNewNotification();
    const sortedNotifications = response.results?.sort(
      (rn, pn) => Number(rn.read) - Number(pn.read)
    );
    if (isInitialNotificationData.current) {
      setNotificationData({ ...response, results: sortedNotifications });
      isInitialNotificationData.current = null;
    } else {
      setIsHaveNewNotification({ ...response, results: sortedNotifications });
    }
  };
  useEffect(() => {
    const data = notificationData?.results?.filter(
      isNew =>
        !isHaveNewNotification?.results?.some(
          nd => nd.notification_id === isNew.notification_id
        )
    );
    if (data?.length) {
      setNewNotification(isHaveNewNotification?.results);
      setUnReadNotification(isHaveNewNotification?.unread_count);
      // setNotificationCount(isHaveNewNotification.count);
    }
  }, [isHaveNewNotification]);
  useInterval(loadNotification, 5000);

  const handleNotificationStatus = async n => {
    const auditSheetRoute = replaceRoute(ROUTES.SINGLE_AUDIT_SHEET, {
      uploadId: n.reference_chart,
    });
    if (!n.read) await notificationContext.markRead(n.notification_user_id);

    if (n.reference_chart) navigate(auditSheetRoute);
  };

  return (
    <Box w={'100%'}>
      <Flex
        bg={'#fff'}
        color={useColorModeValue(AppColors.secondary, 'white')}
        minH={'80px'}
        borderBottom={1}
        position={'relative'}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} alignItems={'center'}>
         <Box bg={"#fff"} px={5} py={2}>
         <ClientIcon
            onClick={() => {
              navigate(ROUTES.DASHBOARD);
            }}
            style={{ height: "3rem", cursor: 'pointer' }}
          />
         </Box>
          <Box m={2} />
          <Flex display={{ base: 'none', md: 'flex' }} mr={5}>
            <DesktopNav />
          </Flex>
        </Flex>

        <HStack alignItems={'center'} spacing={2}>
          <Menu>
            <MenuButton position={'relative'}>
              <WhiteBellIcon
                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
              />
              {newNotifications?.length !== 0 && unReadNotification ? (
                <Circle
                  position={'absolute'}
                  top={0}
                  right={-2}
                  color={AppColors.white}
                  size="20px"
                  bg={AppColors.danger}
                  fontWeight="bold"
                  fontSize={'xs'}
                >
                  {unReadNotification}
                </Circle>
              ) : (
                ''
              )}
            </MenuButton>
            <MenuList
              position={'relative'}
              shadow={'none'}
              border={'none'}
              bg={'transparent'}
            >
              {newNotifications?.length === 0 ? (
                <MenuItem
                  borderTopRadius={'md'}
                  bg={AppColors.white}
                  _hover={{ bg: AppColors.white }}
                >
                  <Box p={4}>No Notifications</Box>
                </MenuItem>
              ) : (
                newNotifications.slice(0, 3).map((n, i) => {
                  return (
                    <>
                      <Box
                        cursor={'pointer'}
                        bg={!n.read ? AppColors.white : '#EBEBEE'}
                        py={2}
                        _hover={{ bg: AppColors.gray }}
                        borderTopRadius={i === 0 && 'md'}
                        borderBottom={`1px solid ${AppColors.gray}`}
                        onClick={() => {
                          handleNotificationStatus(n);
                        }}
                      >
                        <MenuItem _hover={{ bg: 'none' }}>
                          <Text
                            fontWeight={'semibold'}
                            fontSize={'sm'}
                            color={AppColors.secondary}
                            px={2}
                          >
                            {n.message.startsWith('@') &&
                              toTitleCase(
                                `${n?.sender?.first_name} ${n?.sender?.last_name}`
                              )}
                          </Text>
                          <Text fontSize={'sm'} color={'black'}>
                            {n.message.startsWith('@')
                              ? n.message?.substring(1)
                              : n.message}
                          </Text>
                        </MenuItem>
                        <Text
                          color={AppColors.darkGray}
                          px={'25px'}
                          fontSize={'12px'}
                        >
                          {formatDistanceStrict(
                            new Date(n.created_at),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Text>
                      </Box>
                    </>
                  );
                })
              )}

              <MenuItem
                _hover={{
                  bg: newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray,
                }}
                bg={
                  newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray
                }
                py={3}
                color={AppColors.white}
                borderBottomRadius={'md'}
                display={'flex'}
                justifyContent={'center'}
                onClick={() => {
                  if (newNotifications?.length !== 0)
                    navigate(ROUTES.NOTIFICATIONS);
                }}
              >
                <Center fontSize={'sm'}> View all</Center>
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton px={4} py={2} transition="all 0.2s" borderRadius="md">
              <HStack alignItems={'center'} mr={8}>
                <Text
                  fontFamily={FONT_FAMILY.ptSans}
                  fontWeight={'bold'}
                  color={AppColors.secondary}
                  fontSize={'18px'}
                  letterSpacing={'0.3px'}
                >
                  {authContext?.currentUser?.email ?? 'N/A'}
                </Text>
                <ChevronDownIcon color={AppColors.secondary} size={30} />
              </HStack>
            </MenuButton>
            <MenuList fontFamily={FONT_FAMILY.ptSans} py={0}>
              <MenuItem
                icon={<PracticeInfoIcon style={{ width: 15, height: 15 }} />}
                onClick={() => navigate(ROUTES.PRACTICE_INFO)}
                height={'50px'}
                fontSize={'16px'}
              >
                {' '}
                Practice Info
              </MenuItem>
              <MenuItem
                icon={<UserIcon style={{ width: 15, height: 15 }} />}
                onClick={() => navigate(ROUTES.MY_ACCOUNT)}
                height={'50px'}
                fontSize={'16px'}
              >
                {' '}
                My Account
              </MenuItem>
              <RenderTutorial />
              <MenuItem
                onClick={authContext.onLogout}
                fontSize={'16px'}
                icon={<LogoutIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
              >
                {' '}
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNavItem = ({ navItem }) => {
  const linkColor = useColorModeValue(AppColors.secondary);
  const popoverContentBgColor = useColorModeValue(AppColors.secondary);

  const matchObject = useMatch(navItem.href);
  const isActive = !!matchObject;

  return (
    <Box>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <NavLink to={navItem.href ?? '#'}>
            <Box
              p={3}
              borderRadius={'md'}
              fontSize={'sm'}
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              bgColor={isActive ? AppColors.secondary : null}
              color={isActive ? AppColors.white : linkColor}
              fontWeight={isActive ? 'extrabold' : 'normal'}
              _hover={{
                textDecoration: 'none',
                bgColor: AppColors.secondary,
                color: AppColors.white,
                transition: 'all 0.5s ease',
              }}
              letterSpacing={'0.3px'}
            >
              <Text
                fontFamily={FONT_FAMILY.baiJamurjee}
                letterSpacing={'0.3px'}
                p={1}
                px={2}
                fontSize={'18px'}
                fontWeight={700}
              >
                {navItem.label}
              </Text>
            </Box>
          </NavLink>
        </PopoverTrigger>

        {navItem.children && (
          <PopoverContent
            border={0}
            boxShadow={'xl'}
            bg={popoverContentBgColor}
            p={4}
            rounded={'xl'}
            minW={'sm'}
          >
            <Stack>
              {navItem.children.map(child => (
                <DesktopSubNav key={child.label} {...child} />
              ))}
            </Stack>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};

const DesktopNav = () => {
  const authContext = useContext(AuthContext);
  const NAV_LIST = authContext.isManagerToken ? MANAGER_CLIENT_VIEW_ITEMS : NAV_ITEMS;
  return (
    <HStack spacing={4}>
      {NAV_LIST.map((navItem, index) => {
        return (
          <DesktopNavItem
            key={`nav-bar-${navItem.href}-${index}`}
            style={{ fontWeight: 'bold' }}
            navItem={navItem}
          />
        );
      })}
    </HStack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue(AppColors.secondary) }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const authContext = useContext(AuthContext);
  const NAV_LIST = authContext.isManagerToken ? MANAGER_CLIENT_VIEW_ITEMS : NAV_ITEMS;
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {NAV_LIST.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <NavLink to={href ?? '#'}>
        <Flex
          py={2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}
          >
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </NavLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map(child => (
              <NavLink key={child.label} py={2} to={child.href}>
                {child.label}
              </NavLink>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Upload',
    href: ROUTES.UPLOAD,
  },
  {
    label: 'Dashboard',
    href: ROUTES.DASHBOARD,
  },
  {
    label: 'My Charts',
    href: ROUTES.MY_CHARTS,
  },
  {
    label: 'Help',
    href: ROUTES.HELP,
  },
];

const MANAGER_CLIENT_VIEW_ITEMS = [
  {
    label: 'Dashboard',
    href: ROUTES.DASHBOARD,
  },
  {
    label: 'My Charts',
    href: ROUTES.MY_CHARTS,
  },
]

function MCVTopbar(){
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  return(
    <Box w={'100%'}>
    <Flex
      bg={'#fff'}
      color={useColorModeValue(AppColors.secondary, 'white')}
      minH={'80px'}
      borderBottom={1}
      position={'relative'}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      align={'center'}
    >
      <Flex
        flex={{ base: 1, md: 'auto' }}
        ml={{ base: -2 }}
        display={{ base: 'flex', md: 'none' }}
      >
        <IconButton
          onClick={onToggle}
          icon={
            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
          }
          variant={'ghost'}
          aria-label={'Toggle Navigation'}
        />
      </Flex>
      <Flex flex={{ base: 1 }} alignItems={'center'}>
       <Box bg={"#fff"} px={5} py={2}>
       <ClientIcon
          onClick={() => {
            navigate(ROUTES.DASHBOARD);
          }}
          style={{ height: "3rem", cursor: 'pointer' }}
        />
       </Box>
        <Box m={2} />
        <Flex display={{ base: 'none', md: 'flex' }} mr={5}>
          <DesktopNav />
        </Flex>
      </Flex>

      <HStack alignItems={'center'} spacing={2}>
        <Menu>
          <Box px={4} py={2} transition="all 0.2s" borderRadius="md">
            <HStack alignItems={'center'} mr={8}>
              <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                color={AppColors.secondary}
                fontSize={'18px'}
                letterSpacing={'0.3px'}
              >
                Manager View of {authContext?.currentUser?.email ?? 'N/A'}
              </Text>
            </HStack>
          </Box>
        </Menu>
      </HStack>
    </Flex>

    <Collapse in={isOpen} animateOpacity>
      <MobileNav />
    </Collapse>
  </Box>
  );
}

function CQTopbar(){
  const authContext = useContext(AuthContext);
  if(authContext.isManagerToken){
    return MCVTopbar();
  }else{
    return CTopbar();
  }
}

export default withProvider(NotificationProvider, CQTopbar);
