/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import UserTypeSelection from '../components/UserTypeSelection';
import PhysicianGroupOnboarding from '../components/physician_group_onboarding/PhysicianGroupOnboarding';
import DepartmentHeadOnboarding from '../components/department_head-onboarding/DepartmentHeadOnboarding';
import HealthSystemOnboarding from '../components/health_system_onboarding/OnBoarding';
import { AuthContext } from '../../../../providers/AuthProvider';
import HospitalExecutiveOnboarding from '../components/hospital_executive_onboarding/HospitalExecutiveOnboarding';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '../../../../constants/Routes';
import { getObjectFromUrl } from '../getObjectFromUrl';
import Providertutorialwrapper from '../components/provider/Providertutorialwrapper';

const OnBoardingContainer = props => {
  const navigate = useNavigate()
  const search = useLocation().search
  const [queryParameters] = useSearchParams(search)
  const page = new URLSearchParams(search).get('page');

  const { onboardingObject, setOnboardingObject, currentPage, setCurrentPage } = useContext(AuthContext)

  useEffect(() => {
    const data = getObjectFromUrl(queryParameters)
    if (Object.keys(data).length) {
      if (data.page) {
        setCurrentPage(Number(data.page))
      } else {
        setOnboardingObject(data)
        setCurrentPage(0)
        navigate(`${ROUTES.REGISTER}?user_type=${data.userType}&page=${0}`)
      }
    } else {
      setCurrentPage(null)
    }
  }, [])

  const onSelect = (data) => {
    setOnboardingObject(data)
    if (data.userType) {
      navigate(`${ROUTES.REGISTER}?user_type=${data.userType}&page=${0}`)
    }
  }
  const renderPages = () => {
    switch (onboardingObject.userType) {
      case 'Physician\'s Group':
        return <PhysicianGroupOnboarding />
      case 'Health System C-Level':
        return <HealthSystemOnboarding currentPage={currentPage} setCurrentPage={setCurrentPage} onboardingObject={onboardingObject} />
      case 'Department Head':
        return <DepartmentHeadOnboarding currentPage={currentPage} setCurrentPage={setCurrentPage} onboardingObject={onboardingObject} />
      case 'Hospital Executive':
        return <HospitalExecutiveOnboarding currentPage={currentPage} setCurrentPage={setCurrentPage} onboardingObject={onboardingObject} />
      case 'Provider':
        return <Providertutorialwrapper/>
      default:
        return navigate(ROUTES.REGISTER)
    }
  }

  return (
    <>
      {!page ? <UserTypeSelection onSelect={onSelect} /> : renderPages()}
    </>
  );
};

export default OnBoardingContainer