import {
  Button,
  Center,
  Flex,
  Heading,
  ScaleFade,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../../../constants/AppColors';
import { CircleCheckedIcon } from '../../../../../constants/IconData';
import ROUTES from '../../../../../constants/Routes';
import { FONT_FAMILY } from '../../../../../constants/Theme';

function ProviderResetPassword() {
  const navigate = useNavigate();

  return (
    <>
      <Flex>
        <Flex width={'100vw'} justifyContent={'center'}>
          <Flex
            borderRadius={'xl'}
            p={3}
            height={'622px'}
            width={'966px'}
            m={2}
            flexDirection={'column'}
            alignContent={'space-between'}
            justifyContent={'space-between'}
            bg={'white'}
          >
            <Flex
              height={'100%'}
              flexDirection={'column'}
              alignContent={'space-between'}
              justifyContent={'center '}
            >
              <VStack>
              <ScaleFade initialScale={0.1} in={true}>
                <CircleCheckedIcon
                  style={{
                    width: '80px',
                    height: '80px',
                    marginBottom: '30px',
                  }}
                />
                </ScaleFade>
                <Heading
                  marginBottom={'50px'}
                  fontSize={'18px'}
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  color={AppColors.secondary}
                >
                  Password reset successful!
                </Heading>
                <Center textAlign={'center'} mb={2} fontSize={'18px'}>
                  You can now use your new password to log in to your account
                </Center>

                <Button
                  bg={AppColors.secondary}
                  color={AppColors.white}
                  width={'153px'}
                  _hover={{
                    transition: 'all .1s ease',
                    bgColor: AppColors.secondary,
                    color: AppColors.white,
                  }}
                  onClick={() => {
                    navigate(ROUTES.LOGIN_SCREEN);
                  }}
                  height={'50px'}
                >
                  Login
                </Button>
              </VStack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default ProviderResetPassword;
