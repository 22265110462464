/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { PhysicianContext } from '../../../../providers/PhysicianProvider';
import AIRiskMonitor from '../../components/AIRiskMonitor';
import InfoTooltip from '../../../../components/InfoTooltip';

function PrvuOpporturnity({
  defaultStartDate,
  defaultEndDate,
  filter,
  isTileModalOpen,
}) {
  const { isAIRiskLoading } = useContext(PhysicianContext);
  const physicianContext = useContext(PhysicianContext);
  const renderAIRisk = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {/* header */}
          <HStack justifyContent={'space-between'}>
            <Text
              fontSize={'18px'}
              color={AppColors.primary}
              fontFamily={FONT_FAMILY.ptSans}
            >
              Revenue Cycle Temperature Gauge
            </Text>
            <InfoTooltip infoContent={
              <>
                <p>
                  <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
                </p>
              </>} />
          </HStack>
          <Box mt={5}>
            <AIRiskMonitor
              data={physicianContext.aiRisk}
              tile={isTileModalOpen}
            />
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = message => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>
          {message ? message : 'Something went wrong, Please try again later'}
        </Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isAIRiskLoading) {
      return renderLoader();
    } else if (physicianContext.error['AI_RISK']) {
      return renderFailedPage(physicianContext.error['AI_RISK']);
    } else {
      return renderAIRisk();
    }
  };

  return renderMainContent();
}

export default PrvuOpporturnity;
