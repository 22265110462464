import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Icon,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';
import { InfoOutlineIcon } from '@chakra-ui/icons';

function BatchCQscore({ isTileModalOpen, }) {
  const batchContext = useContext(BatchContext);
  const { cqScore, isCQScoreFetching } = batchContext;

  const renderTileHeader = (title, infoContent, noDate) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {title}
          </Text>
        ) : null}
        {infoContent ? (
          <Tooltip p={3} bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0" label={
              <Box>
                {infoContent}
              </Box>
            } placement="top-end">
            <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
          </Tooltip>
        ) : null}
        {/* {!noDate ? (
          <Text
            fontSize={'xs'}
            color={AppColors.black}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {filterParams.label === 'custom'
              ? null
              : filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
          </Text>
        ) : null} */}
      </HStack>
    );
  };

  const tileFailure = (title, infoContent, message) => {
    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader(title, infoContent)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };
  const chartAccuracyContainer = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {/* <HStack justifyContent={'space-between'} gap={'50px'}>
            <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
              Chart Accuracy
            </Text>
          </HStack> */}
          {renderTileHeader("Chart Accuracy", (
            <>
              <p>
                <b>Chart Accuracy</b> tile displays the chart coding accuracy as both a percentage and a letter grade. Monitoring this metric helps you assess the precision of your coding practices and identify areas for improvement.
              </p>
            </>
          ))}
          {/* content */}

          <VStack>
            <CircularProgress
              value={cqScore?.current_cq_score || 0}
              size="230px"
              thickness="2px"
              color={AppColors.primary}
            >
              <CircularProgressLabel>
                <VStack justifyContent={'center'} position={'relative'}>
                  <HStack>
                    <Text
                      fontFamily={FONT_FAMILY.ptSans}
                      mt={0}
                      fontWeight="md"
                    >
                      {cqScore?.current_cq_score || 0}
                    </Text>
                    <Text fontSize={'15px'} color={AppColors.lightGrey}>
                      %
                    </Text>
                  </HStack>

                  <Box position={'absolute'} top={20}>
                    <Text fontSize={'13px'} color={AppColors.lightGrey}>
                      Overall Accuracy
                    </Text>
                  </Box>
                </VStack>
              </CircularProgressLabel>
            </CircularProgress>
          </VStack>

          {/* footer */}
          <Box mb={2} ml={5}>
            <Text
              fontSize="35px"
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={600}
            >
              {cqScore?.grade || 0}
            </Text>
            <Text
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.lightGrey}
              fontSize={'sm'}
            >
              RI Score
            </Text>
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isCQScoreFetching) {
      return renderLoader();
    } else if (!cqScore) {
      return tileFailure("Chart Accuracy", (
        <>
          <p>
            <b>Chart Accuracy</b> tile displays the chart coding accuracy as both a percentage and a letter grade. Monitoring this metric helps you assess the precision of your coding practices and identify areas for improvement.
          </p>
        </>
      ));
    } else {
      return chartAccuracyContainer();
    }
  };

  return renderMainContent();
}

export default BatchCQscore;
