import { Box, Center, HStack, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { TriangleIcon, TriangleRedIcon } from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';
import { convertToPositiveDollar } from '../utils/common.utils';
import AppColors from '../constants/AppColors';
import { WarningIcon } from '@chakra-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import { CQSelect } from '@laxmimanogna/code-quick-components';
import { priceConverter } from '../utils/priceConverter';
import ROUTES from '../constants/Routes';
import { useNavigate } from 'react-router-dom';

const PotentialRevenueTile = ({ potentialRevenue, dropdown, selectedOption, onDropdownChange, loadingPotentialRevenue, initialLoadComplete, description }) => {
    const commonSelectStyles = {
        control: props => ({
            ...props,
            borderRadius: 7,
            paddingRight: 6,
            paddingLeft: 6,
            fontSize: '12px',
            color: AppColors.secondary,
            borderColor: AppColors.secondary,
            '&:hover': {
                borderColor: AppColors.primary,
            },
        }),
        placeholder: props => ({
            ...props,
            color: AppColors.secondary,
            fontWeight: 'bold'
        }),
        dropdownIndicator: props => ({
            ...props,
            color: AppColors.secondary
        }),
        singleValue: props => ({
            ...props,
            fontWeight: 'bold',
            color: AppColors.secondary,
        }),
        option: props => ({
            ...props,
            fontSize: '12px',
            zIndex: 100
        }),
        menu: props => ({
            ...props,
            zIndex: 100
        }),
    }

    const navigate = useNavigate();
    const handleChartCountClick = () => {
        navigate(`/${ROUTES.PRACTICE_INFO}`);
    };

    const noData = potentialRevenue.message === "No data present for this account";
    const noDataDropdown = dropdown.length === 1 && dropdown[0].label === 'No data present for this account';
    const extendedDropdown = useMemo(() => {
        const defaultOption = { label: 'All RVU Types', value: 'total_potential_revenue' };
        return !noDataDropdown ? [defaultOption, ...dropdown] : [];
    }, [dropdown, noDataDropdown]);
    const [selectedRevenue, setSelectedRevenue] = useState(0);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [displayTotal, setDisplayTotal] = useState(true);

    useEffect(() => {
        if (!initialLoadComplete) {
            setSelectedRevenue(potentialRevenue['total_potential_revenue'] ?? 0);
            setSelectedLabel('All RVU Types');
            setDisplayTotal(true);
        } else {
            const revenue = potentialRevenue[selectedOption] ?? 0;
            const label = extendedDropdown.find(option => option.value === selectedOption)?.label || '';
            setSelectedRevenue(revenue);
            setSelectedLabel(label);
            setDisplayTotal(false);
        }
    }, [selectedOption, potentialRevenue, dropdown, initialLoadComplete, extendedDropdown]);

    const handleChange = (data) => {
        if (data.value !== 'total_potential_revenue') {
            onDropdownChange(data.value);
            setSelectedRevenue(potentialRevenue[data.value] ?? 0)
            setSelectedLabel(data.label)
            setDisplayTotal(false);
        }
        else {
            setSelectedRevenue(potentialRevenue['total_potential_revenue'] ?? 0)
            setSelectedLabel('All RVU Types')
            setDisplayTotal(true);
        }
    };

    return (
        <Box alignItems="left" mt={4}>
            <Box>
                {loadingPotentialRevenue ? (
                    <Box mb={5} mt={3}>
                        <Skeleton mt={5} mb={7} w={'60%'} h={15} borderRadius="md" />
                        <Spinner size={'md'} color={AppColors.primary} />
                    </Box>
                ) : (
                    <>
                        <Text fontSize="sm" fontWeight="bold" color="gray.700">
                            {noData ? (
                                <>
                                    Potential Revenue
                                </>
                            ) : selectedLabel === 'All RVU Types' ? (
                                <>
                                    Total Potential Revenue
                                </>
                            ) : (
                                <>
                                    Potential Revenue for <Text as="span" textDecoration="underline">{selectedLabel}</Text>
                                </>
                            )}
                        </Text>
                        <HStack>
                            {noData ? (
                                <WarningIcon />
                            ) : priceConverter(selectedRevenue).startsWith('-$') ? (
                                <TriangleRedIcon />
                            ) : (
                                <TriangleIcon />
                            )}
                            <Text fontSize="5xl" fontWeight="bold" fontFamily={FONT_FAMILY.ptSans} color="black">
                                {potentialRevenue[selectedOption] !== undefined
                                    ? convertToPositiveDollar(priceConverter(selectedRevenue))
                                    : 'N/A'}
                            </Text>
                        </HStack>
                        <Text fontSize="sm" color="gray.700" mt={2}>
                            {noData ? "" : <>Chart Count: <Text as="span" fontWeight="bold" cursor="pointer" textDecorationLine={'underline'} onClick={handleChartCountClick}>{potentialRevenue.charts_count}</Text></>}
                        </Text>
                        <HStack mt={7} justifyContent='center'>
                            <Box onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {noDataDropdown ? (
                                    <Center>
                                        No data present for this account
                                    </Center>
                                ) : (
                                    <CQSelect
                                        options={extendedDropdown}
                                        placeholder='Select RVU Type'
                                        variant='primary'
                                        isSearchable={false}
                                        styles={commonSelectStyles}
                                        value={displayTotal ? extendedDropdown.find(option => option.value === 'total_potential_revenue') : extendedDropdown.find(option => option.value === selectedOption)}
                                        onChange={handleChange}
                                    />
                                )}
                            </Box>
                        </HStack>
                    </>
                )}
            </Box>
            <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm" mt={3}>
                {description}
            </Text>
        </Box>
    );
};

export default PotentialRevenueTile;
