/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Circle,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  VStack,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CirclePlusIcon } from '../../../../constants/IconData';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { MyAccountContext } from '../../../../providers/MyAccountProvider';
import FormMultiSelectModal from '../../../../components/form/FormMultiSelect';
import SelectFormModal from '../../../../components/select_form/SelectFormModal';
import { separateIdNames } from '../../../../utils/separateIdNames';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SpecialtySelect from '../../../../components/select_form/SpecialtySelect';
import authRepository from '../../../../repositories/AuthRepository';
import SearchDropDown from '../../../../components/select_form/SearchDropDown';
import { onlyAlphabetsRegex } from '../../../../constants/constants';

const AddDepartmentScheme = yup.object({
    account_contact: yup
      .array()
      .of(
        yup.object({
          is_primary: yup.bool().required(),
          first_name: yup
            .string()
            .matches(onlyAlphabetsRegex, 'First Name must be valid')
            .when('is_primary', {
              is: true,
              then: yup.string().required('First Name is required'),
            }),
          last_name: yup
            .string()
            .matches(onlyAlphabetsRegex, 'Last Name must be valid')
            .when('is_primary', {
              is: true,
              then: yup.string().required('Last Name is required'),
            }),
          email: yup.string().when('is_primary', {
            is: true,
            then: yup.string().email('Email is required').required('Email is required'),
          }),
        })
      )
      .min(1),
  });

const AddDepartmentForm = ({
  isOpen,
  onClose,
  hospital,
  accountData,
  setDepartmentList,
  departmentList,
  departmentForHospital,
  hospitalId,
}) => {
  const [radioValue] = useState('0');
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedHospital, setSelectedHospital] = useState(
    departmentForHospital && hospitalId
  );
  const myAccountContext = useContext(MyAccountContext);
  var departmentDropdownList = myAccountContext.departmentDropdown;
  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [specialtyValue, setSpecialtyValue] = useState({});

  const GRID_SPACING = 5;

  const hospitalDropdown = useMemo(() => {
    return hospital?.map(h => {
      return { id: h.id, label: h.name, value: h.name };
    });
  }, []);

  function createNewContact() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      is_primary: false,
    };
  }

  useEffect(() => {
    if (selectedHospital) {
      myAccountContext.getDepartmentByHospital(selectedHospital.id);
    }
  }, [selectedHospital]);

  const form = useForm({
    resolver: yupResolver(AddDepartmentScheme),
    defaultValues: {
      account_contact: [createNewContact()],
    },
  });

  const { control, handleSubmit, setValue, getValues, reset } = form;
  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  const departmentOptions = useMemo(() => {
    return departmentDropdownList?.map(d => {
      return { id: d.id, value: d.name, label: d.name };
    });
  }, [departmentDropdownList]);

  const onModalClose = ()=>{
    onClose();
    reset({});
    setSpecialtyValue({});
  }

  async function submitAddAccount(formData) {
    const departmentBody = {
      insurance: separateIdNames(formData.insurance, 'new_ins_name'),
      ehr: separateIdNames(formData.ehr, 'new_ehr_name'),
      account_contact: formData.account_contact,
      hospital_name: selectedHospital.value,
      hospitalId: selectedHospital.id, 
    };
    if (specialtyValue?.id) {
      departmentBody['department_id'] = specialtyValue?.id;
    }else{
      departmentBody['name'] = specialtyValue?.value;
    }
    
    const response = await myAccountContext.createDepartment(departmentBody);
    if(response){
      // let departmentData = [...departmentList, response];
      // setDepartmentList(departmentData);
      onModalClose()
    }
  }

  useEffect(() => {
    getValues('account_contact')?.map((dt, index) => {
      if (index == radioValue) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });

    if (Object.keys(accountData).length) {
      setValue(
        'ehr',
        accountData?.ehr?.map(e => e.id)
      );
      setValue(
        'insurance',
        accountData?.insurance?.map(i => i.id)
      );
    }
  }, [radioValue]);

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    spacbiState(checkedIndexes);
  };

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <VStack display={'block'} mb={4}>
          <SimpleGrid
            columns={{ sm: 1, md: 4 }}
            spacing={GRID_SPACING}
            alignItems={'baseline'}
          >
            <FormTextInput
              height="50px"
              name={`account_contact.${index}.first_name`}
              control={control}
              placeholder={'First Name'}
              size="md"
            />

            <FormTextInput
              height="50px"
              name={`account_contact.${index}.last_name`}
              control={control}
              placeholder={'Last Name'}
              size="md"
            />

            <FormTextInput
              height="50px"
              name={`account_contact.${index}.email`}
              control={control}
              placeholder={'Email'}
              size="md"
            />

            {index.toString() !== radioValue ? (
              <Text
                onClick={() => contactField.remove(index)}
                cursor={'pointer'}
                color={AppColors.secondary}
                fontSize="sm"
              >
                Remove
              </Text>
            ) : null}
          </SimpleGrid>
          <Box display={'flex'} alignItems={'center'} gap={'2'}>
            <Checkbox
              onChange={e => handleCheckBox(e, index)}
              value={index.toString()}
            >
              Primary Account
            </Checkbox>
          </Box>
        </VStack>
      </React.Fragment>
    );
  }

  function renderAddIcon(_props) {
    return (
      <HStack mr={12} mb={3} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <CirclePlusIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  function renderContacts() {
    return (
      <PerfectScrollbar
        style={{
          maxHeight: '200px',
        }}
      >
        {contactField?.fields?.map(renderAddContactForm)}
        {renderAddIcon({
          onClick: () => {
            contactField.append(createNewContact());
          },
        })}
      </PerfectScrollbar>
    );
  }

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={myAccountContext.isFetchingEHR}
        isCreating={myAccountContext.isCreatingEHR}
        onCreateNewOption={myAccountContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        addNewText={'Other Insurance Name'}
        subTitle={'Select Insurance'}
        isFetching={myAccountContext.isFetchingInsurance}
        isCreating={myAccountContext.isCreatingInsurance}
        onCreateNewOption={myAccountContext.createInsuranceOption}
      />
    );
  }

  const promiseOptions = async inputValue => {
    return authRepository.getSpecialtyOptions({ search: inputValue });
  };
  
  return (
    <React.Fragment>
      <Modal size={'4xl'} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent mx={4} px={8}>
          <ModalHeader
            fontFamily={FONT_FAMILY.baiJamurjee}
            textAlign={'center'}
          >
            Add Department
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-10}
            right={-3}
          >
            <Circle>
              <ModalCloseButton
                size={'sm'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={onClose}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody pb={6}>
            <SimpleGrid
              columns={{ sm: 1, md: 2, lg: 2 }}
              spacing={GRID_SPACING}
              mb={GRID_SPACING}
            >
              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color="black">
                  Hospital
                </FormLabel>
                {departmentForHospital ? (
                  <Input value={selectedHospital.value} disabled />
                ) : (
                  <Select
                    name="name"
                    control={control}
                    options={hospitalDropdown}
                    onChange={e => setSelectedHospital(e)}
                    size="md"
                  />
                )}
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color={'black'}>
                  Department Name
                </FormLabel>
                {/* <SpecialtySelect
                    placeholder={"Select Department"}
                    dataValue={specialtyValue}
                    setDataValue={setSpecialtyValue}
                    promiseOptions={promiseOptions}
                /> */}
                <SearchDropDown
                 placeholder={"Select Department"}
                 dataValue={specialtyValue}
                 setDataValue={setSpecialtyValue}
                 promiseOptions={promiseOptions}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'} color="black">
                  Hospital Insurance
                </FormLabel>
                <FormMultiSelectModal
                  control={control}
                  name={'insurance'}
                  placeholder={'Select Insurance'}
                  options={myAccountContext.insuranceOptions}
                  renderFormModal={renderInsuranceForModal}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                  Hospital EHR
                </FormLabel>
                <FormMultiSelectModal
                  control={control}
                  name={'ehr'}
                  placeholder={'Select EHR'}
                  options={myAccountContext.ehrOptions}
                  renderFormModal={renderEHRFormModal}
                />
              </FormControl>
            </SimpleGrid>

            <FormControl>
              <FormLabel fontSize={'xs'} fontWeight={'bold'} color={'black'}>
                Account Contact
              </FormLabel>
              {renderContacts()}
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            <Button
              mr={3}
              borderRadius={'full'}
              px={12}
              borderWidth={2}
              borderColor={AppColors.secondary}
              onClick={onClose}
              variant={'outline'}
            >
              <Text fontWeight={'normal'} fontSize={'sm'}>
                Cancel
              </Text>
            </Button>
            <Button
              bgColor={AppColors.primary}
              borderRadius={'full'}
              px={12}
              isLoading={myAccountContext.isDepartmentLoading}
              onClick={handleSubmit(submitAddAccount)}
            >
              <Text color={'white'} fontSize={'sm'}>
                Add
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
export default AddDepartmentForm;
