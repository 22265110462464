const getObjectFromUrl = (queryParameters) => {

    const userType = queryParameters.get('user_type')
    let email = queryParameters.get('email')
    if (userType && email) {
        email = email.replace('/', '')
        switch (userType) {
            case 'PHYSICIANS GROUP':
                const id = queryParameters.get('uid')
                return {
                    userType: 'Physician\'s Group',
                    email: email,
                    physician_group_id: id.replace('CQ-', ''),
                    primaryEmailOnboarding: true
                }
            case 'HEALTH SYSTEM':
                const healthSystemId = queryParameters.get('uid')
                return {
                    userType: 'Health System C-Level',
                    email: email,
                    health_system_id: healthSystemId.replace('CQ-', ''),
                    primaryEmailOnboarding: true
                }
            case 'HOSPITAL':
                let ids = queryParameters.get('uid')
                if (ids) {
                    ids = ids.split('-')
                }
                return {
                    userType: 'Hospital Executive',
                    email: email,
                    health_system_id: ids[1],
                    practice_id: ids[2],
                    primaryEmailOnboarding: true
                }
            case 'DEPARTMENT':
                let uid = queryParameters.get('uid')
                if (uid) {
                    uid = uid.split('-')
                }
                return {
                    userType: 'Department Head',
                    email: email,
                    health_system_id: uid[1],
                    practice_id: uid[2],
                    primaryEmailOnboarding: true,
                    department_id: uid[3],

                }
            default: return {}
        }
    } else if (userType) {
        const page = queryParameters.get('page')
        return {
            userType,
            page
        }
    } else {
        return {}
    }
}
export { getObjectFromUrl }