/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  CQReactTable,
  TextInput,
} from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { replaceRoute, toTitleCase } from '../../../utils/common.utils';
import { defaultReactTableProp } from '../../../utils/my_audits.utils';
import AppColors from '../../../constants/AppColors';
import { priceConverter } from '../../../utils/priceConverter';
import { DashboardContext } from '../../../providers/DashboardProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import { getCurrentUserTypeId } from '../../../utils/getCurrentUserTypeId';
import InfoTooltip from '../../../components/InfoTooltip';

const columnHelper = createColumnHelper();

function DepartmentTable() {
  const [ordering, setOrdering] = useState('');
  const {
    hospitalFilter,
    setHospitalFilter,
    getDepartments,
    hospitals,
    isHospitalLoading,
  } = useContext(DashboardContext);
  const [searchText, setSearchText] = useState('');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const obj = getCurrentUserTypeId(authContext.currentUser);
    setHospitalFilter({
      ...hospitalFilter,
      ...obj,
    });
  }, [authContext.currentUser]);

  useEffect(() => {
    if (hospitalFilter.hospital_id) {
      let searching = !!(hospitalFilter.search && hospitalFilter.search.trim());
      getDepartments.loadAPI("dashboard", "department_table", searching);
    }
  }, [hospitalFilter]);

  useEffect(() => {
    getDepartments.setParams({ ordering });
  }, [ordering]);

  const onFilterChange = e => {
    if (e) {
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  const onSearchAction = () => {
    setHospitalFilter(prev => ({
      ...prev,
      search: searchText,
    }));
  };

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => 'Department Name',
        id: 'name',
        cell: info => {
          return <Text>{toTitleCase(info?.renderValue())}</Text>;
        },
      }),
      columnHelper.accessor('active_audits', {
        id: 'active_audits',
        header: () => <span>Active Audits</span>,
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('chart_accuracy', {
        id: 'chart_accuracy',
        header: () => <span>Chart Accuracy</span>,
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'RI Rev. Opp',
        id: 'cq_rev_opp',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return priceConverter(cellValue);
        },
      }),
      columnHelper.accessor('', {
        header: () => 'Action',
        id: 'action',
        cell: info => {
          return (
            <Button
              size={'md'}
              bg={AppColors.secondary}
              color={'white'}
              borderRadius="3xl"
              onClick={() => {
                navigate(
                  replaceRoute(ROUTES.HOSPITAL_DEPARTMENT_DASHBOARD, {
                    departmentId: info.row.original?.id,
                  }),
                  { state: info.row.original.name }
                );
              }}
              px={10}
              fontWeight="normal"
              fontSize={'sm'}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
                outline: `2px solid ${AppColors.secondary}`,
              }}
            >
              View
            </Button>
          );
        },
      }),
    ],
    // eslint-disable-next-line
    [hospitals]
  );

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    return {
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
      fontWeight: 'bold',
      color: AppColors.secondary,
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: AppColors.black,
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  function handlePreviousPage() {
    getDepartments.loadPrevious("dashboard", "department_table");
  }

  function handleNextPage() {
    getDepartments.loadNext();
  }

  function handleSizeChange(size) {
    getDepartments.loadSize("dashboard", "department_table", size);
  }

  function handlePageChangeByNumber(pageNumber) {
    getDepartments.loadPageByNumber("dashboard", "department_table", pageNumber);
  }

  const renderTable = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <CQReactTable
          isLoading={isHospitalLoading}
          data={hospitals}
          columns={columns}
          {...defaultReactTableProp}
          getHeaderCellProps={header => {
            return {
              ...getHeaderCellProps(header),
            };
          }}
          getCellProps={getTableCellProps}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onPageChangeByNumber={handlePageChangeByNumber}
        />
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack m={4} justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isHospitalLoading) {
      return renderLoader();
    } else if (!hospitals) {
      return renderFailedPage();
    } else {
      return renderTable();
    }
  };

  return (
    <Box
      marginTop={3}
      width={'100%'}
      bg={AppColors.white}
      padding={4}
      borderRadius={'lg'}
      shadow={'md'}
    >
      <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
        <HStack spacing={2}>
          <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
            Departments
          </Text>
          <InfoTooltip infoContent={
            <>
              <p>
                <b>Departments</b> section displays each Department in your system. Click on a Department to view further details, providing a comprehensive overview of each facility's performance and metrics.
              </p>
            </>} />
        </HStack>
        <Box>
          <TextInput
            borderRadius={'md'}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onSearchAction();
              }
            }}
            onChange={e => onFilterChange(e)}
            rightIconProps={{ zIndex: 0 }}
            placeholder="Search Departments"
            rightIcon={<SearchIcon color={AppColors.gray} />}
          />
        </Box>
      </HStack>
      <Divider />
      {renderMainContent()}
    </Box>
  );
}

export default DepartmentTable;
