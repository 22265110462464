import {
  Box,
  Grid,
  GridItem,
  VStack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FONT_FAMILY } from '../../../../constants/Theme';
import AppColors from '../../../../constants/AppColors';
import {
  ProviderIcon,
  DepartmentHeadIcon,
  ExecutiveIcon,
  HealthSystemIcon,
  PhysicianIcon,
} from '../../../../constants/IconData';
import { useNavigate } from "react-router-dom"
import Layout from './common_files/Layout';
import ROUTES from '../../../../constants/Routes';

function UserTypeSelection(props) {
  const navigate = useNavigate()
  return (
    <Layout title={'Select User Type'} header={''} >
      <Box margin="5px" p="5px">
        <Grid templateColumns="repeat(3, 1fr)" >
          <GridItem
            w="100%"
            background={AppColors.white}
            borderRadius="md"
            p="5"
          >
            <Box
              cursor={'pointer'}
              borderColor='rgba(0,0,0,0.1)'
              borderWidth={1}
              style={{ outlineColor: AppColors.loginFormBg }}
              borderRadius="md"
              padding={'3px'}
              _hover={{
                boxShadow: ' 0 0 20px 0 rgba(2,59,147,0.25)',
                borderColor: AppColors.secondary,
                borderWidth: 1,
              }}
              onClick={() => {
                props.onSelect({
                  userType: "Health System C-Level",
                });
              }}
            >
              <VStack p="10px" >
                <HealthSystemIcon style={{ padding: '3px' }} />
                <Text
                 style={{textAlign:"center"}}
                  color={AppColors.secondary}
                  fontWeight="bold"
                  fontFamily={FONT_FAMILY.baiJamurjee}
                >
                  Health System C-Level
                </Text>
              </VStack>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            background={AppColors.white}
            borderRadius="md"
            p="5"
          >
            <Box
              cursor={'pointer'}
              borderColor='rgba(0,0,0,0.1)'
              borderWidth={1}
              style={{ outlineColor: AppColors.loginFormBg }}
              borderRadius="md"
              padding={'3px'}
              _hover={{
                boxShadow: ' 0 0 20px 0 rgba(2,59,147,0.25)',
                borderColor: AppColors.secondary,
                borderWidth: 1,
              }}
              onClick={() => {
                props.onSelect({
                  userType: "Hospital Executive",
                });
              }}
            >
              <VStack p="10px">
                <ExecutiveIcon style={{ padding: '3px' }} />
                <Text
                  color={AppColors.secondary}
                  fontWeight="bold"
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  style={{textAlign:"center"}}
                >
                  Hospital Executive
                </Text>
              </VStack>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            background={AppColors.white}
            borderRadius="md"
            p="5"
          >
            <Box
              cursor={'pointer'}
              borderColor='rgba(0,0,0,0.1)'
              borderWidth={1}
              style={{ outlineColor: AppColors.loginFormBg }}
              borderRadius="md"
              padding={'3px'}
              _hover={{
                boxShadow: ' 0 0 20px 0 rgba(2,59,147,0.25)',
                borderColor: AppColors.secondary,
                borderWidth: 1,
              }}
              onClick={() => {
                props.onSelect({
                  userType: "Department Head",
                });
              }}
            >
              <VStack p="10px">
                <DepartmentHeadIcon style={{ padding: '3px' }} />
                <Text
                  color={AppColors.secondary}
                  fontWeight="bold"
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  style={{textAlign:"center"}}
                >
                  Department Head
                </Text>
              </VStack>
            </Box>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(4, 2fr)" gap={1}>
          <GridItem
            w="100%"
            colStart={2}
            colEnd={3}
            background={AppColors.white}
            borderRadius="md"
            pt="5"
            pd="5"
            pr="5"
          >
            <Box
              cursor={'pointer'}
              borderColor='rgba(0,0,0,0.1)'
              borderWidth={1}
              style={{ outlineColor: AppColors.loginFormBg }}
              borderRadius="md"
              padding={'3px'}
              _hover={{
                boxShadow: ' 0 0 20px 0 rgba(2,59,147,0.25)',
                borderColor: AppColors.secondary,
                borderWidth: 1,
              }}
              onClick={() => {
                props.onSelect({
                  userType: 'Physician\'s Group'
                })
              }}
            >
              <VStack p="10px">
                <PhysicianIcon style={{ padding: '3px' }} />
                <Text
                  color={AppColors.secondary}
                  fontWeight="bold"
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  style={{textAlign:"center"}}
                >
                  Physician's Group
                </Text>
              </VStack>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            background={AppColors.white}
            borderRadius="md"
            pt="5"
            pd="5"
            colStart={3}
            colEnd={4}
          >
            <Box
              cursor={'pointer'}
              borderColor='rgba(0,0,0,0.1)'
              borderWidth={1}
              style={{ outlineColor: AppColors.loginFormBg }}
              borderRadius="md"
              padding={'3px'}
              _hover={{
                boxShadow: ' 0 0 20px 0 rgba(2,59,147,0.25)',
                borderColor: AppColors.secondary,
                borderWidth: 1,
              }}
              onClick={() => {
                props.onSelect({
                  userType: 'Provider'
                })
              }}
              // onClick={()=>{navigate(ROUTES.ON_BOARDING_PROVIDER)}}
            >
              <VStack p="10px">
                <ProviderIcon style={{ padding: '3px' }} />
                <Text
                  color={AppColors.secondary}
                  fontWeight="bold"
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  style={{textAlign:"center"}}
                >
                  Provider
                </Text>
              </VStack>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Layout>
  );
}

export default UserTypeSelection;
