import { useContext } from 'react';
import {
  CREATE_ACCOUNT,
  PRACTICE_INFO,
  PRIMARY_OWNER,
  REGISTER,
} from '../../../../../constants/constants';
import Layout from '../common_files/Layout';
import CreateAccount from './CreateAccount';
import PrimaryOwner from './PrimaryOwner';
import PrimaryOwnerSuccessPage from './PrimaryOwnerSuccessPage';
import SignupForm from './SignupForm';
import PracticeInfo from './PracticeInfo';
import { AuthContext } from '../../../../../providers/AuthProvider';
import AccountCreationModal from '../common_files/AccountCreationModal';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import AppColors from '../../../../../constants/AppColors';
import ROUTES from '../../../../../constants/Routes';
import { useNavigate, useLocation } from 'react-router-dom';

const DepartmentHeadOnboarding = () => {
  const navigate = useNavigate();
  const search = useLocation().search

  const page = new URLSearchParams(search).get('page');
  const {
    onboardingObject,
    isCreateModalOpen,
    closeCreateModal,
    currentPage,
    setCurrentPage,
    onRegisterSuccess,
  } = useContext(AuthContext);

  const onPreviousClick = (skip) => {
    if (skip && typeof skip === 'number') {
      setCurrentPage(Number(currentPage - 1 - skip))
      navigate(`${ROUTES.REGISTER}?user_type=${onboardingObject.userType}&page=${currentPage - 1 - skip}`)
    } else {
      if (!currentPage) {
        setCurrentPage(null)
        navigate(`${ROUTES.REGISTER}`)
      } else {
        setCurrentPage(Number(currentPage - 1))
        navigate(`${ROUTES.REGISTER}?user_type=${onboardingObject.userType}&page=${currentPage - 1}`)
      }
    }
  }

  const onNextClick = (skip) => {
    if (skip && typeof skip === 'number') {
      setCurrentPage(currentPage + 1 + skip)
      navigate(`${ROUTES.REGISTER}?user_type=${onboardingObject.userType}&page=${currentPage + 1 + skip}`)
    } else {
      setCurrentPage(currentPage + 1)
      navigate(`${ROUTES.REGISTER}?user_type=${onboardingObject.userType}&page=${currentPage + 1}`)
    }
  }

  const PAGE_INFO = onboardingObject.primaryEmailOnboarding ? [
    {
      title: REGISTER,
      component: (
        <SignupForm
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
    {
      title: PRACTICE_INFO,
      component: (
        <PracticeInfo
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
    {
      title: CREATE_ACCOUNT,
      component: (
        <CreateAccount
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
  ] : [
    {
      title: REGISTER,
      component: (
        <SignupForm
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
    {
      title: PRIMARY_OWNER,
      component: (
        <PrimaryOwner
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          email={onboardingObject?.email}
        />
      ),
    },
    {
      title: PRIMARY_OWNER,
      component: (
        <PrimaryOwnerSuccessPage
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
    {
      title: PRACTICE_INFO,
      component: (
        <PracticeInfo
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
    {
      title: CREATE_ACCOUNT,
      component: (
        <CreateAccount
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      ),
    },
  ];

  return (
    <>
      <Layout
        title={PAGE_INFO[page]?.title}
        header={onboardingObject.userType}
        currentPage={page}
      >
        {PAGE_INFO[page]?.component}
      </Layout>
      <Modal
        onClose={() => {
          closeCreateModal()
          onRegisterSuccess();
        }}
        onOverlayClick={() => {
          closeCreateModal()
          onRegisterSuccess();
        }}
        isOpen={isCreateModalOpen}
        size={'sm'}
        isCentered
      >
        <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
        <ModalContent
          margin={2}
          borderRadius={'18px'}
          p={15}
          height="400px"
          maxW="700px"
        >
          <ModalBody
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <AccountCreationModal />
          </ModalBody>
          <ModalFooter position={'relative'} justifyContent={'center'}>
            <Button
              width={'190px'}
              height={'50px'}
              borderRadius="12px"
              paddingInline={'20px'}
              marginLeft={'20px'}
              marginRight={'20px'}
              bg={AppColors.secondary}
              color={AppColors.white}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
                outline: `2px solid ${AppColors.secondary}`,
              }}
              onClick={() => {
                onRegisterSuccess();
              }}
              fontWeight={'100px'}
            >
              Got It!{' '}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default DepartmentHeadOnboarding;
