export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CLIENT_LOGIN = BASE_URL + '/login';
export const USER_LOOKUP = BASE_URL + '/users/me';
export const UPLOAD_CHART = BASE_URL + '/client/charts/files/';
export const SUBMIT_UPLOADED_CHART = BASE_URL + '/client/charts/';
export const DELETE_CHART = BASE_URL + '/client/charts/files/:fileId';
export const FORGOT_PASSWORD = BASE_URL + '/forgot-password';
export const LOGOUT = BASE_URL + '/logout';
export const MY_CHARTS = BASE_URL + '/client/dashboard/';

//AUDIT SHEET
export const CLIENT_AUDIT_SHEET =
  BASE_URL + '/client/dashboard/:batchId/auditsheet/';

export const AUDIT_SHEET = BASE_URL + '/auditsheet/:uploadId/';
export const MONITOR_HOUR = BASE_URL + '/auditsheet/audithours/:uploadId/';
export const AUDIT_UPLOAD = BASE_URL + '/manager/charts/:uploadId/';
export const AUDIT_SHEET_INDUSTRY = BASE_URL + '/auditsheet/industry/';
export const UPDATE_ACTIVITY =
  BASE_URL + '/auditsheet/comment/updation_activity/';
export const AUDITSHEET_USER = BASE_URL + '/auditsheet/cqusers/';
export const DELETE_TEAMS_USER = BASE_URL + '/users/:userId/';
export const GET_COMMENT = BASE_URL + '/auditsheet/comment/:uploadId';
export const GET_CLIENT_COMMENT =
  BASE_URL + '/auditsheet/comment/:batchId/client_comment';

export const GET_ALL_USER_API = BASE_URL + '/users';
export const GET_COMMENT_FLAG =
  BASE_URL + '/auditsheet/comment/:uploadId/cell_flag/';
export const GET_CLIENT_COMMENT_FLAG =
  BASE_URL + '/auditsheet/comment/:batchId/client_cell_flag/';
export const POST_COMMENT = BASE_URL + '/auditsheet/comment/';
export const MANAGE_COMMENT = BASE_URL + '/auditsheet/comment/:commentId/';
export const AUDIT_SHEET_PROVIDER_DROPDOWN =
  BASE_URL + '/client/dashboard/:chartId/provider_dropdown/';

//REGISTER
export const REGISTER = BASE_URL + '/client/registration/';
export const GET_SPECIALTIES = BASE_URL + '/client/registration/specialty/';
export const GET_PHYSICIAN_SPECIALTIES = BASE_URL + '/manager/specialties/';
export const GET_PHYSICIAN_GROUP =
  BASE_URL + '/client/registration/physicians_group/';

export const GET_HEALTH_SYSTEM =
  BASE_URL + '/client/registration/health_system_dropdown/';
export const GET_HOSPITAL =
  BASE_URL + '/client/registration/hospital_dropdown/';

export const GET_HEALTH_SYSTEM_HOSPITAL =
  BASE_URL + '/client/registration/:healthSystemId/health_system_hospital/';
export const GET_HEALTH_SYSTEM_DEPARTMENT =
  BASE_URL + '/client/registration/:healthSystemId/health_system_department/';
export const GET_ALL_DEPARTMENT = BASE_URL + '/client/registration/department/';
export const GET_HEALTH_SYSTEM_BY_ID =
  BASE_URL + '/client/healthsystem/:healthSystemId/';
export const GET_HOSPITAL_BY_ID = BASE_URL + '/client/hospitals/:hospitalId/';
export const HOSPITAL_DEPARTMENT_LIST = BASE_URL + '/manager/specialties/';

//DASHBOARD
export const PROVIDERS = BASE_URL + '/client/dashboard/providers_ranking';
export const HOSPITAL = BASE_URL + '/client/dashboard/hospitals';
export const CQ_REVENUE_OPP =
  BASE_URL + '/client/dashboard/cq_outstanding_rev_opp/';
export const CHART_ACTIVITY =
  BASE_URL + '/client/dashboard/upload_chart_activity/';

export const CHART_ACTIVITY_RVU = BASE_URL + '/client/dashboard/chart_activity_breakdown_rvu/';
export const CHART_ACTIVITY_UPCODED = BASE_URL + '/client/dashboard/chart_activity_breakdown_upcoded/';
export const CHART_ACTIVITY_DOWNCODED = BASE_URL + '/client/dashboard/chart_activity_breakdown_downcoded/';
export const CHART_ACTIVITY_MODIFIER = BASE_URL + '/client/dashboard/chart_activity_breakdown_modifier/';

export const TOTAL_UPLOADS = BASE_URL + '/client/dashboard/total_uploads/';
export const CQ_SCORE = BASE_URL + '/client/dashboard/cq_score/';
export const RVU_OPP = BASE_URL + '/client/dashboard/rvu_opportunity_v2/';
export const USER_TYPE_LOOKUP = BASE_URL + '/client/my-account';
export const EDIT_USER_DATA = BASE_URL + '/client/my-account';
export const CQ_ESTIMATED_REV =
  BASE_URL + '/client/dashboard/cq_estimated_revenue';
export const PRACTICE_INFO_HEALTH_SYSTEM = BASE_URL + '/client/praticeinfo/';
export const PRACTICE_INFO_HOSPITAL = BASE_URL + '/client/praticeinfo/';
export const PRACTICE_INFO_DEPARTMENT = BASE_URL + '/client/praticeinfo/';
export const PRACTICE_INFO_PROVIDER = BASE_URL + '/client/praticeinfo/';
export const EHR_LIST = BASE_URL + '/manager/ehr/';
export const INSURANCE_LIST = BASE_URL + '/manager/insurance/';
export const SPECIALTIES_LIST = BASE_URL + '/specialties';
export const CREATE_HOSPITAL =
  BASE_URL + '/manager/accounts/hospital/:health_system/';
export const MOODLE_ACTION = BASE_URL + '/client/moodle-users/';
export const GET_PROVIDER = BASE_URL + '/manager/accounts/provider/:providerId/';
export const GET_DEPARTMENT_DROPDOWN =
  BASE_URL + '/manager/accounts/department/:hospitalId/department_dropdown/';
export const CREATE_DEPARTMENT =
  BASE_URL + '/manager/accounts/department/:hospitalId/';
export const GET_DEPARTMENT_BY_KEYWORD =
  BASE_URL + '/manager/accounts/department/';
export const UPDATE_HEALTH_SYSTEM_PRACTICE_INFO =
  BASE_URL + '/client/praticeinfo/';
export const UPDATE_HOSPITAL_PRACTICE_INFO = BASE_URL + '/client/praticeinfo/';
export const UPDATE_DEPARTMENT_PRACTICE_INFO =
  BASE_URL + '/client/praticeinfo/';
export const MANAGE_DEPARTMENT = BASE_URL + '/manager/accounts/department/:id';
export const GET_DEPARTMENT_BY_ID = BASE_URL + '/client/department/:id';
export const DELETE_HOSPITAL = BASE_URL + '/manager/accounts/hospital/:id';
export const GET_DEPARTMENT_DROPDOWN_BY_HOSPITAL =
  BASE_URL + '/manager/specialties/';
export const GET_AI_RISK = BASE_URL + '/client/dashboard/ai_risk_monitor';
// department users

export const GET_DEPARTMENT_USERS =
  BASE_URL + '/client/registration/department_user';

export const PROVIDER_SEND_MAIL = BASE_URL + '/client/registration/provider/';

// hospital dashboard
export const DASHBOARD_RVU_OPPORTURINTY =
  BASE_URL + '/client/dashboard/cq_outstanding_rev_opp/';

export const GET_HOSPITAL_DASHBOARD =
  BASE_URL + '/client/hospitals/:hospitalId/';

export const GET_DASHBOARD_CQ_SCORE = BASE_URL + '/client/dashboard/cq_score/';

export const DASHBOARD_RVU_OPP_BREAKDOWN =
  BASE_URL + '/client/dashboard/rvu_opp_breakdown/';

export const CHART_ACCURACY_BREAKDOWN =
  BASE_URL + '/client/dashboard/chart_accuracy_breakdown/';

export const POTENTIAL_REVENUE =
  BASE_URL + '/client/dashboard/potential_revenue/';

export const UPDATE_ACCURACY_REVIEW_STATUS =
  BASE_URL + '/client/dashboard/update_accuracy_review_status/';

export const HOSPITAL_PROVIDER =
  BASE_URL + '/client/dashboard/providers_ranking/';

export const DEPARTMENT_PROVIDER =
  BASE_URL + '/client/dashboard/providers_ranking/';

export const HOSPITAL_DEPARTMENTS =
  BASE_URL + '/client/dashboard/department-list/';
export const GET_FILE_BY_BATCH =
  BASE_URL + '/client/dashboard/:batchId/batchresult';

export const UPLOAD_CHART_ACTIVITY =
  BASE_URL + '/client/dashboard/upload_chart_activity/';
export const PHYSICIAN_LIST = BASE_URL + '/client/dashboard/physician-list/';

export const PHYSICIAN_STATISTICS =
  BASE_URL + '/client/dashboard/provider_statistics/';

export const PHYSICIAN_CQ_SCORE = BASE_URL + '/client/dashboard/cq_score/';
export const GET_FAQ = BASE_URL + '/faq';

export const GET_AUDITSHEET_COMMENTS =
  BASE_URL + '/auditsheet/comment/:batchId/client_rebuttal_comment/';

export const ADD_COMMENT = BASE_URL + '/auditsheet/comment/';

export const GET_DEPARTMENT_NAME =
  BASE_URL + '/client/dashboard/:departmentId/department_name/';

export const GET_NOTIFICATIONS = BASE_URL + '/notifications/';

export const CHANGE_NOTIFICATION_STATUS =
  BASE_URL + '/notifications/:notificationId/';
export const RVU_OPP_DROPDOWN =
  BASE_URL + '/client/dashboard/rvu_opportunity_types/';

export const AUDIT_RESULT = BASE_URL + '/client/dashboard/audit_results/';

// Complaince Plan Chart upload
export const COMPLAINCE_PLAN_UPLOAD_CHART = BASE_URL + '/client/compliance/upload/';
export const SUBMIT_COMPLAINCE_PLAN_UPLOADED_CHART = BASE_URL + '/client/compliance/upload/submit/';
export const DELETE_COMPLAINCE_PLAN_CHART = BASE_URL + '/client/compliance/upload/:fileId';
export const GET_COMPLAINCE_PLAN_CHART = BASE_URL + '/client/compliance/upload/';
export const GET_COMPLAINCE_PLAN_CHART_BY_ID = BASE_URL + '/client/compliance/upload/:fileId';
