/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
  Skeleton,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useToastr } from '@laxmimanogna/code-quick-components';
import React, { useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import faqHelpRepository from '../../repositories/FaqRepository';
import { EmailIcon } from '@chakra-ui/icons';
import HelpSupportImage from '../../assets/images/HelpPageImage.svg';

const HelpContainer = props => {
  const toast = useToastr();
  const [isFetching, ifState] = useBoolean(false);
  const [faqQuestions, setFaqQuestions] = useState([]);

  const getFaqQuestions = async () => {
    try {
      ifState.on();
      const response = await faqHelpRepository.getFaqQuestions();
      setFaqQuestions(response);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    getFaqQuestions();
  }, []);

  const renderAccordian = () => {
    return (
      <Accordion borderColor={'transparent'} allowToggle>
        {faqQuestions
          .sort((q, p) => q.order - p.order)
          .filter(f => f.is_active)
          .map((faq, i) => {
            return (
              <AccordionItem key={i} mb={5}>
                <h2>
                  <AccordionButton
                    color={AppColors.secondary}
                    _hover={{ bg: AppColors.white }}
                    bg={AppColors.white}
                    borderRadius={'md'}
                    _expanded={{
                      bg: AppColors.secondary,
                      color: AppColors.white,
                    }}
                  >
                    <Box flex="1" textAlign="left">
                      <Text
                        fontSize={'xl'}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                        p={3}
                      >
                        {faq.question}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize={'lg'}
                  p={5}
                >
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    );
  };

  const renderLoader = () => {
    return (
      <Stack>
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else {
      return renderAccordian();
    }
  };

  return (
    <>
      <CQContainer
        mt={50}
        py={10}
        px={200}
        borderRadius="lg"
        boxShadow="xl"
        bgImage={'linear-gradient(to bottom right, #05bdfb, #2acee2, #40d9d5);'}
      >
        <CQContainer bg="white" py={5} px={8} borderRadius="md" boxShadow="md">
          <Stack spacing={4} align="center">
            <Text fontSize="4xl" fontWeight="bold">
              We're here to help!
            </Text>
            <Image src={HelpSupportImage} alt="Help and support" width={200} height={200} />
            <Text fontSize="xl">
              For support with your RevIntegrity account, feel free to reach out to us.
            </Text>
            <Stack direction="row" spacing={4} align="center">
              <Text fontSize="xl" fontWeight="bold">
                Email us at:
              </Text>
              <Stack direction="row" spacing={2} align="center">
                <EmailIcon color={AppColors.primary} boxSize={6} />
                <Text fontSize="xl" color={AppColors.secondary} _hover={{ textDecoration: 'underline' }}>
                  <a href="mailto:support@codequick.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                    support@codequick.com
                  </a>
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </CQContainer>
      </CQContainer>

      <CQContainer py={10} px={200}>
        {faqQuestions.length > 0 && (
          <Stack mb={5}>
            <Text
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontSize={'2xl'}
              fontWeight={600}
            >
              FAQ's
            </Text>
          </Stack>
        )}
        {renderMainContent()}
      </CQContainer>
    </>
  );
};

export default HelpContainer;
