/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { add } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import ROUTES from '../../../constants/Routes';
import { withProvider } from '../../../hoc/withProvider';
import HospitalProvider, {
  HospitalDashboardContext,
} from '../../../providers/HospitalProvider';
import HAuditChartAccuracy from './sections/HAuditChartAccuracy';
import HChartAccuracy from './sections/HChartAccuracy';
import HCqAiRevenueOpporturinty from './sections/HCqAiRevenueOpporturinty';
import HospitalDepartments from './sections/HospitalDepartments';
import HOutstandingRevenue from './sections/HOutstandingRevenue';
import HProvider from './sections/HProvider';
import DashBoardHeader from '../../../components/dashboard_components/DashBoardHeader';
import { dateFormat } from '../../../utils/common.utils';
import { CUSTOM } from '../../../constants/constants';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import { FONT_FAMILY } from '../../../constants/Theme';
import AppColors from '../../../constants/AppColors';
import AIRiskMonitor from '../components/AIRiskMonitor';
import TilePopup from '../components/TilePopup';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { AccessDenied } from '../components/AccessDenied';

const HOSPITAL_CQ_AI_RISK_OPPORTURINITY = 'HOSPITAL_CQ_AI_RISK_OPPORTURINITY';

function HospitalDashboardContainer() {
  const { hospitalId } = useParams();
  const hospitalDashboardContext = useContext(HospitalDashboardContext);
  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = useState(null);
  const oldFilter = JSON.parse(localStorage.getItem('selectedFilter'));
  const [filter, setFilter] = React.useState(oldFilter ? oldFilter : 'month');
  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );
  const yesterday = add(new Date(), {
    days: -1,
  });

  const [fromDate, setFromDate] = React.useState({
    start_date: add(yesterday, {
      days: -29,
    }),
    end_date: yesterday,
    previous_start_date: add(yesterday, {
      days: -59,
    }),
    previous_end_date: add(yesterday, {
      days: -30,
    }),
  });

  React.useEffect(() => {
    if(!hospitalDashboardContext.isAccessDenied){
      fetchRUVOpportunity();
    }
  }, [fromDate, filter, hospitalDashboardContext.isAccessDenied]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    hospitalDashboardContext.getHospital(hospitalId)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!hospitalDashboardContext.isAccessDenied && oldFilter !== filter) {
      localStorage.setItem('selectedFilter', JSON.stringify(filter));
      if (filter === 'custom') {
        const { start_date, end_date, previous_start_date, previous_end_date } =
          fromDate;
        localStorage.setItem(
          'customDateFilter',
          JSON.stringify({
            start_date,
            end_date,
            previous_start_date,
            previous_end_date,
          })
        );
      } else {
        localStorage.removeItem('customDateFilter');
      }
    }
  }, [filter, hospitalDashboardContext.isAccessDenied]);

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchRUVOpportunity = async () => {
    const filterParams = {};
    filterParams.filter = filter.toLowerCase();

    if (filter === CUSTOM.toLowerCase()) {
      setCustomDates(filterParams);

      // setFilters(
      //   filterParams,
      //   dateFormat(fromDate.previous_start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.previous_end_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.end_date, 'yyyy-MM-dd')
      // );
      delete filterParams.filter;
    }
    filterParams.hospital_id = hospitalId;
    hospitalDashboardContext.getAIRisk(filterParams);
  };

  const tileLoader = () => {
    return (
      <Stack p={5}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderTileHeader = (title, infoContent) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text
            fontSize={'sm'}
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {title}
          </Text>
        ) : null}
        {infoContent ? (
          <Tooltip p={3} bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0" label={
              <Box>
                {infoContent}
              </Box>
            } placement="top-end">
            <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
          </Tooltip>
        ) : null}
      </HStack>
    );
  };
  const tileFailure = (title, infoContent, message) => {
    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader(title, infoContent)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };
  const renderCQAIRiskMonitor = () => {
    if (hospitalDashboardContext.isAIRiskLoading) {
      return tileLoader();
    }
    if (hospitalDashboardContext.error['AI_RISK']) {
      return tileFailure(
        'Revenue Cycle Temperature Gauge',
        (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ),
        hospitalDashboardContext.error['AI_RISK']
      );
    }

    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('Revenue Cycle Temperature Gauge', (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ))}
        <Box mt={4}>
          <AIRiskMonitor
            data={hospitalDashboardContext.aiRisk}
            tile={isTileModalOpen}
          />
        </Box>
      </Box>
    );
  };

  const renderHeaderComponent = () => {
    return (
      <DashBoardHeader
        isFetchingHospital={hospitalDashboardContext.isFetchingHospital}
        titleName={hospitalDashboardContext.hospital?.name}
        setFromDate={setFromDate}
        fromDate={fromDate}
        navigationRoute={ROUTES.DASHBOARD}
        setFilter={setFilter}
        filter={filter}
        objectWithDate={hospitalDashboardContext.cqScore}
      />
    );
  };

  const renderDepartmentTable = () => {
    return (
      <HospitalDepartments
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
      />
    );
  };

  const renderAuditChartAccuracy = () => {
    return (
      <HAuditChartAccuracy
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
      />
    );
  };

  const renderFirstRowComponent = () => {
    return (
      <Box>
        <Grid
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          mt={4}
          gap={4}
        >
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <HChartAccuracy
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
          <GridItem
            cursor="pointer"
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
            onClick={e => {
              if (e.target.name !== 'aiData') {
                setCurrentTile(HOSPITAL_CQ_AI_RISK_OPPORTURINITY);
                itmState.on();
              }
            }}
          >
            {renderCQAIRiskMonitor()}
          </GridItem>
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <HCqAiRevenueOpporturinty
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const renderSecondRowComponent = () => {
    return (
      <Box>
        <Grid
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
          }}
          mt={4}
          gap={4}
        >
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <HOutstandingRevenue
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <HProvider
              defaultStartDate={fromDate.end_date}
              defaultEndDate={fromDate.start_date}
              startDate={fromDate.previous_start_date}
              endDate={fromDate.previous_end_date}
              filter={filter}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const TILE_MODAL_BODY = {
    HOSPITAL_CQ_AI_RISK_OPPORTURINITY: renderCQAIRiskMonitor,
  };

  const renderGraphModal = () => {
    return (
      <TilePopup
        renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
        isOpen={isTileModalOpen}
        onClose={() => {
          itmState.off();
        }}
      />
    );
  };
  return (
    <CQContainer>
      <AccessDenied loading={hospitalDashboardContext.isFetchingHospital} 
        accessDenied={hospitalDashboardContext.isAccessDenied}
      >
        {renderHeaderComponent()}
        {renderFirstRowComponent()}
        {renderSecondRowComponent()}
        {renderAuditChartAccuracy()}
        {renderDepartmentTable()}
        {renderGraphModal()}
      </AccessDenied>
    </CQContainer>
  );
}

export default withProvider(HospitalProvider, HospitalDashboardContainer);
