/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, HStack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AppColors from '../../../../../constants/AppColors';
import {
  FilledPlusIcon,
  LocationIcon,
  PatientPerMonthIcon,
  PhysicianGroupShortIcon,
} from '../../../../../constants/IconData';
import NavigationFooter from '../common_files/NavigationFooter';
import { PRACTICE_INFO } from './validation';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import { FONT_FAMILY } from '../../../../../constants/Theme';
import SelectFormModal from '../../../../../components/select_form/SelectFormModal';
import FormMultiSelect from '../../../../../components/form/FormMultiSelect';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { REGISTER_ERROR_PAGE, STATES } from '../../../../../constants/constants';
import SpecialtySelect from '../../../../../components/select_form/SpecialtySelect';
import authRepository from '../../../../../repositories/AuthRepository';
import StateDropDown from '../../../../dashboard/practice_info/forms/StateDropDown';

const PhysicianGroupInfo = ({ onPreviousClick, onNextClick }) => {
  const authContext = useContext(AuthContext);
  const {
    onboardingObject,
    setOnboardingObject,
    physicianDropdown,
    setPhysicianDropdown,
    specialtiesDropdown,
    setSpecialtiesDropdown,
    registerError,
    currentPage,
    registerBody,
  } = useContext(AuthContext);
  const [newPhysicianGroup, setNewPhysicianGroup] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const [specialtyValue, setSpecialtyValue] = useState({});

  useEffect(() => {
    if (Object.keys(dataValue).length && !dataValue.back) {
      if (dataValue.value !== dataValue.label) {
        authContext.getPhysicianSpecialties(dataValue.value);
      } else {
        authContext.getSpecialties(dataValue.value);
      }
    }
  }, [dataValue]);

  useEffect(() => {
    if (specialtyValue && Object.keys(specialtyValue).length) {
      if (
        specialtiesDropdown.findIndex(
          d => d.id === specialtyValue.id && d.label === specialtyValue.label
        ) === -1
      ) {
        setSpecialtiesDropdown(prev => [...prev, specialtyValue]);
      }
    }
  }, [specialtyValue]);

  const form = useForm({
    resolver: yupResolver(PRACTICE_INFO),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState:{ errors }
  } = form;

  useEffect(() => {
    authContext.getPhysicianGroup();
    if (Object.keys(onboardingObject).length) {
      if (onboardingObject.new) {
        setNewPhysicianGroup(true);
        setDataValue({
          label: onboardingObject.physician_group_name,
          value: onboardingObject.physician_group_name,
          back: true,
        });
        reset({
          physician_group_name: onboardingObject.physician_group_name,
          state: onboardingObject.state,
          specialties: onboardingObject.specialties,
          patients_per_month: onboardingObject.patients_per_month,
          new: onboardingObject.new,
        });
      } else {
        if (onboardingObject.physician_group_name) {
          setNewPhysicianGroup(false);
          setDataValue({
            label: onboardingObject.physician_group_name,
            value: onboardingObject.physician_group_id,
            back: true,
          });
          reset({
            physician_group_name: onboardingObject.physician_group_name,
            physician_group_id: onboardingObject.physician_group_id,
            specialties: onboardingObject.specialties,
            new: onboardingObject.new,
          });
        }
      }
    }
    if (Object.keys(registerError).length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage].map(key => {
        if (registerErrorKeys.includes(key)) {
          if (key === 'health_system') {
            setError('physician_group_name', {
              type: 'custom',
              message: registerError[key],
            });
          } else if (key === 'specialty') {
            setError('specialties', {
              type: 'custom',
              message: registerError[key],
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (physicianDropdown.length && onboardingObject.primaryEmailOnboarding) {
      const data = physicianDropdown.find(
        d => d.value == onboardingObject.physician_group_id
      );

      setDataValue({
        label: data.label,
        value: onboardingObject.physician_group_id,
      });
      reset({
        physician_group_name: data.label,
        physician_group_id: onboardingObject.physician_group_id,
        new: false,
      });
      setOnboardingObject(prev => ({
        ...prev,
        physician_group_name: data.label,
      }));
    }
  }, [physicianDropdown]);

  const clearErrorData = (key, data) => {
    if (registerBody[key] && data !== registerBody[key]) {
      clearErrors(key);
    } else if (data === registerBody[key] && registerError[key]) {
      setError(key, { type: 'custom', message: registerError[key] });
    }
  };

  const onSubmitData = data => {
    setOnboardingObject({ ...onboardingObject, ...data });
    onNextClick();
  };

  const handleCreate = inputValue => {
    setNewPhysicianGroup(true);
    setPhysicianDropdown(prev => [
      ...prev,
      { label: inputValue, value: inputValue, address: '' },
    ]);
    setDataValue({ label: inputValue, value: inputValue, address: '' });
    setOnboardingObject(prev => ({
      ...prev,
      physician_group_name: inputValue,
    }));
    setValue('physician_group_name', inputValue);
    setValue('new', true);
  };
  const renderNewAddedField = () => {
    if (!onboardingObject.physician_group_name) {
      return null;
    }
    return (
      <Box>
        <Box mt={5} />
        <StateDropDown
          value={STATES.find(state => state.value === getValues('state'))}
          name="state"
          width={'100%'}
          id="state"
          onChange={e => {
            setValue('state', e.value);
            clearErrors('state');
          }}
          control={control}
          errors={errors}
        />
        {/* <FormTextInput
          name="state"
          id="state"
          placeholder="Practice Address"
          style={{ opacity: '1' }}
          leftIcon={<LocationIcon />}
          leftIconProps={{ height: '50px' }}
          height="50px"
          size="md"
          borderRadius="lg"
          control={control}
        /> */}
        <Box mt={5} />
        <FormMultiSelect
          control={control}
          selectedValues={getValues('specialties')}
          name={'specialties'}
          placeholder={'Select Specialty'}
          options={specialtiesDropdown}
          // renderFormModal={renderSpecialtiesForModal}
          renderFormModal={renderSpecialtyForModal}
        />
        <Box mt={5} />
        <FormTextInput
          name="patients_per_month"
          id="patients_per_month"
          placeholder="No. of patients/month"
          style={{ opacity: '1' }}
          leftIconProps={{ height: '50px' }}
          height="50px"
          leftIcon={<PatientPerMonthIcon style={{ height: 15, width: 15 }} />}
          size="md"
          borderRadius="lg"
          control={control}
        />
      </Box>
    );
  };

  // const renderSpecialtiesForModal = (inputProps) => {
  //     return <SelectFormModal
  //         {...inputProps}
  //         title={'Add Specialties'}
  //         addNewText={'Other Specialty Name'}
  //         subTitle={''}
  //         isFetching={isSpecialtiesLoading}
  //         isCreating={isCreateSpecialtiesLoading}
  //         onCreateNewOption={createSpecialtyOption}
  //     />
  // }

  const promiseOptions = async inputValue => {
    return authRepository.getSpecialtyOptions({ search: inputValue });
  };
  const renderSpecialtySelect = () => {
    return (
      <SpecialtySelect
        dataValue={specialtyValue}
        setDataValue={setSpecialtyValue}
        promiseOptions={promiseOptions}
      />
    );
  };

  function renderSpecialtyForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Specialties'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Specialty'}
        isFetching={authContext.isFetchingSpecialtyOption}
        isCreating={authContext.isCreatingSpecialtyOption}
        onCreateNewOption={authContext.createSpecialtyOption}
        renderSpecialtySelect={renderSpecialtySelect}
        dataValue={specialtyValue}
      />
    );
  }

  const renderOldAddedField = () => {
    if (!onboardingObject.physician_group_name) {
      return null;
    }
    return (
      <Box mt={5}>
        <FormMultiSelect
          control={control}
          name={'specialties'}
          placeholder={'Select Specialty'}
          options={specialtiesDropdown}
          renderFormModal={renderSpecialtyForModal}
        />
      </Box>
    );
  };

  const formatCreateLabel = inputValue => {
    return (
      <Box>
        <Text
          fontSize={'small'}
          color={AppColors.gray}
        >{` No results matching " ${inputValue} "`}</Text>
        <HStack bg={'rgba(67,218,212,0.07)'} p={1} fontSize={'xs'}>
          <FilledPlusIcon style={{ height: 15, width: 15 }} />
          <span color={AppColors.secondary}>
            {' '}
            Add <span style={{ fontWeight: 'bolder' }}>{inputValue}</span> to
            RevIntegrity
          </span>
        </HStack>
      </Box>
    );
  };

  const formatOptionLabel = props => {
    return (
      <Box>
        <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm">
          {props.label}
          <span
            style={{ fontSize: 10, color: AppColors.darkGray, marginLeft: 5 }}
          >
            {props.address}
          </span>
        </Text>
      </Box>
    );
  };

  const phyGrpFilterOptions = inputValue => {
    return physicianDropdown.filter(healthSystem => {
      const healthSystemName = healthSystem.label?.toLowerCase();
      const searchedValue = inputValue.toLowerCase();
      return healthSystemName.startsWith(searchedValue);
    });
  };

  const phyGrpPromiseOptions = inputValue => {
    if (inputValue.length >= 5) {
      return new Promise(resolve => {
        // setTimeout(() => {
        resolve(phyGrpFilterOptions(inputValue));
        // }, 100);
      });
    }
  };

  return (
    <Box justifyContent={'center'} display={'flex'}>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Box w={'3xl'}>
          <Text size={'md'} align="center">
            Enter your{' '}
            <span style={{ color: AppColors.primary }}>practice's details</span>{' '}
            below:
          </Text>
          <Box mt={5}>
            <AsyncCreatableSelect
              name="physician_group_name"
              id="physician_group_name"
              control={control}
              formatCreateLabel={formatCreateLabel}
              isDisabled={onboardingObject.primaryEmailOnboarding}
              onChange={newValue => {
                setDataValue(newValue);
                setValue('physician_group_name', newValue.label);
                if (!newValue.id) {
                  setNewPhysicianGroup(true);
                  setValue('new', true);
                  setOnboardingObject(prev => ({
                    ...prev,
                    physician_group_name: newValue.label,
                  }));
                } else {
                  setNewPhysicianGroup(false);
                  setValue('new', false);
                  setValue('physician_group_id', newValue.value);
                  setOnboardingObject(prev => ({
                    ...prev,
                    physician_group_name: newValue.label,
                    physician_group_id: newValue.value,
                  }));
                }
                clearErrorData('physician_group_name', newValue.label);
              }}
              isSearchable={true}
              placeholder="Physician Group Name ( Enter minimum 5 characters to search )"
              // options={physicianDropdown}
              defaultOptions={[]}
              loadOptions={phyGrpPromiseOptions}
              value={
                dataValue && Object.keys(dataValue).length === 0
                  ? ''
                  : dataValue
              }
              styles={{
                noOptionsMessage: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                menu: props => ({
                  ...props,
                  zIndex: 10,
                }),
              }}
              formatOptionLabel={formatOptionLabel}
              components={{
                Control: ({ children, ...props }) => {
                  return (
                    <components.Control {...props}>
                      <span style={{ marginLeft: 10 }}>
                        <PhysicianGroupShortIcon />
                      </span>
                      {children}
                    </components.Control>
                  );
                },
              }}
              onCreateOption={handleCreate}
            />
          </Box>
          <Box mt={5}>
            {newPhysicianGroup ? renderNewAddedField() : renderOldAddedField()}
          </Box>
          <Box mt={14} />
          <NavigationFooter
            onPreviousClick={() => {
              if (
                !onboardingObject.primaryEmailOnboarding &&
                !onboardingObject.primary_owner_email
              ) {
                onPreviousClick(1);
              } else {
                onPreviousClick();
              }
            }}
            currentPageTitle={onboardingObject.userType}
          />
        </Box>
      </form>
    </Box>
  );
};
export default PhysicianGroupInfo;
