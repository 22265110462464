import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ROUTES from "../../../constants/Routes";
import { MANAGER_CLIENT_ACCESS_TOKEN } from "../../../constants/PreferenceKeys";
import LoginContainer from "./LoginContainer";

const ManagerClientAuth = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const clientToken = query.get('clientToken');

    useEffect(() => {
        if (clientToken) {
            sessionStorage.setItem(MANAGER_CLIENT_ACCESS_TOKEN, 'Bearer ' + clientToken);
            localStorage.setItem(MANAGER_CLIENT_ACCESS_TOKEN, sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN));
            if (localStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN) && sessionStorage.getItem(MANAGER_CLIENT_ACCESS_TOKEN)) {
                window.location.href = ROUTES.DASHBOARD;
            }
        }
    }, [clientToken]);

    return (
        <LoginContainer isManagerLogin={true} />
    );
}

export default ManagerClientAuth;
