import { API } from '../config/AppConfig';
import {
  CHART_ACCURACY_BREAKDOWN,
  CHART_ACTIVITY,
  CHART_ACTIVITY_DOWNCODED,
  CHART_ACTIVITY_MODIFIER,
  CHART_ACTIVITY_RVU,
  CHART_ACTIVITY_UPCODED,
  CQ_ESTIMATED_REV,
  CQ_REVENUE_OPP,
  CQ_SCORE,
  GET_AI_RISK,
  GET_FILE_BY_BATCH,
  HOSPITAL,
  MY_CHARTS,
  POTENTIAL_REVENUE,
  PROVIDERS,
  RVU_OPP,
  RVU_OPP_DROPDOWN,
  TOTAL_UPLOADS,
} from '../constants/Endpoints';

class DashboardRepository {
  async getHospitals(queryParams) {
    const response = await API.get(HOSPITAL, {
      queryParams,
    });
    return response;
  }
  async getChartUploads(queryParams) {
    const response = await API.get(MY_CHARTS, {
      queryParams,
    });
    return response;
  }
  async getProviders(queryParams) {
    const response = await API.get(PROVIDERS, {
      queryParams,
    });
    return response;
  }
  async getOutstandingRevenue(queryParams) {
    const response = await API.get(CQ_REVENUE_OPP, {
      queryParams,
    });
    return response;
  }
  async getChartAccuracyBreakdown(queryParams) {
    const response = await API.get(CHART_ACCURACY_BREAKDOWN, {
      queryParams,
    });
    return response;
  }
  async getPotentialRevenue(queryParams) {
    const response = await API.get(POTENTIAL_REVENUE, {
      queryParams,
    });
    return response;
  }
  async getChartActivity(queryParams) {
    const response = await API.get(CHART_ACTIVITY, {
      queryParams,
    });
    return response;
  }

  async getChartActivityDetailUp(queryParams) {
    const { metricType } = queryParams; 

    let endpoint;
    switch (metricType) {
        case 'rvu':
            endpoint = CHART_ACTIVITY_RVU; 
            break;
        case 'upcoded':
            endpoint = CHART_ACTIVITY_UPCODED; 
            break;
        case 'downcoded':
            endpoint = CHART_ACTIVITY_DOWNCODED;
            break;
        case 'modifier':
            endpoint = CHART_ACTIVITY_MODIFIER;
            break;
        default:
            throw new Error('Invalid metric type');
    }
    
    const response = await API.get(endpoint, {
        queryParams,
    });
    return response;
  }

  async getTotalUploads(queryParams) {
    const response = await API.get(TOTAL_UPLOADS, {
      queryParams,
    });
    return response;
  }
  async getCQScore(queryParams) {
    const response = await API.get(CQ_SCORE, {
      queryParams,
    });
    return response;
  }
  async getRVUOpp(queryParams) {
    const response = await API.get(RVU_OPP, {
      queryParams,
    });
    return response;
  }
  async getCQEstimatedRev(queryParams) {
    const response = await API.get(CQ_ESTIMATED_REV, {
      queryParams,
    });
    return response;
  }
  async getFileObjectByBatchId(batchId) {
    const response = await API.get(GET_FILE_BY_BATCH, {
      pathParams: { batchId },
    });
    return response;
  }
  async getAIRisk(queryParams) {
    const response = await API.get(GET_AI_RISK, {
      queryParams,
    });
    return response;
  }

  async getRVUOppDropdown(queryParams) {
    const response = await API.get(RVU_OPP_DROPDOWN, {
      queryParams,
    });
    return response;
  }
}

const dashboardRepository = new DashboardRepository();
export default dashboardRepository;
