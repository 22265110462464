import { useBoolean } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Providers from '../../../../components/dashboard_components/Providers';
import { CUSTOM } from '../../../../constants/constants';
import departmentDashboardRepository from '../../../../repositories/DepartmentRepository';
import { dateFormat } from '../../../../utils/common.utils';

function Dproviders({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
}) {
  const { departmentId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [providerData, setProviderData] = useState(null);
  const [search, setSearch] = React.useState('');
  const [ordering, setOrdering] = React.useState('');

  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchHospitalProvider = async () => {
    try {
      ifState.on();
      const filterParams = {};
      filterParams.filter = filter.toLowerCase();

      if (filter === CUSTOM.toLowerCase()) {
        setCustomDates(filterParams);
        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );
        delete filterParams.filter;
      }
      filterParams.department_id = departmentId;
      if (search) filterParams.search = search;
      if (ordering) filterParams.ordering = ordering;

      const response = await departmentDashboardRepository.getProviders(
        filterParams
      );
      return setProviderData(response);
    } catch (error) {
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchHospitalProvider();
    // eslint-disable-next-line
  }, [defaultStartDate, defaultEndDate, startDate, endDate, ordering, filter]);

  const uiStartDate = dateFormat(defaultStartDate, 'MM/dd/yyyy');
  const uiEndDate = dateFormat(defaultEndDate, 'MM/dd/yyyy');

  return (
    <Providers
      search={search}
      defaultStartDate={uiStartDate}
      defaultEndDate={uiEndDate}
      setSearch={setSearch}
      fetchHospitalProvider={fetchHospitalProvider}
      isFetching={isFetching}
      providerData={providerData}
      setOrdering={setOrdering}
      filter={filter}
    />
  );
}

export default Dproviders;
