import {
    Box,
    Text,
    HStack,
    Circle
} from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import AppColors from '../../../../../constants/AppColors';
import { PRIMARY_OWNER_TOOLTIP } from '../../../../../constants/constants';
import { CorrectIcon, HelpIcon } from '../../../../../constants/IconData';
import { AuthContext } from '../../../../../providers/AuthProvider';
import NavigationFooter from '../common_files/NavigationFooter';
import TooltipContent from '../TooltipContent';

const PrimaryOwnerSuccessPage = ({ onPreviousClick, onNextClick }) => {
    const [isHelpOpen, ihoState] = useBoolean(false)
    const { onboardingObject } = useContext(AuthContext)

    const form = useForm({
    });
    const { handleSubmit } = form;
    const onSubmitData = data => {
        onNextClick()
    };
    return <Box>
        <form onSubmit={handleSubmit(onSubmitData)}>
            <HStack justifyContent={'center'}>
                <Text fontSize={'large'} >
                    Will you be the primary owner of this account?
                </Text>
                <Circle bg={'transparent'} size={5} borderWidth={2} borderColor={AppColors.secondary} position='relative' onMouseEnter={() => {
                    ihoState.on()
                }} onMouseLeave={() => {
                    ihoState.off()
                }}>
                    <HelpIcon />
                    {isHelpOpen ? <TooltipContent data={PRIMARY_OWNER_TOOLTIP} /> : null}
                </Circle>
            </HStack>
            <Text fontSize={'large'} color={AppColors.primary} align={'center'} mt={3}>
                If not, enter the primary owner’s email below and we will send the recipient a link to sign up.
            </Text>
            <Box mt={10} align={'center'} >
                <CorrectIcon style={{ height: 25, width: 25 }} />
                <Text fontSize={'md'} align={'center'} mt={1}>
                    We will send an email to <span style={{ fontWeight: 'bold' }}>{onboardingObject.primary_owner_email ? onboardingObject.primary_owner_email : onboardingObject.email}</span> with a link to register.
                </Text>
            </Box>
            <Box mt={14} />
            <NavigationFooter onPreviousClick={onPreviousClick} currentPageTitle={onboardingObject.userType} />
        </form>
    </Box>
}
export default PrimaryOwnerSuccessPage