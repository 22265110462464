const ClientType = Object.freeze({
    HEALTH_SYSTEM: 'HEALTH_SYSTEM',
    HOSPITAL: 'HOSPITAL',
    DEPARTMENT: 'DEPARTMENT',
    PHYSICIAN: 'PHYSICIAN', 
    PHYSICIANS_GROUP: 'PHYSICIANS_GROUP',
    UNDEFINED: "USER_NOT_DEFINED" 
})

const userTyepToEnumMapping = Object.freeze({
    'HEALTH SYSTEM': 'HEALTH_SYSTEM',
    'HOSPITAL': 'HOSPITAL',
    'DEPARTMENT': 'DEPARTMENT',
    'PROVIDER': 'PHYSICIAN',
    'PHYSICIANS GROUP': 'PHYSICIANS_GROUP'
})

const getCurrentClientType = currentUser => {
    return userTyepToEnumMapping[currentUser?.user_type] ?
     userTyepToEnumMapping[currentUser?.user_type]: ClientType.UNDEFINED;
};

export { getCurrentClientType, ClientType }