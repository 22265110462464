import { format } from "date-fns";
import { AUDIT_SHEET_KEY } from "../constants";

const sanitize = (sheets, providerOptions) => {
    //removing empty object from array
    // change the date, provider id

    const modified = sheets.map(sheet => {
        const updatedData = []
        sheet.data.forEach(element => {
            if (Object.keys(element).length !== 0) {
                if (!Object.keys(element).every(key => {
                    if (AUDIT_SHEET_KEY.includes(key)) {
                        if ((element[key] === null || element[key] === '')) {
                            return true
                        }
                        return false
                    }
                    return true
                })) {
                    if (element.rendering) {
                        if (typeof element.rendering === 'object' && Object.keys(element.rendering).length) {
                            element.rendering = element.rendering.id
                        } else {
                            const providerOption = providerOptions.find(p => p.value === element.rendering);
                            if (providerOption) {
                                element.rendering = providerOption.id
                            }
                    }

                }
                if (element.enc_dt) {
                    const d = Date.parse(element.enc_dt)
                    if (d) {
                        element.enc_dt = format(new Date(d), 'yyyy-MM-dd')
                    }
                }
                updatedData.push(element)
            }
        }
        });
    return {
        ...sheet,
        data: updatedData
    }
})
return modified

}
export { sanitize }