import {
  Box,
  VStack,
  Flex,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import CQFooter from '../../../../components/layout/CQFooter';
import OnBoarding from '../components/health_system_onboarding/OnBoarding';
import ROUTES from '../../../../constants/Routes';
import { CodeQuickTextIcon } from '../../../../constants/IconData';

const HealthSystemContainer = props => {
  const navigate = useNavigate();
  return (
    <VStack
      spacing={0}
      bgColor={AppColors.loginFormBg}
      justifyContent={'space-between'}
    >
      <Box w={'100%'}>
        <Flex
          bg={useColorModeValue(AppColors.primary)}
          color={useColorModeValue(AppColors.secondary, 'white')}
          minH={'60px'}
          align={'center'}
        >
          <Flex flex={{ base: 1 }}>
            <Box m={1} />
            <Flex
              flex={{ base: 1 }}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CodeQuickTextIcon
                onClick={() => {
                  navigate(ROUTES.REGISTER);
                }}
                style={{ height: 40, cursor: 'pointer' }}
              />
              <Heading fontSize="md" ml="2">
                - Health System C-Level
              </Heading>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box  h={'100%'}>
        <OnBoarding />
      </Box>
      <CQFooter />
    </VStack>
  );
};

export default HealthSystemContainer;
