import {
  Box,
  Button,
  Center,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  VStack,
  Flex,
  Switch,
} from '@chakra-ui/react';
import { CQReactTable } from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import { CUSTOM } from '../../../../constants/constants';
import ROUTES from '../../../../constants/Routes';
import { FONT_FAMILY } from '../../../../constants/Theme';
import physicianRepository from '../../../../repositories/PhysiciansRepository';
import { dateFormat } from '../../../../utils/common.utils';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { priceConverter } from '../../../../utils/priceConverter';
import { format } from 'date-fns';
import InfoTooltip from '../../../../components/InfoTooltip';

const columnHelper = createColumnHelper();

const ChartAccuracyTable = ({
  chartAccuracydata,
  setChartAccuracyData,
  isLoading,
  setOrdering,
  navigate,
}) => {

  const handleReviewToggle = async (rowData) => {
    try {
      const updatedData = [...chartAccuracydata];
      // Find the index of the row to be updated based on chart_id and location
      const rowIndex = updatedData.findIndex(item => item.chart_id === rowData.chart_id && item.location === rowData.location);
      if (rowIndex !== -1) {
        updatedData[rowIndex].review = !rowData.review;
        setChartAccuracyData(updatedData);
        await physicianRepository.updateAccuracyReviewStatus(updatedData[rowIndex])
      }
    } catch (error) {
      console.error('Error toggling review status:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('batch_id', {
        header: () => 'Upload #',
        id: 'batch_id',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),
      columnHelper.accessor('file_id', {
        header: () => 'File Id',
        id: 'file_id',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),
      columnHelper.accessor(row => row.upload_date, {
        id: 'upload_date',
        header: () => <span>Upload Date</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('location', {
        header: () => (
          <Tooltip
            bg={'white'}
            color={'black'}
            w={200}
            placement="top"
            label="This is the line number in the audit sheet where the error was identified. Click on 'View' to see the error."
            aria-label="A tooltip"
          >
            Location
          </Tooltip>
        ),
        id: 'location',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('error', {
        header: () => <span>Error</span>,
        id: 'error',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('suggestion', {
        header: () => 'Suggestion',
        id: 'suggestion',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('rvu_opportunity', {
        header: () => 'Opportunity',
        id: 'rvu_opportunity',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return (
            <>
              {cellValue > 0 ? (
                <Text color={'#77C586'}>{priceConverter(cellValue)}</Text>
              ) : (
                <Text color={'red'}>{`-${priceConverter(-cellValue)}`}</Text>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('', {
        header: () => 'Action',
        id: 'action',
        cell: info => {
          return (
            <Button
              size={'sm'}
              bg={AppColors.secondary}
              color={'white'}
              borderRadius="3xl"
              px={10}
              fontWeight="normal"
              fontSize={'sm'}
              navigate
              onClick={() => {
                navigate(
                  `${ROUTES.SINGLE_AUDIT_SHEET.replace(
                    ':uploadId',
                    info.row.original.chart_id
                  )}/?location=${info.row.original.location}`
                );
              }}
            >
              View
            </Button>
          );
        },
      }),
      columnHelper.accessor('review', {
        header: () => 'Review',
        enableSorting: true,
        id: 'review',
        cell: info => {
          const reviewValue = info.row.original.review;
          return (
            <Flex alignItems="center" justifyContent="center" padding={2}>
              <Text width="24px" marginRight={2} textAlign="center">{reviewValue ? 'Yes' : 'No'}</Text>
              <Switch
                isChecked={reviewValue}
                onChange={() => handleReviewToggle(info.row.original)}
                colorScheme="green"
                size="lg"
              />
            </Flex>
          );
        },
      }),
    ],
    // eslint-disable-next-line
    [chartAccuracydata]
  );

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['error'];

    return {
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(data.column.id) ? 'left' : 'center',
      color: startingHeaderId.includes(data.column.id)
        ? AppColors.black
        : AppColors.gray,
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['error'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(header.id) ? 'left' : 'center',
      fontWeight: 'bold',
      color: AppColors.black,
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  return (
    <Box
      id="fixedTableHeader"
      className="tableWraper"
      maxHeight={'400px'}
      overflowY={'scroll'}
    >
      <CQReactTable
        isLoading={isLoading}
        data={chartAccuracydata}
        columns={columns}
        {...defaultReactTableProp}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        getCellProps={getTableCellProps}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
      {chartAccuracydata?.length === 0 && (
        <Center>There is no data to display</Center>
      )}
    </Box>
  );
};

function PAuditChartAccuracy({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
  selectedDates,
}) {
  const { physicianId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [chartAccuracydata, setChartAccuracyData] = useState(null);
  const [ordering, setOrdering] = useState('');
  const navigate = useNavigate();
  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <ChartAccuracyTable
          chartAccuracydata={chartAccuracydata}
          setChartAccuracyData={setChartAccuracyData}
          isLoading={isFetching}
          setOrdering={setOrdering}
          navigate={navigate}
        />
      </ScaleFade>
    );
  };
  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };
  const getChartAccuracyData = async () => {
    try {
      ifState.on();
      const filterParams = {};
      filterParams.filter = filter.toLowerCase();

      if (filter === CUSTOM.toLowerCase()) {
        await setCustomDates(filterParams);
        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );
        delete filterParams.filter;
      }
      filterParams.provider_id = physicianId;
      const response = await physicianRepository.getAuditChartAccuracy(
        filterParams
      );
      setChartAccuracyData(response);
      return response;
    } catch (error) {
      setChartAccuracyData(null);
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    getChartAccuracyData();
    // eslint-disable-next-line
  }, [defaultStartDate, defaultEndDate, ordering, startDate, endDate, filter]);

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!chartAccuracydata) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };

  return (
    <Box
      marginTop={3}
      width={'100%'}
      bg={AppColors.white}
      padding={4}
      borderRadius={'lg'}
      shadow={'md'}
    >
      <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
          Upload Breakdown
        </Text>
        <InfoTooltip infoContent={
          <>
            <p>
              <b>Upload Breakdown</b> details each batch of charts uploaded, sortable by each column header. It can also function as a work queue by using the checkboxes in the far-right column, allowing you to track and manage chart uploads effectively.
            </p>
          </>} />
      </HStack>

      {renderMainContent()}
    </Box>
  );
}

export default PAuditChartAccuracy;
