import {
  Button,
  Heading,
  Stack,
  Image,
  Box,
  Text,
  VStack,
  Center,
  Flex,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import {
  EyeHiddenIcon,
  EyeIcon,
  LockIcon,
  MailIcon,
} from '../../../constants/IconData';
import {
  CQLoginImage,
  LoginCardWatermark,
  QWatermark,
} from '../../../constants/ImageData';
import { FONT_FAMILY } from '../../../constants/Theme';
import {
  FormPasswordInput,
  FormTextInput,
} from '@laxmimanogna/code-quick-components';
import { useContext } from 'react';
import { AuthContext, AUTH_FORMS } from '../../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink } from 'react-router-dom';
import ROUTES from '../../../constants/Routes';
import { LOGIN_SCHEMA } from '../../../validation/Auth.validation';
import useDeviceScreen from '../../../hooks/useDeviceScreen';

export default function LoginContainer({isManagerLogin = false}) {
  const authContext = useContext(AuthContext);

  const isSubmitting = authContext.isLoggingIn;

  const formServerError = authContext.forms[AUTH_FORMS.LOGIN]?.error;

  const handleLogin = async formData => {
    const { email, password } = formData;
    authContext.onLogin({ email, password });
  };
  const form = useForm({
    resolver: yupResolver(LOGIN_SCHEMA),
  });

  const { isTablet, isMobile } = useDeviceScreen();

  const { control, handleSubmit } = form;
  function renderManagerLogin() {
    return (
      <VStack
        flex={1}
        zIndex={1}
        style={{ marginTop: 45, marginBottom: 45 }}
        borderRadius={'3xl'}
        bgColor={isMobile ? AppColors.loginFormBg : 'transparent'}
        justifyContent={'center'}
      >
        <Center
          borderRadius={isMobile ? '3xl' : 'inherit'}
          bg={AppColors.loginFormBg}
          width={'100%'}
        >
          <Stack
            p={'15%'}
            w={'100%'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <Heading
              fontFamily={FONT_FAMILY.baiJamurjee}
              mb={2}
              color={AppColors.secondary}
              fontSize={'30px'}
            >
              Login
            </Heading>
            <Text
              pb={4}
              mb={2}
              textAlign={'center'}
              lineHeight={'30px'}
              fontFamily={FONT_FAMILY.ptSans}
            >
              Manager Login Please Wait...
            </Text>
          </Stack>
        </Center>
      </VStack>
    );
  }
  
  function renderLoginForm() {
    return (
      <VStack
        flex={1}
        zIndex={1}
        style={{ marginTop: 45, marginBottom: 45 }}
        borderRadius={'3xl'}
        bgColor={isMobile ? AppColors.loginFormBg : 'transparent'}
        justifyContent={'center'}
      >
        <Center
          borderRadius={isMobile ? '3xl' : 'inherit'}
          bg={AppColors.loginFormBg}
          width={'100%'}
        >
          <Stack
            p={'15%'}
            w={'100%'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <Heading
              fontFamily={FONT_FAMILY.baiJamurjee}
              mb={2}
              color={AppColors.secondary}
              fontSize={'30px'}
            >
              Login
            </Heading>
            <Text
              pb={4}
              mb={2}
              textAlign={'center'}
              lineHeight={'30px'}
              fontFamily={FONT_FAMILY.ptSans}
            >
              Enter your credentials to access your account
            </Text>
            {formServerError && (
              <Text fontWeight={'bold'} color={'red.400'}>
                {formServerError}
              </Text>
            )}

            <form onSubmit={handleSubmit(handleLogin)}>
              <Flex width={'100%'} flexDirection={'column'} ml={4}>
                <FormTextInput
                  name="email"
                  control={control}
                  id="email"
                  width={'100%'}
                  placeholder="Email"
                  _placeholder={{ color: 'black' }}
                  leftIcon={<MailIcon style={{ paddingTop: '12px' }} />}
                  height={'50px'}
                />
                <Box pt={3} />
                <FormPasswordInput
                  control={control}
                  name="password"
                  id="password"
                  placeholder="Password"
                  _placeholder={{ color: 'black' }}
                  leftIcon={<LockIcon style={{ paddingTop: '12px' }} />}
                  passwordShowIcon={
                    <EyeHiddenIcon style={{ paddingTop: '12px' }} />
                  }
                  passwordHideIcon={<EyeIcon style={{ paddingTop: '12px' }} />}
                  size="md"
                  height={'50px'}
                />
                <Box pt={4} />
                <Stack spacing={6}>
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    bgColor={AppColors.primary}
                    colorScheme={'blue'}
                    variant={'solid'}
                    height={'50px'}
                    fontSize={'18px'}
                    _hover={{ bg: AppColors.secondary }}
                  >
                    Login
                  </Button>
                  <Stack spacing={3}>
                    <Text fontFamily={FONT_FAMILY.ptSans}>
                      Don't have an account?{' '}
                      <NavLink
                        to={ROUTES.REGISTER}
                        style={{ color: AppColors.secondary }}
                      >
                        Register Here
                      </NavLink>
                    </Text>
                    <Text fontFamily={FONT_FAMILY.ptSans}>
                      Forgot Password?
                      <NavLink
                        to={ROUTES.FORGOT_PASSWORD_SCREEN}
                        style={{ color: AppColors.secondary }}
                      >
                        &nbsp;Click here
                      </NavLink>
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
            </form>
          </Stack>
        </Center>
      </VStack>
    );
  }

  function renderCoverImage() {
    return (
      <VStack
        bgImage={LoginCardWatermark}
        display={{ base: 'none', md: 'flex' }}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        py={32}
        border={'3px solid'}
        borderColor={'rgba(151,151,151,0.3)'}
        rounded="md"
        px={8}
        flex={1}
        textAlign={'center'}
        spacing={8}
        justifyContent={'center'}
        bgColor={AppColors.secondary}
        zIndex={9999}
        borderRadius={'3xl'}
      >
         <Image boxSize={'8rem'} alt={'cq-image-logo'} src={CQLoginImage} />
        <Text
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontSize={'lg'}
          letterSpacing={'1.5px'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          color={'#fff'}
        >
          RevIntegrity
        </Text>
        <Box
          boxSizing={'border-box'}
          width={'92px'}
          border={'2px solid #FFFFFF '}
          opacity={'0.3'}
          borderRadius={'full'}
        />
        <Stack mt={5}>
          <Heading
            color={'white'}
            // fontWeight={'bold'}
            letterSpacing={'1px'}
            fontSize={'30px'}
            fontFamily={FONT_FAMILY.baiJamurjee}
          >
            Client Portal
          </Heading>
          <Stack px={10}>
            <Text
              mt={3}
              opacity={0.5}
              fontSize={'18px'}
              fontFamily={FONT_FAMILY.ptSans}
              color={'white'}
            >
              Welcome to our client portal!
              <Text>This portal is designed to provide a convenient view of revenue opportunity and coding accuracy.</Text>
            </Text>
          </Stack>
        </Stack>
      </VStack>
    );
  }

  return (
    <Flex
      bgColor={AppColors.primary}
      // py={'5%'}
      // px={isMobile ? '20%' : '10%'}
      alignItems={'center'}
      justifyContent={'center'}
      minH={'100vh'}
    >
      {isMobile ? (
        <Image
          top={'5vh'}
          left={30}
          style={{ height: '95vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      ) : (
        <Image
          top={'5vh'}
          left={5}
          style={{ height: '80vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      )}
      <Stack
        spacing={-8}
        height={'730px'}
        direction={{ base: 'column', md: 'row' }}
        // marginTop={!isTablet ? '30%' : ''}
        // width={'100%'}
        px={!isTablet ? '5%' : '0'}
        width={'1150px'}
      >
        {renderCoverImage()}
        {isManagerLogin ? renderManagerLogin() : renderLoginForm()}
      </Stack>
    </Flex>
  );
}
