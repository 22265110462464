const AppColors = {
  primary: '#43DAD4',
  secondary: '#023B93',
  bgColor: '#EBEBEE',
  clientBgColor: '#FFFFFF',
  loginFormBg: '#F7F7FA',
  black: "#000000",
  gray: '#AEAEAE',
  modalOverlayColor: '#001A41',
  white: '#FFFFFF',
  orange: '#F9480F', // for delete button
  lightSkyBlue: '#324666c9',
  red: '#F9480F',
  danger: '#EFA963',
  lightDanger: '#FDF6EF',
  markAsUrgent: '#FDF6EF', //markAsUrgent backGround
  markAsUrgentDot: '#EFA963', //mark as urgent dot color
  lightGrey: '#AEAEAE',
  yellowColor: '#FFB647',
  closeButtonColor: '#8894A6',
  lightBlue: '#c9ecf8a4',
  selectHoverColor: '#77d2d9',
  offWhite: '#E6E6E6',
  darkGray: '#6D747E',
  lightPink: '#FBE4D6',
  green: '#77C586',
  hoverColor: '#77d2d91A'

};

export default AppColors;
