/* eslint-disable react-hooks/exhaustive-deps */
import { FormLabel } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import MyAccountProvider, {
  MyAccountContext,
} from '../../../providers/MyAccountProvider';
import EditPracticeInfo from './EditPracticeInfo';

const HealthSystemPracticeInfo = ({ id, user, status }) => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUserPracticeInfo = myAccountContext.currentUserPracticeInfo;

  useEffect(() => {
    myAccountContext.practiceInfoHealthSystem(id);
  }, [id]);

  return (
    <>
      <FormLabel
        fontFamily={FONT_FAMILY.baiJamurjee}
        fontWeight={'bold'}
        fontSize={'xl'}
        color={AppColors.black}
        ml={250}
        mt={10}
      >
        Health System Info
      </FormLabel>
      {currentUserPracticeInfo && (
        <EditPracticeInfo
          profileData={currentUserPracticeInfo}
          id={id}
          user={user}
          status={status}
        />
      )}
      {/* <CompliancePlan /> */}
    </>


  );
};

export default withProvider(MyAccountProvider, HealthSystemPracticeInfo);
