import { Box, HStack, Text } from "@chakra-ui/react"
import AppColors from "../../../../../constants/AppColors"

const Footer = () => {
    const presentYear = new Date().getFullYear();
    return <Box
        w={'100%'}
        py={4}
        alignItems={'center'}
        textAlign={'center'}
        bg={AppColors.bgColor}>
        <HStack w={'100%'} justifyContent={'center'} spacing={2} alignItems={'center'}>
            <Text>Copyright @ {presentYear} </Text>
            <Text fontWeight={'bold'} color={AppColors.secondary}>RevIntegrity</Text>
        </HStack>
    </Box>
}
export default Footer