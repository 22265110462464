import { useBoolean, usePagination, useToastr } from "@laxmimanogna/code-quick-components";
import { createContext, useState } from "react";
import { SELF } from "../constants/constants";
import dashboardRepository from "../repositories/DashboardRepository";
import myChartRepository from "../repositories/MyChartRepository";

export const MyChartContext = createContext({
    getAllCharts: async () => { },
    getArchivedCharts: async () => { },

    allCharts: [],
    archivedCharts: [],

    filterParams: {},
    handleApplyFilter: () => { },
    isAllChartLoading: false,
    isArchivedChartLoading: false,
    getFileObjectByBatchId: async () => { },
    isFileLoading: false
});
const initialFilterParams = {
    searchText: ''
}

const MyChartProvider = props => {
    const toast = useToastr();

    const [allCharts, setAllCharts] = useState([])
    const [archivedCharts, setArchivedCharts] = useState([])
    const [isAllChartLoading, ialState] = useBoolean(false)
    const [isArchivedChartLoading, iarState] = useBoolean(false)
    const [isFileLoading, iflState] = useBoolean(false)

    const [filterParams, setFilterParams] = useState(
        Object.assign({}, initialFilterParams)
    );

    const getFilterParams = () => {
        const queryParams = {};
        const { searchText } = filterParams;
        if (searchText) queryParams['search'] = searchText;

        return queryParams;
    };

    const handleApplyFilter = selectedFilters => {
        setFilterParams(selectedFilters);
    };

    const getAllCharts = async (paginationParams) => {
        const filterParams = getFilterParams();
        filterParams['user'] = SELF
        ialState.on()
        try {
            const response = await myChartRepository.getCharts({ ...filterParams, ...paginationParams })
            setAllCharts(response.results)
            return response
        } catch (err) {
            toast.showError({
                description: `Something went wrong`,
            });
        } finally {
            ialState.off()
        }
    }
    const getArchivedCharts = async (paginationParams) => {
        const filterParams = getFilterParams();
        filterParams['status'] = 'RECEIVED'
        filterParams['user'] = SELF
        iarState.on()
        try {
            const response = await myChartRepository.getCharts({ ...filterParams, ...paginationParams })
            setArchivedCharts(response.results)
            return { ...response, count: response.results.length }
        } catch (err) {
            toast.showError({
                description: `Something went wrong`,
            });
        } finally {
            iarState.off()
        }
    }

    const getFileObjectByBatchId = async (batchId) => {
        try {
            iflState.on();
            const response = await dashboardRepository.getFileObjectByBatchId(batchId);
            return response;
        } catch (err) {
            toast.showError({
                description: `Something went wrong.`,
            });
        } finally {
            iflState.off();
        }
    }
    const getAllCharts1 = usePagination(getAllCharts)
    const getArchivedCharts1 = usePagination(getArchivedCharts)


    const mContext = {
        getAllCharts: getAllCharts1,
        getArchivedCharts: getArchivedCharts1,

        allCharts,
        archivedCharts,
        filterParams,
        handleApplyFilter,
        isAllChartLoading,
        isArchivedChartLoading,
        getFileObjectByBatchId,
        isFileLoading
    };

    return (
        <MyChartContext.Provider value={mContext}>
            {props.children}
        </MyChartContext.Provider>
    );
};

export default MyChartProvider;
