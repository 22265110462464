/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, Text } from '@chakra-ui/react';
import {
  FormPasswordInput,
  FormTextInput,
  strengthChecker,
} from '@laxmimanogna/code-quick-components';
import { useContext, useState, useEffect } from 'react';
import { PasswordStrength } from '../../../../../components/PasswordStrength';
import {
  EyeHiddenIcon,
  EyeIcon,
  LockIcon,
  MailIcon,
} from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NavigationFooter from '../common_files/NavigationFooter';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { CREATE_ACCOUNT_SCHEMA } from './validation';
import { REGISTER_ERROR_PAGE } from '../../../../../constants/constants';

const CreateAccount = ({ onPreviousClick, onNextClick }) => {
  const {
    onboardingObject,
    setOnboardingObject,
    onRegister,
    isRegisterLoading,
    registerError,
    currentPage,
    registerBody,
  } = useContext(AuthContext);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const form = useForm({
    resolver: yupResolver(CREATE_ACCOUNT_SCHEMA),
  });
  const { control, handleSubmit, reset, setError, clearErrors } = form;

  useEffect(() => {
    if (Object.keys(onboardingObject).length) {
      reset({
        new_email: onboardingObject.email,
        new_password: onboardingObject.new_password,
        confirm_password: onboardingObject.new_password,
      });
      if (onboardingObject.new_password) {
        const value = strengthChecker(onboardingObject.new_password);
        setPasswordStrength(value);
      }
    }
    if (Object.keys(registerError).length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage].map(key => {
        if (registerErrorKeys.includes(key)) {
          if (
            key === 'email' &&
            onboardingObject.new_email === registerBody.email
          ) {
            setError('new_email', {
              type: 'custom',
              message: registerError[key],
            });
          } else if (
            key === 'password' &&
            onboardingObject.new_password === registerBody.password
          ) {
            setError('new_password', {
              type: 'custom',
              message: registerError[key],
            });
          }
        }
      });
    }
  }, []);

  const clearErrorData = (key, data) => {
    if (data !== registerBody[key.replace('new_', '')]) {
      clearErrors(key);
    } else if (data === registerBody[key.replace('new_', '')]) {
      setError(key, {
        type: 'custom',
        message: registerError[key.replace('new_', '')],
      });
    }
  };

  const onSubmitData = async data => {
    const obj = { ...onboardingObject, ...data, email: data.new_email };
    setOnboardingObject(obj);
    if (passwordStrength < 4) {
      setError('new_password', {
        type: 'custom',
        message:
          'Password must contain minimum of 8 characters and maximum of 25 characters, with a combination of lower case and upper case letters, numbers and symbols',
      });
    } else {
      const registerBody = {
        first_name: onboardingObject.first_name,
        last_name: onboardingObject.last_name,
        email: data.new_email,
        password: data.new_password,
        primary_owner_email: onboardingObject.primary_owner_email,
        user_type: 'HOSPITAL',
        address:onboardingObject.state
      };
      if (onboardingObject.new_health_system) {
        registerBody.new_health_system = {
          name: onboardingObject.health_system_name,
          address: onboardingObject.health_system_address,
        };
      } else {
        registerBody.existing_health_system = Number(
          onboardingObject.health_system_id
        );
      }
      if (onboardingObject.new_hospital) {
        registerBody.new_hospital = {
          name: onboardingObject.practice_name,
          address:onboardingObject.state,
          patients_per_month: onboardingObject.patients_per_month,
        };
      } else {
        registerBody.existing_hospital = Number(onboardingObject.practice_id);
      }
      await onRegister(registerBody);
    }
  };

  return (
    <Box justifyContent={'center'} display={'flex'}>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Box w={'sm'}>
          <Text size={'md'} fontWeight="semibold" align="center">
            You're almost done!
          </Text>
          <Box mt={5}>
            <FormTextInput
              name="new_email"
              id="new_email"
              placeholder="name@gmail.com"
              style={{ opacity: '1' }}
              leftIcon={<MailIcon />}
              leftIconProps={{ height: '50px' }}
              height="50px"
              control={control}
              onChange={e => {
                clearErrorData('new_email', e.target.value);
              }}
            />
          </Box>
          <Box mt={5}>
            <FormPasswordInput
              name="new_password"
              id="new_password"
              placeholder="New Password"
              leftIcon={<LockIcon />}
              passwordShowIcon={<EyeHiddenIcon />}
              passwordHideIcon={<EyeIcon />}
              control={control}
              onChange={e => {
                const value = strengthChecker(e.target.value);
                setPasswordStrength(value);
                clearErrorData('new_password', e.target.value);
              }}
            />
          </Box>
          <Box mt={5}>
            <FormPasswordInput
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm Password"
              leftIcon={<LockIcon />}
              passwordShowIcon={<EyeHiddenIcon />}
              passwordHideIcon={<EyeIcon />}
              control={control}
              onChange={e => {
                clearErrorData('confirm_password', e.target.value);
              }}
            />
          </Box>
          <Box mt={5} />
          <Text fontSize={'sm'} ml={1}>
            Password Strength
          </Text>
          <PasswordStrength strength={passwordStrength} />
          <Box mt={14} />
          <NavigationFooter
            onPreviousClick={onPreviousClick}
            currentPageTitle={onboardingObject.userType}
            pageTitle={'CREATE_ACCOUNT'}
            isLoading={isRegisterLoading}
          />
        </Box>
      </form>
    </Box>
  );
};
export default CreateAccount;
