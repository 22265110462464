import Handsontable from "handsontable";
import AppColors from "../../../../constants/AppColors";

class AuditSheetEditor extends Handsontable.editors.NumericEditor {
  init() {
    super.init()
  }
}

function getScaledRowFontSize(scale) {
  return (14 * scale) + 'px';
}

function customDropdownRenderer(instance, td, row, col, prop, value, cellProperties) {
  // td.style.textAlign = 'center';
  td.style.fontSize = getScaledRowFontSize(cellProperties?.scale);
  Handsontable.renderers.AutocompleteRenderer(instance, td, row, col, prop, value, cellProperties)
}

function currencyRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (value && !''.startsWith('$')) {
    value = `$${value}`;
  }

  td.style.textAlign = 'center';
  td.style.fontSize = getScaledRowFontSize(cellProperties?.scale);
  td.style.background = AppColors.lightPink
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties);
}

export function registerCustomCells({ scale }) {
  Handsontable.cellTypes.registerCellType('audit_sheet.currency_renderer', {
    editor: AuditSheetEditor,
    renderer: currencyRenderer,
    validator: Handsontable.validators.NumericValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    type: 'numeric',
    scale: scale,
  });

  Handsontable.cellTypes.registerCellType('audit_sheet.dropdown_renderer', {
    editor: Handsontable.editors.DropdownEditor,
    renderer: customDropdownRenderer,
    validator: Handsontable.validators.AutocompleteValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    scale: scale,
  });

  Handsontable.cellTypes.registerCellType('audit_sheet.currency_renderer', {
    editor: AuditSheetEditor,
    renderer: currencyRenderer,
    validator: Handsontable.validators.NumericValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    type: 'numeric',
    scale: scale,
  });
}
