import ROUTES from "../constants/Routes";
import ConfirmationContainer from "../pages/auth/forgot_password/ConfirmationContainer";
import ForgotPasswordContainer from "../pages/auth/forgot_password/ForgotPasswordContainer";
import LoginContainer from "../pages/auth/login/LoginContainer";
import ResetConfirmationContainer from "../pages/auth/reset_password/ResetConfirmationContainer";
import ResetPasswordContainer from "../pages/auth/reset_password/ResetPasswordContainer";
import OnBoardingContainer from "../pages/auth/register/containers/OnBoardingContainer";
import HealthSystemContainer from "../pages/auth/register/containers/HealthSystemContainer";
import PhysicianGroupOnboarding from "../pages/auth/register/components/physician_group_onboarding/PhysicianGroupOnboarding";
import ManagerClientAuth from "../pages/auth/login/ManagerClientAuth";

const AuthRouter = [{
  route: ROUTES.LOGIN_SCREEN,
  element: <LoginContainer />,
},
{
  route: ROUTES.FORGOT_PASSWORD_SCREEN,
  element: <ForgotPasswordContainer />,
},
{
  route: ROUTES.CONFIRMATION_SCREEN,
  element: <ConfirmationContainer />
},
{
  route: ROUTES.RESET_PASSWORD_SCREEN,
  element: <ResetPasswordContainer />
},
{
  route: ROUTES.RESET_CONFIRMATION_SCREEN,
  element: <ResetConfirmationContainer />
},
{
  route: ROUTES.REGISTER,
  element: <OnBoardingContainer />
},
{
  route: ROUTES.ON_BOARDING,
  element: <HealthSystemContainer />
},
{
  route: ROUTES.CLIENT_PHYSICIAN_GROUP,
  element: <PhysicianGroupOnboarding />
},
{
  route: ROUTES.MANAGER_LOGIN_AS_CLIENT,
  element: <ManagerClientAuth />,
},
];

export default AuthRouter;
