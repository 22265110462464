import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CQReactTable, PrimaryButton } from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import { defaultReactTableProp, paginationProps } from '../../../utils/my_audits.utils';
import AppColors from '../../../constants/AppColors';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardContext } from '../../../providers/DashboardProvider';
import ROUTES from '../../../constants/Routes';
import { STATUS } from '../../../constants/constants';
import { getUserType, replaceRoute } from '../../../utils/common.utils';
import { format } from 'date-fns';
import { AuthContext } from '../../../providers/AuthProvider';

const columnHelper = createColumnHelper();

const ChartActivityTable = ({ selectedTab }) => {
  const [ordering, setOrdering] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const dashboardContext = useContext(DashboardContext);
  const authContext = useContext(AuthContext);

  const {
    getChartActivityRvu,
    getChartActivityUpcoded,
    getChartActivityDowncoded,
    getChartActivityModifier,
    handleApplyFilter,
    uploadedChartsActivityRvu,
    uploadedChartsActivityUpcoded,
    uploadedChartsActivityDowncoded,
    uploadedChartsActivityModifier,
    isFetchingChartActivityRvu,
    isFetchingChartActivityUpcoded,
    isFetchingChartActivityDowncoded,
    isFetchingChartActivityModifier,
    isAllChartLoading,
    filterParams,
    chartFilters,
  } = dashboardContext;

  const userTypeID = getUserType(authContext.currentUser);

  useEffect(() => {
    if (state && Object.keys(state).length) {
      handleApplyFilter(prev => ({ ...prev, ...state }));
    }
    dashboardContext.setUserType(userTypeID);
  }, []);

  useEffect(() => {
    loadChartData();
  }, [dashboardContext.userTypes,
    chartFilters,
  getChartActivityRvu.showOrdering,
  getChartActivityUpcoded.showOrdering,
  getChartActivityDowncoded.showOrdering,
  getChartActivityModifier.showOrdering,
    filterParams,]);

  const loadChartData = async () => {

    if (!Object.keys(filterParams).length) {
      return;
    }

    if (!Object.keys(dashboardContext.userTypes).length) {
      return;
    }
    let searching = !!(chartFilters.search && chartFilters.search.trim());
    getChartActivityRvu.loadAPI('View Chart Activity', 'rvu', searching);
    getChartActivityUpcoded.loadAPI('View Chart Activity', 'upcoded', searching);
    getChartActivityDowncoded.loadAPI('View Chart Activity', 'downcoded', searching);
    getChartActivityModifier.loadAPI('View Chart Activity', 'modifier', searching);

  };

  const getColumnsByTab = (tab) => {
    const columnMap = {
      0: [{ path: 'rvu', accessor: 'metric', label: 'RVU' }, { accessor: 'metric_percentage', label: 'RVU Percentage' }],
      1: [{ path: 'upcoded', accessor: 'metric', label: 'Upcoded' }, { accessor: 'metric_percentage', label: 'Upcoded Percentage' }],
      2: [{ path: 'downcoded', accessor: 'metric', label: 'Downcoded' }, { accessor: 'metric_percentage', label: 'Downcoded Percentage' }],
      3: [{ path: 'modifier', accessor: 'metric', label: 'Modifier' }, { accessor: 'metric_percentage', label: 'Modifier Percentage' }],
    };
    return columnMap[tab] || [];
  };

  const handleAuditSheetView = useCallback((cellValue) => {
    const tabColumns = getColumnsByTab(selectedTab);
    const auditSheetURL = replaceRoute(ROUTES.SINGLE_AUDIT_SHEET_TYPE, { uploadId: cellValue, type: tabColumns[0].path });
    navigate(auditSheetURL);
  }, [selectedTab]);

  const columns = useMemo(() => {
    const selectedColumns = getColumnsByTab(selectedTab);
    return [
      columnHelper.accessor('chart_id_prefix', {
        header: () => <span>Chart ID</span>,
        cell: info => info.getValue() || <Text color={AppColors.gray}>-</Text>,
      }),
      columnHelper.accessor('uploaded_by', {
        header: () => <span>Uploaded By</span>,
        cell: info => info.getValue() || <Text color={AppColors.gray}>-</Text>,
      }),
      columnHelper.accessor('upload_date', {
        header: () => <span>Upload Date</span>,
        cell: info => {
          const value = info.getValue();
          return value ? format(new Date(value), 'MM/dd/yyyy') : <Text color={AppColors.gray}>N/A</Text>;
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: info => {
          const cellValue = info.getValue();
          return !cellValue ? <Text color={AppColors.gray}>N/A</Text> : STATUS[cellValue];
        },
      }),
      ...selectedColumns.map(column =>
        columnHelper.accessor(column.accessor, {
          header: () => <span>{column.label}</span>,
          cell: info => {
            const value = info.getValue();
            return value ? Number(value).toFixed(1) : <Text color={AppColors.gray}>-</Text>;
          },
        })
      ),
      columnHelper.accessor('chart_id', {
        id: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => (
          <HStack justifyContent={'center'}>
            <PrimaryButton
              bg={AppColors.secondary}
              fontWeight="normal"
              size="sm"
              borderRadius="3xl"
              px="7"
              _hover={{ transition: 'all .3s ease', bgColor: AppColors.white, color: AppColors.secondary, outline: `2px solid ${AppColors.secondary}` }}
              onClick={() => handleAuditSheetView(info.getValue())}
            >
              View
            </PrimaryButton>
          </HStack>
        ),
      }),
    ];
  }, [selectedTab]);

  const filteredData = useMemo(() => {
    const rvuResults = uploadedChartsActivityRvu?.results || [];
    const upcodedResults = uploadedChartsActivityUpcoded?.results || [];
    const downcodedResults = uploadedChartsActivityDowncoded?.results || [];
    const modifierResults = uploadedChartsActivityModifier?.results || [];

    const combinedResults = selectedTab === 0 ? rvuResults :
      selectedTab === 1 ? upcodedResults :
        selectedTab === 2 ? downcodedResults :
          selectedTab === 3 ? modifierResults : [];

    return combinedResults.filter(row => row.metric && row.metric !== 0);
  }, [uploadedChartsActivityRvu, uploadedChartsActivityUpcoded, uploadedChartsActivityDowncoded, uploadedChartsActivityModifier, selectedTab]);


  const handlePreviousPage = () => {
    if (selectedTab === 0) {
      getChartActivityRvu.loadPrevious('View Chart Activity', 'rvu');
    } else if (selectedTab === 1) {
      getChartActivityUpcoded.loadPrevious('View Chart Activity', 'upcoded');
    } else if (selectedTab === 2) {
      getChartActivityDowncoded.loadPrevious('View Chart Activity', 'downcoded');
    } else if (selectedTab === 3) {
      getChartActivityModifier.loadPrevious('View Chart Activity', 'modifier');
    }
  };


  const handleNextPage = () => {
    if (selectedTab === 0) {
      getChartActivityRvu.loadNext('View Chart Activity', 'rvu');
    } else if (selectedTab === 1) {
      getChartActivityUpcoded.loadNext('View Chart Activity', 'upcoded');
    } else if (selectedTab === 2) {
      getChartActivityDowncoded.loadNext('View Chart Activity', 'downcoded');
    } else if (selectedTab === 3) {
      getChartActivityModifier.loadNext('View Chart Activity', 'modifier');
    }
  };


  const handleSizeChange = size => {
    if (selectedTab === 0) {
      getChartActivityRvu.loadSize('View Chart Activity', 'rvu', size);
    } else if (selectedTab === 1) {
      getChartActivityUpcoded.loadSize('View Chart Activity', 'upcoded', size);
    } else if (selectedTab === 2) {
      getChartActivityDowncoded.loadSize('View Chart Activity', 'downcoded', size);
    } else if (selectedTab === 3) {
      getChartActivityModifier.loadSize('View Chart Activity', 'modifier', size);
    }
  };

  const handlePageChangeByNumber = pageNumber => {
    if (selectedTab === 0) {
      getChartActivityRvu.loadPageByNumber('View Chart Activity', 'rvu', pageNumber);
    } else if (selectedTab === 1) {
      getChartActivityUpcoded.loadPageByNumber('View Chart Activity', 'upcoded', pageNumber);
    } else if (selectedTab === 2) {
      getChartActivityDowncoded.loadPageByNumber('View Chart Activity', 'downcoded', pageNumber);
    } else if (selectedTab === 3) {
      getChartActivityModifier.loadPageByNumber('View Chart Activity', 'modifier', pageNumber);
    }
  };

  const renderLoader = () => (
    <Stack p={4}>
      {Array.from({ length: 6 }, (_, i) => (
        <Skeleton key={i} w={`${(i + 4) * 10}%`} height="5px" />
      ))}
    </Stack>
  );

  return (
    <Box mt={3}>
      {selectedTab === 0 && isFetchingChartActivityRvu ? (
        renderLoader()
      ) : selectedTab === 1 && isFetchingChartActivityUpcoded ? (
        renderLoader()
      ) : selectedTab === 2 && isFetchingChartActivityDowncoded ? (
        renderLoader()
      ) : selectedTab === 3 && isFetchingChartActivityModifier ? (
        renderLoader()
      ) : (
        <CQReactTable
          isLoading={isAllChartLoading}
          data={filteredData || []}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(
            selectedTab === 0
              ? getChartActivityRvu
              : selectedTab === 1
                ? getChartActivityUpcoded
                : selectedTab === 2
                  ? getChartActivityDowncoded
                  : selectedTab === 3
                    ? getChartActivityModifier
                    : {}
          )}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onPageChangeByNumber={handlePageChangeByNumber}
          onAsc={(header) => setOrdering(header)}
          onDesc={(header) => setOrdering(`-${header}`)}
          getRowProps={(row) => ({
            style: { height: '45px' },
          })}
        />
      )}
    </Box>
  );
};

export default ChartActivityTable;