/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Switch,
  useBoolean,
} from '@chakra-ui/react';
import { add } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DashBoardHeader from '../../../components/dashboard_components/DashBoardHeader';
import CQContainer from '../../../components/layout/CQContainer';
import { CUSTOM } from '../../../constants/constants';
import ROUTES from '../../../constants/Routes';
import { withProvider } from '../../../hoc/withProvider';
import PhysicianProvider, {
  PhysicianContext,
} from '../../../providers/PhysicianProvider';
import {
  dateFormat,
  replaceRoute,
} from '../../../utils/common.utils';
import TilePopup from '../components/TilePopup';
import PrvuOpporturnity from './sections/PrvuOpporturnity';
import PAuditChartAccuracy from './sections/PAuditChartAccuracy';
import PCQScoreChart from './sections/PCQScoreChart';
import PhysicianChartAccuracy from './sections/PhysicianChartAccuracy';
import PhysicianProfileSection from './sections/PhysicianProfileSection';
import PRiskMonitor from './sections/PRiskMonitor';
import PTotalUploadsChart from './sections/PTotalUploadsChart';
import CustomIcon from '../../audit_sheet/components/CustomIcon';
import EducationClassTable from './sections/EducationClassTable';
import { AuthContext } from '../../../providers/AuthProvider';
import { AccessDenied } from '../components/AccessDenied';

const PHYSICIAN_CQ_SCORE = 'PHYSICIAN_CQ_SCORE';
const PHYSICIAN_CQ_AI_RVU_OPPORTURINITY = 'PHYSICIAN_CQ_AI_RVU_OPPORTURINITY';
const PHYSICIAN_TOTAL_UPLOAD = 'PHYSICIAN_TOTAL_UPLOAD';
const PHYSICIAN_CQ_AI_RISK_OPPORTURINITY = 'PHYSICIAN_CQ_AI_RISK_OPPORTURINITY';

function PhysicianDashboardcotainer() {
  const physicianContext = useContext(PhysicianContext);
  const { departmentId, hospitalId } = useParams();
  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = React.useState(null);
  const [practiceChartData, setPracticeChartData] = useState({});
  const [stateChartData, setStateChartData] = useState({});
  const [auditChartData, setAuditChartData] = useState({});
  const [checked, setChecked] = useState('');
  const oldFilter = JSON.parse(localStorage.getItem('selectedFilter'));
  const [filter, setFilter] = React.useState(oldFilter ? oldFilter : 'month');
  let backRoute = ROUTES.DASHBOARD;
  const { physicianId } = useParams();
  const authContext = useContext(AuthContext);
  const isDepartmentUser = authContext?.currentUser?.user_type !== 'DEPARTMENT';

  if (hospitalId && departmentId && isDepartmentUser ) {
    backRoute = replaceRoute(ROUTES.DEPARTMENT_DASHBOARD, {
      departmentId,
      hospitalId,
    });
  }
  if (!hospitalId && departmentId && isDepartmentUser ) {
    backRoute = replaceRoute(ROUTES.HOSPITAL_DEPARTMENT_DASHBOARD, {
      departmentId,
    });
  }

  useEffect(() => {
    physicianContext.getPhysician({provider_id : physicianId});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const yesterday = add(new Date(), {
    days: -1,
  });
  const [fromDate, setFromDate] = React.useState({
    start_date: add(yesterday, {
      days: -29,
    }),
    end_date: yesterday,
    previous_start_date: add(yesterday, {
      days: -59,
    }),
    previous_end_date: add(yesterday, {
      days: -30,
    }),
  });

  useEffect(() => {
    if (!physicianContext.isAccessDenied && oldFilter !== filter) {
      localStorage.setItem('selectedFilter', JSON.stringify(filter));
      if (filter === 'custom') {
        const { start_date, end_date, previous_start_date, previous_end_date } =
          fromDate;
        localStorage.setItem(
          'customDateFilter',
          JSON.stringify({
            start_date,
            end_date,
            previous_start_date,
            previous_end_date,
          })
        );
      } else {
        localStorage.removeItem('customDateFilter');
      }
    }
    if (filter?.toLowerCase() !== 'custom') {
      physicianContext.setShowComparison(false);
    }
  }, [filter, physicianContext.isAccessDenied]);

  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const getFilter = () => {
    const filterParams = {};
    filterParams.filter = filter.toLowerCase();

    if (filter === CUSTOM.toLowerCase()) {
      setCustomDates(filterParams);
      // setFilters(
      //   filterParams,
      //   dateFormat(fromDate.previous_start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.previous_end_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.start_date, 'yyyy-MM-dd'),
      //   dateFormat(fromDate.end_date, 'yyyy-MM-dd')
      // );
      delete filterParams.filter;
    }
    filterParams.provider_id = physicianId;
    return filterParams;
  };
  const fetchRUVOpportunity = async () => {
    if (physicianContext.rvuOppFilter['rvu_opportunity_type']) {
      const filterParams = getFilter();
      physicianContext.getRVUOpp(filterParams);
    }
  };

  const fetchAIRisk = async () => {
    const filterParams = getFilter();
    physicianContext.getAIRisk(filterParams);
  };

  useEffect(() => {
    if(!physicianContext.isAccessDenied){
      fetchRUVOpportunity();
    }
  }, [physicianContext.rvuOppFilter, fromDate, filter, physicianContext.isAccessDenied]);

  React.useEffect(() => {
    if(!physicianContext.isAccessDenied){
      fetchAIRisk();
      const filterParams = getFilter();
      physicianContext.getRVUOppDropdown(filterParams);
    }
  }, [fromDate, filter, physicianContext.isAccessDenied]);

  useEffect(() => {
    if (!physicianContext.isAccessDenied && physicianContext.dropdown.length) {
      setChecked(physicianContext.dropdown[0]['value']);
      physicianContext.setRVUOppFilter({
        rvu_opportunity_type: physicianContext.dropdown[0]['value'],
      });
    }
  }, [physicianContext.dropdown, physicianContext.isAccessDenied]);

  useEffect(() => {
    const option = physicianContext.rvuOppFilter['rvu_opportunity_type'];
    if (
      !physicianContext.isAccessDenied &&
      physicianContext.rvuOpp &&
      Object.keys(physicianContext.rvuOpp).length
    ) {
      const data = physicianContext.rvuOpp[option];
      let practiceChartData = { label: [], data: [] };
      if (data?.practice_distribution) {
        practiceChartData.label = Object.keys(data.practice_distribution);
        practiceChartData.data = Object.keys(data.practice_distribution).map(
          d => data.practice_distribution[d]
        );
      }
      let stateChartData = { label: [], data: [] };
      if (data?.state_distribution) {
        stateChartData.label = Object.keys(data.state_distribution);
        stateChartData.data = Object.keys(data.state_distribution).map(
          d => data.state_distribution[d]
        );
      }
      let auditChartData = { label: [], data: [] };
      if (data?.revintegrity_distribution) {
        auditChartData.label = Object.keys(data.revintegrity_distribution);
        auditChartData.data = Object.keys(data.revintegrity_distribution).map(
          d => data.revintegrity_distribution[d]
        );
      }
      setPracticeChartData(practiceChartData);
      setStateChartData(stateChartData);
      setAuditChartData(auditChartData);
    }
  }, [physicianContext.rvuOpp, physicianContext.isAccessDenied]);

  const radioClick = option => {
    setChecked(option);
    physicianContext.setRVUOppFilter(prev => ({
      ...prev,
      rvu_opportunity_type: option,
    }));
  };

  const renderHeaderComponent = () => {
    return (
      <DashBoardHeader
        isFetchingHospital={physicianContext.isGettingphysician}
        titleName={'Provider Chart Analytics'}
        setFromDate={setFromDate}
        fromDate={fromDate}
        navigationRoute={backRoute}
        setFilter={setFilter}
        filter={filter}
        objectWithDate={physicianContext.cqScore}
      />
    );
  };

  const physicianCqScoreChart = () => {
    return (
      <PCQScoreChart
        defaultStartDate={fromDate.previous_start_date}
        defaultEndDate={fromDate.previous_end_date}
        startDate={fromDate.start_date}
        endDate={fromDate.end_date}
        filter={filter}
        selectedDates={selectedDates}
      />
    );
  };
  const physicianRVUopportunity = () => {
    return (
      <PrvuOpporturnity
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
        isTileModalOpen={isTileModalOpen}
        practiceChartData={practiceChartData}
        stateChartData={stateChartData}
        auditChartData={auditChartData}
        radioClick={radioClick}
        checked={checked}
        dropdown={physicianContext.dropdown}
        selectedDates={selectedDates}
      />
    );
  };
  const physicianTotalUploadsChart = () => {
    return (
      <PTotalUploadsChart
        defaultStartDate={fromDate.previous_start_date}
        defaultEndDate={fromDate.previous_end_date}
        startDate={fromDate.start_date}
        endDate={fromDate.end_date}
        filter={filter}
        selectedDates={selectedDates}
      />
    );
  };

  // const renderFirstRowcomponents = () => {
  //   return (
  //     <Box>
  //       <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={4}>
  //       <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
  //           <PhysicianProfileSection />
  //         </GridItem>
  //         <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
  //           <PhysicianChartAccuracy
  //             defaultStartDate={fromDate.end_date}
  //             defaultEndDate={fromDate.start_date}
  //             startDate={fromDate.previous_start_date}
  //             endDate={fromDate.previous_end_date}
  //             filter={filter}
  //           />
  //         </GridItem>
  //         <GridItem
  //           cursor={'pointer'}
  //           onClick={() => {
  //             setCurrentTile(PHYSICIAN_CQ_SCORE);
  //             itmState.on();
  //           }}
  //           bgColor={'#fff'}
  //           borderRadius={'lg'}
  //           shadow={'md'}
  //         >
  //           {physicianCqScoreChart()}
  //         </GridItem>
  //       </Grid>
  //     </Box>
  //   );
  // };

  const renderAIRisk = () => {
    return (
      <PRiskMonitor
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
        isTileModalOpen={isTileModalOpen}
        selectedDates={selectedDates}
      />
    );
  };

  const renderSecondRowComponent = () => {
    return (
      <Grid
        // templateColumns="repeat(3, 1fr)"
        templateColumns={{
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        mt={4}
        gap={4}
      >
        <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
          <PhysicianProfileSection />
        </GridItem>
        <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
          <PhysicianChartAccuracy
            defaultStartDate={fromDate.end_date}
            defaultEndDate={fromDate.start_date}
            startDate={fromDate.previous_start_date}
            endDate={fromDate.previous_end_date}
            filter={filter}
            selectedDates={selectedDates}
          />

          {/* completed */}
        </GridItem>
        <GridItem
          cursor={'pointer'}
          onClick={() => {
            setCurrentTile(PHYSICIAN_CQ_SCORE);
            itmState.on();
          }}
          bgColor={'#fff'}
          borderRadius={'lg'}
          shadow={'md'}
        >
          {physicianCqScoreChart()}
          {/* completed */}
        </GridItem>
        <GridItem
          cursor={'pointer'}
          onClick={e => {
            if (e.target.name !== 'data') {
              setCurrentTile(PHYSICIAN_CQ_AI_RVU_OPPORTURINITY);
              itmState.on();
            }
          }}
          bgColor={'#fff'}
          borderRadius={'lg'}
          shadow={'md'}
        >
          {physicianRVUopportunity()}
          {/* completed */}
        </GridItem>
        <GridItem
          cursor={'pointer'}
          onClick={() => {
            setCurrentTile(PHYSICIAN_TOTAL_UPLOAD);
            itmState.on();
          }}
          bgColor={'#fff'}
          borderRadius={'lg'}
          shadow={'md'}
        >
          {physicianTotalUploadsChart()}
        </GridItem>
        <GridItem
          bgColor={'#fff'}
          borderRadius={'lg'}
          shadow={'md'}
          cursor={'pointer'}
          onClick={e => {
            if (e.target.name !== 'aiData') {
              setCurrentTile(PHYSICIAN_CQ_AI_RISK_OPPORTURINITY);
              itmState.on();
            }
          }}
        >
          {renderAIRisk()}
        </GridItem>
      </Grid>
    );
  };

  const renderAuditChartAccuracyTAble = () => {
    return (
      <PAuditChartAccuracy
        defaultStartDate={fromDate.end_date}
        defaultEndDate={fromDate.start_date}
        startDate={fromDate.previous_start_date}
        endDate={fromDate.previous_end_date}
        filter={filter}
        selectedDates={selectedDates}
      />
    );
  };

  const TILE_MODAL_BODY = {
    PHYSICIAN_CQ_SCORE: physicianCqScoreChart,
    PHYSICIAN_CQ_AI_RVU_OPPORTURINITY: physicianRVUopportunity,
    PHYSICIAN_TOTAL_UPLOAD: physicianTotalUploadsChart,
    PHYSICIAN_CQ_AI_RISK_OPPORTURINITY: renderAIRisk,
  };

  const renderGraphModal = () => {
    return (
      <TilePopup
        renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
        isOpen={isTileModalOpen}
        onClose={() => {
          itmState.off();
        }}
      />
    );
  };

  const renderEducationClass = () => {
    return <EducationClassTable />;
  };

  return (
    <CQContainer>
      <AccessDenied 
        loading={physicianContext.isGettingphysician} 
        accessDenied={physicianContext.isAccessDenied}
      >
        <Box width={'100%'}>
          {renderGraphModal()}
          {renderHeaderComponent()}
          <HStack justifyContent={'end'} my={2} alignItems="center">
            <Switch
              icon={<CustomIcon />}
              mb={5}
              mr={1}
              id="show-comparison"
              isChecked={physicianContext.showComparison}
              onChange={async e => {
                physicianContext.setShowComparison(e.target.checked);
              }}
              border={'black'}
              colorScheme="secondary"
              isDisabled={filter?.toLowerCase() === 'all'}
            >
              Show Comparison Data
            </Switch>
          </HStack>
          {/* {renderFirstRowcomponents()} */}
          {renderSecondRowComponent()}
          {renderAuditChartAccuracyTAble()}
          {renderEducationClass()}
        </Box>
      </AccessDenied>
    </CQContainer>
  );
}

export default withProvider(PhysicianProvider, PhysicianDashboardcotainer);
