/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
// import { AccountContext } from '../../providers/AccountsProvider';
import { MyAccountContext } from '../../../providers/MyAccountProvider';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ButtonGroup,
  Circle,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useEditableControls,
} from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import AppColors from '../../../constants/AppColors';
import SelectFormModal from '../../../components/select_form/SelectFormModal';
import {
  CirclePlusIcon,
  EditIcon,
  LinkIcon,
} from '../../../constants/IconData';
import { sendMail } from '../../../utils/send_mail_utils';
import { separateIdNames } from '../../../utils/separateIdNames';
import AddHospitalForm from './forms/AddHospitalForm';
import AddDepartmentForm from './forms/AddDepartmentForm';
import { FONT_FAMILY } from '../../../constants/Theme';
import EditAccountsModal from '../my_accounts/EditAccountsModal';
import FormMultiSelectModal from '../../../components/form/FormMultiSelectModal';
import StateDropDown from './forms/StateDropDown';
import { STATES } from '../../../constants/constants';
import EditProviderAccount from '../my_accounts/EditProviderAccount';
import CompliancePlan from './CompliancePlan';

function EditPracticeInfo({ profileData, id, accountId, user, status }) {
  const [editingKey, setEditingKey] = useState(null);
  const [healthSystemData, setHealthSystemData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const myAccountContext = useContext(MyAccountContext);

  const departmentData =
    !!profileData.department?.length &&
    profileData.department.map(hd => {
      // if (!!hd?.department?.length) {
      //   hd?.department.forEach(dd => {
      // departmentData.push(hd.name);

      //   });
      // }
      return hd.name;
    });

  const [departmentList, setDepartmentList] = useState([]);
  const [allDepartmentData, setAllDepartmentData] = useState(departmentData);
  const [hospitalList, setHospitalList] = useState(profileData?.hospitals);
  const [hospitalFormVisible, hfvState] = useBoolean(false);
  const [DepartmentFormVisible, dfvState] = useBoolean(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useBoolean(false);
  const [accountDataForModal, adfmState] = useState(profileData.account_contact);
  const [isProviderData, ipdState] = useState(false);
  const [goalLimit, setGoalLimit] = useState([
    profileData?.bottom_parameter,
    profileData?.goal,
    profileData?.top_parameter,
  ]);
  const [chartsCountData, setChartsCountData] = useState(profileData?.charts_count);
  const [primaryAccountList, setPrimaryAccountList] = useState();
  const [allAccountList, setAllAccountList] = useState(
    profileData?.account_contact
  );
  const [allProviderList, setAllProviderList] = useState(
    profileData?.providers
  );
  const [selectedHospitalId, setSelectedHospitalId] = useState();
  const [defaultCheckedAccountValues, setDefaultCheckedAccountValues] =
    useState();
  const [goalLimitError, setGoalLimitError] = useState(false);
  const [chartsCountError, setChartsCountError] = useState(false);
  const [isPAccountsModalOpen, ipamState] = useBoolean(false);
  const [providerAccountData, setProviderAccountData] = useState([])

  const accountsContext = useContext(MyAccountContext);

  //   schema
  const schema = yup.object({
    state: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required('State is a required field')
      .nullable(),
    insurance: yup.array(),
    ehr: yup.array(),
  });

  function loadOptions() {
    accountsContext.fetchEHROptions();
    accountsContext.fetchInsuranceOptions();
  }

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (user === 'HEALTH SYSTEM' || user === 'PHYSICIANS GROUP') structingTheResponseToRender();
    primaryAccountListToRender();
  }, [profileData]);

  const structingTheResponseToRender = () => {
    let departments = [];
    profileData?.hospitals?.forEach(h => {
      h?.department?.forEach(d => {
        if (!departments.includes(d.name)) departments.push(d.name);
      });
    });
    profileData.all_department = departments;
    profileData.department = [];
    setAllDepartmentData([...departments]);
  };

  // useform
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insurance: [],
      ehr: [],
    },
  });

  const { control, handleSubmit, reset, formState: { errors } } = form;

  useEffect(() => {
    if (Object.keys(profileData).length) {
      // if data
      const ehr = profileData.ehr?.length ? profileData.ehr.map(e => e.id) : [];
      const insurance = profileData.insurance?.length
        ? profileData.insurance.map(e => e.id)
        : [];

      setHealthSystemData({
        ...profileData,
        ehr,
        insurance,
        // specialty,
        account_contact: profileData.account_contacts,
      });
      setUpdatedData({
        ...profileData,
        ehr,
        insurance,
        // specialty,
        // account_contact: profileData.account_contacts,
      });
      reset({
        ehr,
        insurance,
      });
    }
  }, [profileData]);

  // udated data
  const update = async (key, formData) => {
    if (goalLimitError || chartsCountError) return;
    let body = {
      name: updatedData.name,
      address: updatedData.address.split(",")[1],
      ehr: separateIdNames(updatedData.ehr, 'new_ehr_name'),
      insurance: separateIdNames(updatedData.insurance, 'new_ins_name'),
      bottom_parameter: goalLimit[0],
      goal: goalLimit[1],
      top_parameter: goalLimit[2],
      charts_count: chartsCountData,
    };

    if (key === 'ehr') {
      body[key] = separateIdNames(formData[key], 'new_ehr_name');
    } else if (key === 'insurance') {
      body[key] = separateIdNames(formData[key], 'new_ins_name');
    }

    if (user === 'HEALTH SYSTEM') {
      let healthSystemBody = {
        ...body,
        id: id,
      };

      await myAccountContext.updateHealthSystemPracticeInfo(healthSystemBody);
      // -----------------------------------------------------------------------------
    } else if (user === 'HOSPITAL') {
      let hospitalBody = {
        ...body,
        patients_per_month: updatedData.patients_per_month,
        id: id,
      };
      await myAccountContext.updateHospitalPracticeInfo(hospitalBody);

      // -----------------------------------------------------------------------------
    } else if (user === 'DEPARTMENT') {
      const departmentBody = {
        ...body,
        id: id,
        providers: updatedData.providers,
        patients_per_month: updatedData.patients_per_month,
      };

      await myAccountContext.updateDepartmentPracticeInfo(departmentBody);
      // -----------------------------------------------------------------------------
    } else if (user === 'PHYSICIANS GROUP') {
      const physicianGroupBody = { ...body, id: id };

      // Same API Endpoint for both Health System and Physician Group
      await myAccountContext.updateHealthSystemPracticeInfo(physicianGroupBody);
      // -----------------------------------------------------------------------------
    }
  };

  const updateHospitalDepartment = async (type, id) => {
    if (type === 'hospital') {
      await myAccountContext.deleteHospital(id);
    } else if (type === 'department') {
      await myAccountContext.deleteDepartment(id);
    }
  };

  const onUpdateData = key => {
    update(key);
    setHealthSystemData(prev => ({
      ...prev,
      [key]: updatedData[key],
    }));
    setEditingKey(null);
  };

  const onUndoUpdateData = key => {
    setUpdatedData(prev => ({
      ...prev,
      [key]: healthSystemData[key],
    }));
    setEditingKey(null);
  };

  const onMultiTextSubmit = (formData, key) => {
    update(key, formData);
    setHealthSystemData(prev => ({ ...prev, [key]: formData[key] }));
    setEditingKey(null);
  };

  const onEdit = key => {
    if (editingKey === key) {
      setEditingKey(null);
    } else {
      setEditingKey(key);
    }
  };

  const renderLeftData = (key, form) => {
    return (
      <ButtonGroup justifyContent="end" size="sm" spacing={2} mt={4}>
        <IconButton
          borderRadius={'full'}
          // p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CheckIcon color={'black'} boxSize={3} />}
          onClick={() => {
            if (form) {
              handleSubmit(onMultiTextSubmit)(key);
              setEditingKey(null);
            } else {
              onUpdateData(key);
            }
          }}
        />
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<SmallCloseIcon color={'black'} boxSize={3} />}
          onClick={() => {
            onUndoUpdateData(key);
          }}
        />
      </ButtonGroup>
    );
  };

  const renderDepartmentByHospital = hospitalId => {
    if (user === 'PROVIDER') return;
    profileData.hospitals?.forEach(h => {
      if (h.id === hospitalId) {
        setDepartmentList(h.department);
      }
    });
  };

  const renderDepartmentInfo = React.useMemo(
    () =>
      departmentList?.length === 0 ? (
        <Text>
          {allDepartmentData?.length === 0 ? (
            <Text>No departments for this hospital</Text>
          ) : (
            allDepartmentData &&
            allDepartmentData?.map((depart, i) => {
              return (
                <>
                  {depart}
                  {i !== allDepartmentData?.length - 1 ? ' | ' : ''}
                </>
              );
            })
          )}
        </Text>
      ) : departmentList ? (
        departmentList?.map(depart => (
          <HStack
            key={depart?.id}
            m={1}
            bg={`#F7F7FA`}
            borderRadius={'full'}
            px={5}
            py={2}
          >
            <Text>{depart?.name}</Text>
            {user !== 'PROVIDER' && (
              <SmallCloseIcon
                color={'gray'}
                onClick={async () => {
                  const updatedDepartment = departmentList.filter(
                    d => d.id !== depart.id
                  );
                  await setDepartmentList(updatedDepartment);
                  updateHospitalDepartment('department', depart?.id);
                }}
                cursor={'pointer'}
              />
            )}
          </HStack>
        ))
      ) : (
        <Text>No Departments for this Hospital</Text>
      ),
    [departmentList, allDepartmentData]
  );

  const renderFormComponent = (key, inputType) => {
    if (inputType === 'text') {
      return (
        <>
          <TextInput
            height={'50px'}
            name={key}
            id={{ key }}
            type={inputType}
            value={updatedData[key]}
            onChange={e => {
              setUpdatedData(prev => ({
                ...prev,
                [key]: e.target.value,
              }));
            }}
          />
          {renderLeftData(key)}
        </>
      );
    } else if (inputType === 'dropdown') {
      return (
        <>
          <StateDropDown
            value={STATES.find(state => state.label === updatedData.address)}
            onChange={e => {
              setUpdatedData(prev => ({
                ...prev,
                [key]: e.label,
              }));
            }}
            control={control}
            errors={errors}
          />
          {renderLeftData(key)}
        </>
      );
    }
  };


  // flexJoint
  const renderTextData = (key, inputType) => {
    return editingKey === key ? (
      renderFormComponent(key, inputType)
    ) : (
      <>
        <Text color={AppColors.black} fontSize={'md'}>
          {healthSystemData[key]}
          {key === 'hospital_info' && (
            <HStack
              flexWrap={'wrap'}
              gap={2}
              fontFamily={FONT_FAMILY.ptSans}
              fontSize={'md'}
            >
              {hospitalList?.length ? (
                hospitalList?.map(hospital => (
                  <HStack
                    key={hospital?.id}
                    m={1}
                    bg={`#F7F7FA`}
                    borderRadius={'full'}
                    px={5}
                    py={2}
                    onClick={() => setSelectedHospitalId(hospital?.id)}
                    border={
                      selectedHospitalId === hospital?.id
                        ? `2px solid ${AppColors.primary}`
                        : '2px solid white'
                    }
                  >
                    <Text
                      onClick={() => renderDepartmentByHospital(hospital?.id)}
                      cursor={'pointer'}
                    >
                      {hospital?.name}
                    </Text>
                    {user !== 'PROVIDER' && (
                      <SmallCloseIcon
                        color={'gray'}
                        onClick={async () => {
                          const updatedHospital = hospitalList.filter(
                            h => h.id !== hospital?.id
                          );
                          await setHospitalList(updatedHospital);
                          updateHospitalDepartment('hospital', hospital?.id);
                        }}
                        cursor={'pointer'}
                        _hover={{
                          color: 'red',
                          border: '1px solid red',
                          borderRadius: 'full',
                        }}
                      />
                    )}
                  </HStack>
                ))
              ) : (
                <Text>N/A</Text>
              )}
            </HStack>
          )}
          {key === 'department_info' && (
            <HStack
              flexWrap={'wrap'}
              gap={2}
              fontFamily={FONT_FAMILY.ptSans}
              fontSize={'md'}
            >
              {renderDepartmentInfo}
            </HStack>
          )}
        </Text>
      </>
    );
  };

  const renderOnlyText = (key, inputText) => {
    return (
      <Text width={''} name={key} id={{ key }} fontSize={'md'} mt={2}>
        {inputText}
      </Text>
    );
  };

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        subTitle={'Select Insurance'}
        addNewText={'Add Other Insurance'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  const renderMultiForm = key => {
    const title = {
      ehr: {
        option: 'ehrOptions',
        form: renderEHRFormModal,
        placeHolder: "Select EHR"
        // setSateFunc: setEhr,
      },
      insurance: {
        option: 'insuranceOptions',
        form: renderInsuranceForModal,
        placeHolder: "Select Insurance"
        // setSateFunc: setInsurance,
      },
    };
    const options = accountsContext[title[key]['option']];
    const placeHolder = title[key]['placeHolder']
    return editingKey === key ? (
      // <HStack>
      <>
        <Box>
          <FormMultiSelectModal
            control={control}
            name={key}
            placeholder={placeHolder}
            options={options}
            renderFormModal={title[key]['form']}
            setData={title[key]['setSateFunc']}
          />
        </Box>
        <Box pb={2}>{renderLeftData(key, true)} </Box>
      </>
    ) : (
      // </HStack>
      <Stack>
        <Text mt={2} color={AppColors.black} fontSize={'md'}>
          {healthSystemData[key]?.length
            ? healthSystemData[key]?.map((id, index) => {
              if (options.find(d => d.value === id)?.label) {
                return `${options.find(d => d.value === id)?.label} ${index < healthSystemData[key].length - 1 ? ' | ' : ''
                  }`;
              }
            })
            : 'N/A'}
        </Text>
      </Stack>
    );
  };

  const renderContactForm = key => {
    return allAccountList?.length ? (
      allAccountList
        ?.sort((prev, after) => after.is_approved - prev.is_approved)
        .map(account => {
          return (
            <HStack my={2} fontSize={'md'} key={account.id}>
              <Text
                color={account.is_approved ? AppColors.black : AppColors.gray}
              >
                {account.first_name} {account.last_name} |
              </Text>
              <Text
                color={account.is_approved ? AppColors.primary : AppColors.gray}
              >
                {account.email}
              </Text>
              <LinkIcon
                onClick={() => {
                  sendMail(account.email);
                }}
                style={{ height: 15, width: 15, cursor: 'pointer' }}
              />
            </HStack>
          );
        })
    ) : (
      <Text fontSize={'md'}>N/A</Text>
    );
  };

  const renderProviderForm = key => {
    return allProviderList?.length ? (
      allProviderList
        ?.sort((prev, after) => after.is_approved - prev.is_approved)
        .map(account => {
          return (
            <HStack my={2} fontSize={'md'} key={account.id}>
              <Text
                color={account.is_approved ? AppColors.black : AppColors.gray}
              >
                {account.first_name} {account.last_name} |
              </Text>
              <Text
                color={account.is_approved ? AppColors.primary : AppColors.gray}
              >
                {account.email}
              </Text>
              <LinkIcon
                onClick={() => {
                  sendMail(account.email);
                }}
                style={{ height: 15, width: 15, cursor: 'pointer' }}
              />
            </HStack>
          );
        })
    ) : (
      <Text fontSize={'md'}>N/A</Text>
    );
  };

  const renderAccounts = key => {
    return (
      <Box
        mt={1}
        p={3}
        justifyContent={'space-between'}
        width={'100%'}
        m={'auto'}
        my={4}
        fontSize={'xl'}
        bgColor={AppColors.white}
        borderRadius={'lg'}
        boxShadow={'md'}
      >
        <Flex justifyContent={'space-between'} pr={2}>
          <Text
            color={AppColors.secondary}
            fontSize={'md'}
            fontWeight="bold"
            pb={3}
          >
            {key === 'account_contact' ? 'Account Contact' : 'Primary Account'}
          </Text>
          {key === 'account_contact' && (
            <Circle
              onClick={() => openForm('account_contact')}
              cursor={'pointer'}
              mr={-1}
            >
              <CirclePlusIcon />
            </Circle>
          )}
        </Flex>
        <Box>
          {key === 'account_contact'
            ? renderContactForm(key)
            : renderPrimaryAccount(key)}
        </Box>
      </Box>
    );
  };

  const primaryAccountListToRender = () => {
    const primaryContact = profileData?.account_contact?.filter(acc => {
      return acc.is_primary;
    });
    setPrimaryAccountList(primaryContact);
  };

  const renderPrimaryAccount = () => {
    return primaryAccountList?.length ? (
      primaryAccountList
        .sort((prev, after) => after.is_approved - prev.is_approved)
        .map(account => {
          return (
            <HStack my={2} fontSize={'md'} key={account.id}>
              <Text
                color={account.is_approved ? AppColors.black : AppColors.gray}
              >
                {account.first_name} {account.last_name} |
              </Text>
              <Text
                color={account.is_approved ? AppColors.primary : AppColors.gray}
              >
                {account.email}
              </Text>
            </HStack>
          );
        })
    ) : (
      <Text fontSize={'md'}>N/A</Text>
    );
  };

  const dataStructure = [];

  for (let key in { ...profileData, department: departmentData }) {
    if (key === 'name') {
      let tempObj = {
        heading: 'Name',
        label: 'name',
        type: 'text',
        component: 'text',
      };
      dataStructure.push(tempObj);
    } else if (key === 'address') {
      let tempObj = {
        heading: 'State',
        label: 'address',
        type: 'dropdown',
        component: 'stateropdown',
      };
      dataStructure.push(tempObj);
    } else if (key === 'insurance') {
      let tempObj = {
        heading: 'Insurance',
        label: 'insurance',
        type: 'multi',
        component: 'modal',
      };
      dataStructure.push(tempObj);
    } else if (key === 'ehr') {
      let tempObj = {
        heading: 'EHR',
        label: 'ehr',
        type: 'multi',
        component: 'modal',
      };
      dataStructure.push(tempObj);
    } else if (key === 'hospitals') {
      let tempObj = {
        heading: 'Hospital Info',
        label: 'hospital_info',
        type: 'text',
        component: 'hospital_modal',
      };
      dataStructure.push(tempObj);
    } else if (key === 'department' && user !== 'DEPARTMENT') {
      let tempObj = {
        heading: 'Department Info',
        label: 'department_info',
        type: 'text',
        component: 'department_modal',
      };
      dataStructure.push(tempObj);
    } else if (key === 'patients_per_month') {
      let tempObj = {
        heading: 'No. of Patients/Month',
        label: 'patients_per_month',
        type: 'text',
        component: 'text',
      };
      dataStructure.push(tempObj);
    } else if (key === 'providers') {
      //   -------------------------------------------------- Provider
      let tempObj = {
        heading: 'Providers',
        label: 'provider',
        type: 'text',
        component: 'provider_modal',
      };
      dataStructure.push(tempObj);
    } else if (key === 'health_system') {
      let tempObj = {
        heading: 'Health System Name',
        label: 'health_system_name',
        type: 'text',
        component: 'health_system_name',
      };
      dataStructure.push(tempObj);
    } else if (key === 'hospitals_name') {
      let tempObj = {
        heading: 'Hospital Name',
        label: 'hospital_name',
        type: 'text',
        component: 'hospital_name',
      };
      dataStructure.push(tempObj);
    } else if (key === 'provider_health_system_name') {
      let tempObj = {
        heading: 'Health System Info',
        label: 'provider_health_system_name',
        type: 'text',
        component: 'text',
      };
      dataStructure.push(tempObj);
    }
  }

  const openForm = label => {
    if (label === 'hospital_info') {
      hfvState.on();
    } else if (label === 'department_info') {
      dfvState.on();
    } else if (label === 'provider') {
      // adfmState(profileData.providers);
      // ipdState(true);
      // setIsAccountModalOpen.on();
      setProviderAccountData(profileData.providers);
      ipamState.on();
    } else if (label === 'account_contact') {
      adfmState(profileData.account_contact);
      setIsAccountModalOpen.on();
    }
  };

  const renderLeftDataColumn = () => {
    return dataStructure.map(({ heading, label, type, component }) => {
      return (
        <Stack
          key={`${label}-component`}
          mt={1}
          p={3}
          justifyContent={'space-between'}
          width={'100%'}
          m={'auto'}
          my={4}
          fontSize={'xl'}
          bgColor={AppColors.white}
          borderRadius={'lg'}
          boxShadow={'md'}
        >
          <Flex justifyContent={'space-between'}>
            <Box w={'100%'}>
              <Flex justifyContent={'space-between'}>
                <Text
                  color={AppColors.secondary}
                  fontSize={'md'}
                  fontWeight="bold"
                  pb={3}
                >
                  {label !== 'name'
                    ? heading
                    : status
                      ? heading + ' (Active)'
                      : heading + ' (Inactive)'}
                </Text>
                <Box>
                  {user !== 'PROVIDER' &&
                    status &&
                    editingKey !== label &&
                    label !== 'hospital_name' &&
                    label !== 'health_system_name' ? (
                    label === 'hospital_info' ||
                      label === 'department_info' ||
                      label === 'account_contact' ? (
                      <Circle
                        cursor={'pointer'}
                        mr={1}
                        onClick={() => openForm(label)}
                      >
                        <CirclePlusIcon size={'md'} />
                      </Circle>
                    ) : label === 'name' && user === 'DEPARTMENT' ? (
                      <></>
                    ) : (
                      <Circle size="40px" bg={'#EBEBEE'} cursor={'pointer'}>
                        <EditIcon
                          onClick={() => {
                            label === 'provider'
                              ? openForm(label)
                              : onEdit(label);
                          }}
                        />
                      </Circle>
                    )
                  ) : null}
                </Box>
              </Flex>
              <HStack justifyContent={'space-between'}>
                {component === 'health_system_name' &&
                  renderOnlyText(label, profileData?.health_system?.name)}
                {component === 'hospital_name' &&
                  renderOnlyText(label, profileData?.hospitals_name?.name)}
                {component === 'provider_modal' && (
                  <Box>{renderProviderForm('providers')}</Box>
                )}
                {component === 'modal'
                  ? renderMultiForm(label)
                  : renderTextData(label, type)}
              </HStack>
            </Box>
          </Flex>
        </Stack>
      );
    });
  };

  const renderLoader = () => {
    return (
      <>
        <Stack>
          <Skeleton h={20} />
          <Skeleton h={20} />
          <Skeleton h={20} />
          <Skeleton h={20} />
          <Skeleton h={20} />
        </Stack>
      </>
    );
  };

  const handleGoalLimit = e => {
    let value = e.target.value;
    if (value.length > 5) return;
    if (e.target.value > 100) setGoalLimit([goalLimit[0], +100, goalLimit[2]]);
    else setGoalLimit([goalLimit[0], +value, goalLimit[2]]);

    if (e.target.value >= goalLimit[0] && e.target.value <= goalLimit[2])
      setGoalLimitError(false);
    else setGoalLimitError(true);
  };

  const handleChartCount = (e) => {
    let value = e.target.value;

    // Allow clearing the input
    if (value === "") {
      setChartsCountData("");
      setChartsCountError(true);
      return;
    }

    // Ensure the value is a number
    if (!isNaN(value) && value.trim() !== "") {
      const numberValue = Number(value);

      // Ensure the number is greater than 0 and not more than 100000
      if (Number.isInteger(numberValue) && numberValue > 0 && numberValue <= 100000) {
        setChartsCountData(numberValue);
        setChartsCountError(false);
      } else {
        setChartsCountError(true);
      }
    } else {
      setChartsCountError(true);
    }
  };

  const onUndoGoalUpdate = () => {
    setGoalLimit([goalLimit[0], healthSystemData.goal, goalLimit[2]]);
    setGoalLimitError(false);
  };

  const onUndoChartsUpdate = () => {
    setChartsCountData(healthSystemData.charts_count);
    setChartsCountError(false);
  };

  const goalRangeSlider = () => {
    return (
      <Box
        mt={1}
        p={3}
        justifyContent={'space-between'}
        width={'100%'}
        m={'auto'}
        my={4}
        fontSize={'xl'}
        bgColor={AppColors.white}
        borderRadius={'lg'}
        boxShadow={'md'}
      >
        <Stack direction="row">
          <Text
            color={AppColors.secondary}
            fontSize={'md'}
            fontWeight="bold"
            pb={3}
          >
            Goal
          </Text>
          <Tooltip
            label="Please a goal range"
            placement="top"
            hasArrow
            shouldWrapChildren
          >
            <Button size="lg" display="contents" />
          </Tooltip>
        </Stack>

        <RangeSlider
          defaultValue={goalLimit}
          size="lg"
          onChange={val => setGoalLimit(val)}
          onChangeEnd={val => {
            update();
          }}
          value={goalLimit}
          min={0}
          max={100}
          step={0.1}
          width={'96%'}
          height="50px"
        >
          <RangeSliderTrack
            height="20px"
            borderRadius={'full'}
            border="1px solid #1F2023"
          >
            <RangeSliderFilledTrack bg={AppColors.primary} />
          </RangeSliderTrack>

          <Tooltip
            borderRadius={'full'}
            label={goalLimit[0]}
            bg="white"
            border="1px solid gray"
            color="black"
            placement="bottom"
            pl={3}
            pr={3}
            isOpen={
              isAccountModalOpen || DepartmentFormVisible || hospitalFormVisible
                ? false
                : true
            }
          >
            <RangeSliderThumb
              index={0}
              w="25px"
              h="25px"
              borderColor="gray"
              border="1px solid"
              borderRadius={'full'}
              sx={{
                ':focus': {
                  boxShadow: '0 0 3px #fff',
                },
              }}
            ></RangeSliderThumb>
          </Tooltip>
          <Tooltip
            borderRadius={'full'}
            label={goalLimit[1]}
            bg={AppColors.secondary}
            border="1px solid gray"
            color="white"
            placement="bottom"
            pl={3}
            pr={3}
            isOpen={
              isAccountModalOpen || DepartmentFormVisible || hospitalFormVisible
                ? false
                : true
            }
          >
            <RangeSliderThumb
              index={1}
              boxShadow={'none'}
              bg={'none'}
              _focus={'none'}
            >
              {goalLimit[1] !== 0 && '|'}
            </RangeSliderThumb>
          </Tooltip>
          <Tooltip
            label={goalLimit[2]}
            bg="white"
            border="1px solid gray"
            color="black"
            placement="bottom"
            pl={3}
            pr={3}
            borderRadius={'full'}
            isOpen={
              isAccountModalOpen || DepartmentFormVisible || hospitalFormVisible
                ? false
                : true
            }
          >
            <RangeSliderThumb
              index={2}
              w="25px"
              h="25px"
              borderColor="gray"
              border="1px solid"
              borderRadius={'full'}
              sx={{
                ':focus': {
                  boxShadow: '0 0 3px #fff',
                },
              }}
            ></RangeSliderThumb>
          </Tooltip>
        </RangeSlider>
        <HStack mt={4} w={'100%'} fontSize={'md'}>
          <HStack mr={5}>
            <Text>Bottom Parameter : </Text>
            <Text color={AppColors.primary} fontWeight={'bold'}>
              {goalLimit[0] ? goalLimit[0] : '0'}
            </Text>
          </HStack>
          <HStack>
            <Text>Top Parameter :</Text>{' '}
            <Text color={AppColors.primary} fontWeight={'bold'}>
              {goalLimit[2] ? goalLimit[2] : '100'}
            </Text>
          </HStack>
        </HStack>

        <Box mt={3}>
          <Text
            color={AppColors.secondary}
            fontSize={'md'}
            fontWeight="bold"
            pb={0}
          >
            Goal Value
          </Text>
          <Editable
            width={'99%'}
            display={'flex'}
            justifyContent={'space-between'}
            value={goalLimit[1] || ''}
            placeholder={'Goal Value'}
            fontSize="lg"
            isPreviewFocusable={false}
            selectAllOnFocus={false}
          >
            <EditablePreview />

            <Input
              as={EditableInput}
              onBlur={false}
              type={'number'}
              onChange={handleGoalLimit}
              step={0.1}
              w={'50%'}
            />
            <EditableControls />
          </Editable>
          <Text color={'red'} fontSize={'md'}>
            {goalLimitError ? 'Please enter Goal value within the limit' : ''}
          </Text>
        </Box>
      </Box>
    );
  };

  const chartsCount = () => {
    return (
      <Box
        p={3}
        justifyContent={'space-between'}
        width={'100%'}
        m={'auto'}
        my={4}
        fontSize={'xl'}
        bgColor={AppColors.white}
        borderRadius={'lg'}
        boxShadow={'md'}
        mt={5}
      >
        <Box>
          <Text
            color={AppColors.secondary}
            fontSize={'md'}
            fontWeight="bold"
            pb={0}
          >
            Chart Count
          </Text>
          <Editable
            width={'99%'}
            display={'flex'}
            justifyContent={'space-between'}
            value={chartsCountData || ''}
            placeholder={'Chart Count'}
            fontSize="lg"
            isPreviewFocusable={false}
            selectAllOnFocus={false}
            mt={3}
          >
            <EditablePreview />

            <Input
              as={EditableInput}
              onBlur={false}
              type={'number'}
              onChange={handleChartCount}
              step={0.1}
              w={'50%'}
            />
            <EditableControlsChartsCount />
          </Editable>
          <Text color={'red'} fontSize={'md'} mt={2}>
            {chartsCountError ? '* Please enter a valid chart count (1 - 100,000).' : ''}
          </Text>
        </Box>
      </Box>
    );
  };

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <Circle onClick={update}>
          <IconButton
            icon={<CheckIcon />}
            borderRadius={'full'}
            {...getSubmitButtonProps()}
            disabled={goalLimitError}
          />
        </Circle>
        <Circle onClick={onUndoGoalUpdate}>
          <IconButton
            icon={<SmallCloseIcon />}
            borderRadius={'full'}
            {...getCancelButtonProps()}
          />
        </Circle>
      </ButtonGroup>
    ) : (
      <Circle
        size="40px"
        mt={-4}
        bg={'#EBEBEE'}
        cursor={'pointer'}
        {...getEditButtonProps()}
      >
        <EditIcon />
      </Circle>
    );
  }

  function EditableControlsChartsCount() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <Circle onClick={update}>
          <IconButton
            icon={<CheckIcon />}
            borderRadius={'full'}
            {...getSubmitButtonProps()}
            disabled={chartsCountError}
          />
        </Circle>
        <Circle onClick={onUndoChartsUpdate}>
          <IconButton
            icon={<SmallCloseIcon />}
            borderRadius={'full'}
            {...getCancelButtonProps()}
          />
        </Circle>
      </ButtonGroup>
    ) : (
      <Circle
        size="40px"
        mt={-4}
        bg={'#EBEBEE'}
        cursor={'pointer'}
        {...getEditButtonProps()}
      >
        <EditIcon />
      </Circle>
    );
  }

  return (
    <Box width={'100%'} mb={4} p={5} borderRadius={'lg'} mt={-6}>
      <SimpleGrid width={'75%'}
        m={'auto'}
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={10}
        fontSize={'xl'}>
        <GridItem>
          <Box width={'100%'} mb={4} p={5} borderRadius={'lg'}>
            {!profileData ? renderLoader() : renderLeftDataColumn()}
            {user !== 'PROVIDER' && (
              <>
                <Box>{renderAccounts('account_contact')}</Box>
                <Box>{renderAccounts('primary_contact')}</Box>
              </>
            )}
          </Box>
        </GridItem>
        <GridItem>
          {user !== 'PROVIDER' && (
            <>
              <CompliancePlan />
              <Box mt={4}>{chartsCount()}</Box>
              <Box mt={4}>{goalRangeSlider()}</Box>
            </>
          )}
        </GridItem>
      </SimpleGrid>
      <AddHospitalForm
        hospitalId={hospitalFormVisible}
        isOpen={hospitalFormVisible}
        onClose={hfvState.off}
        accountId={id}
        healthSystemName={profileData?.name}
        hospitalList={hospitalList}
        setHospitalList={setHospitalList}
      />
      <AddDepartmentForm
        isOpen={DepartmentFormVisible}
        onClose={dfvState.off}
        hospital={profileData?.hospitals}
        accountData={{
          ehr: profileData?.ehr,
          insurance: profileData?.insurance,
        }}
        departmentForHospital={profileData?.health_system ? true : false}
        setDepartmentList={setDepartmentList}
        departmentList={departmentList}
        hospitalId={{ value: profileData?.name, id: id }}
      />
      <EditAccountsModal
        isOpen={isAccountModalOpen}
        onClose={setIsAccountModalOpen.off}
        providers={isProviderData}
        accountData={accountDataForModal}
        setPrimaryAccountList={setPrimaryAccountList}
        setAllAccountList={setAllAccountList}
        setAllProviderList={() => { }}
        defaultCheckedAccountValues={defaultCheckedAccountValues}
        setDefaultCheckedAccountValues={setDefaultCheckedAccountValues}
        id={id}
        user={user}
      />
      <EditProviderAccount
        isOpen={isPAccountsModalOpen}
        onClose={ipamState.off}
        accountData={allProviderList}
        setAllProviderList={setAllProviderList}
      />
    </Box>
  );
}

export default React.memo(EditPracticeInfo);
