import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useMemo, useContext } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { FONT_FAMILY } from '../../constants/Theme';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './styles';
import {
  CrossIcon,
  GoogleDriveIcon,
  MsOneDriveIcon,
  PinIcon,
  RedCrossIcon,
  TickIcon,
  UploadIcon,
} from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import { withProvider } from '../../hoc/withProvider';
import UploadProvider, { UploadContext } from '../../providers/UploadProvider';
import { AuthContext } from '../../providers/AuthProvider';
import useDeviceScreen from '../../hooks/useDeviceScreen';
import { WarningIcon } from '@chakra-ui/icons';
import GoogleDriveUploader from './components/google_drive_uploader';

import OneDriveUpload from './components/one_drive_uploader';

const UploadContainer = props => {
  const {
    isSubmitting,
    uploadedCharts,
    setUploadedCharts,
    uploadCharts,
    deleteChart,
    submitUploadedCharts,
  } = useContext(UploadContext);

  const authContext = useContext(AuthContext);

  const canUpload = authContext.currentUser?.can_upload;

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'text/doc': ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.hl7'],
      },
      useFsAccessApi: false,
      onDrop: async acceptedFiles => {
        await uploadCharts(acceptedFiles, 0);
      },
      disabled: !canUpload,
    });

  const submitCharts = async () => {
    await submitUploadedCharts();
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleRemoveFile = async id => {
    await deleteChart(id);
  };

  const { isWeb } = useDeviceScreen();

  const handleRemoveState = index => {
    const data = uploadedCharts.filter((chart, i) => i !== index);
    setUploadedCharts([...data]);
  };

  // Function to check if any uploaded file name exceeds 200 characters
  const validateFileNameLength = () => {
    return uploadedCharts.some((chart) => chart.name.length > 200);
  };

  return (
    <>
      <CQContainer>
      <Box>
        <Center>
          <Text
            fontSize={'30px'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={600}
            color={AppColors.darkBlue}
          >
            Upload Charts
          </Text>
        </Center>

        <Center>
          <Flex
            borderRadius={12}
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            width={isWeb ? '60%' : '100%'}
            mt={8}
            bgColor={'#F8FAFC'}
            flexDirection={'column'}
            p={6}
          >
            {canUpload ? (
              <>
                {/* Tabs for switching between upload methods */}
                <Tabs variant="enclosed-colored" isFitted>
                  <TabList mb="1em">
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }}>
                      <UploadIcon style={{ marginRight: 5, width: 30, height: 30 }} /> Local
                    </Tab>
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }}>
                      <GoogleDriveIcon style={{ marginRight: 5, width: 30, height: 30 }} /> Google Drive
                    </Tab>
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }}>
                      <MsOneDriveIcon style={{ marginRight: 5, width: 30, height: 30 }} /> OneDrive
                    </Tab>
                  </TabList>

                  <TabPanels>
                    {/* Local Tab */}
                    <TabPanel>
                      <Flex
                        p={4}
                        border="2px dashed"
                        borderColor="#A0AEC0"
                        borderRadius="12px"
                        bgColor={'white'}
                        flexDirection="column"
                        justifyContent="center"
                        _hover={{ borderColor: '#48BB78', bgColor: '#E6FFFA' }}
                        style={{ cursor: 'pointer' }}
                        mb={4}
                      >
                        <Text fontSize="16px" fontWeight="bold" color="#2D3748" mb={4} textAlign="center">
                          Upload from Local
                        </Text>
                        <Flex {...getRootProps({})} flexDirection="column" alignItems="center">
                          <input {...getInputProps()} />
                          <UploadIcon style={{ width: 30, height: 30, color: '#48BB78' }} />
                          <Text fontSize="14px" color="#2D3748" mt={4}>
                            Drag and drop files here
                          </Text>
                          <Text fontSize="12px" color="#A0AEC0">
                            Supported formats: XLS, XLSX, PDF, DOC, DOCX, HL7
                          </Text>
                        </Flex>
                      </Flex>
                    </TabPanel>

                    {/* Google Drive Tab */}
                    <TabPanel>
                      <GoogleDriveUploader
                      uploadCharts={uploadCharts}
                      >
                        <Text fontSize="16px" fontWeight="bold" color="#2D3748" mb={4}>
                          Upload from Google Drive
                        </Text>
                        <GoogleDriveIcon />
                        <Text fontSize="12px" color="#A0AEC0" mt={4}>
                          Supported formats: XLS, XLSX, PDF, DOC, DOCX, HL7
                        </Text>
                      </GoogleDriveUploader>
                    </TabPanel>

                    {/* OneDrive Tab */}
                    <TabPanel>
                      <OneDriveUpload uploadCharts={uploadCharts} >
                        <Flex
                          p={4}
                          border="2px dashed"
                          borderColor="#A0AEC0"
                          borderRadius="12px"
                          bgColor={'white'}
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          _hover={{ borderColor: '#48BB78', bgColor: '#E6FFFA' }}
                          style={{ cursor: 'pointer' }}
                          mb={4}
                        >
                          <Text fontSize="16px" fontWeight="bold" color="#2D3748" mb={4}>
                            Upload from OneDrive
                          </Text>
                          <MsOneDriveIcon />
                          <Text fontSize="12px" color="#A0AEC0" mt={4}>
                            Supported formats: XLS, XLSX, PDF, DOC, DOCX, HL7
                          </Text>
                        </Flex>
                      </OneDriveUpload>
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                {/* Uploaded Charts List */}
                {uploadedCharts?.length ? (
                  <PerfectScrollbar style={{ maxHeight: '150px', marginTop: 20 }}>
                    {uploadedCharts.map((chart, index) => (
                      <Box key={index} mt={4}>
                        <HStack mb={2}>
                          <Text maxWidth="70%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                            {chart.name}
                          </Text>
                          <Spacer />
                          <Text>{chart.size}</Text>
                          {chart.uploaded && (
                            <>
                              <CrossIcon onClick={() => handleRemoveFile(chart.id)} style={{ cursor: 'pointer' }} />
                              {chart.name.length > 200 ? (
                                <WarningIcon color="red.500" boxSize={5} />
                              ) : (
                                <TickIcon style={{ width: 22, height: 22 }} />
                              )}
                            </>
                          )}
                          {chart.cancelled && (
                            <RedCrossIcon style={{ cursor: 'pointer' }} onClick={() => handleRemoveState(index)} />
                          )}
                        </HStack>
                        <Progress value={chart.value} size="xs" colorScheme={chart.cancelled ? 'red' : 'teal'} borderRadius={10} />
                      </Box>
                    ))}
                  </PerfectScrollbar>
                ) : null}

                {/* Submit Button */}
                {uploadedCharts?.length ? (
                  <Center mt={6}>
                    <Button
                      isLoading={isSubmitting}
                      onClick={submitCharts}
                      bg="#48BB78"
                      color="white"
                      borderRadius={20}
                      px={10}
                      _hover={{
                        bg: '#38A169',
                        color: "white",
                      }}
                      disabled={!uploadedCharts.every(chart => chart.uploaded) || validateFileNameLength()}
                    >
                      Submit
                    </Button>
                  </Center>
                ) : null}

                {validateFileNameLength() && (
                  <Center mt={4}>
                    <Text color="red.500" fontSize="sm">
                      * File name exceeds the 200-character limit.
                    </Text>
                  </Center>
                )}
              </>
            ) : (
              <Center mt={8}>
                <Text color="red.500" fontSize="lg">
                  You do not have permission to upload files.
                </Text>
              </Center>
            )}
          </Flex>
        </Center>
      </Box>

        {canUpload && (
          <HStack display={'flex'} justifyContent={'center'} mt={2}>
            <Text fontSize={'14px'} fontFamily={FONT_FAMILY.ptSans}>
              By uploading files, you agree to our{' '}
              <span
                style={{
                  fontSize: '14px',
                  fontFamily: FONT_FAMILY.ptSans,
                  fontWeight: 'bold',
                }}
              >
                Terms of Service
              </span>
            </Text>
          </HStack>
        )}
      </CQContainer>
    </>
  );
};

export default withProvider(UploadProvider, UploadContainer);
