import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import AppColors from '../../constants/AppColors';
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';
import PropTypes from 'prop-types';
import { convertToPositiveDollar } from '../../utils/common.utils';
import InfoTooltip from '../InfoTooltip';

function ChartAccuracyComponents({
  isFetching,
  cq_score,
  defaultStartDate,
  defaultEndDate,
  filter,
}) {
  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {/* header */}
          <HStack justifyContent={'space-between'} gap={'50px'}>
            <Text
              fontSize="sm"
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.primary}
            >
              Chart Accuracy
            </Text>
            <InfoTooltip infoContent={
              <>
                <p>
                  <b>Chart Accuracy</b> tile displays the chart coding accuracy as both a percentage and a letter grade. Monitoring this metric helps you assess the precision of your coding practices and identify areas for improvement.
                </p>
              </>} />
          </HStack>
          {/* content */}

          <VStack mt={5}>
            <CircularProgress
              value={cq_score?.current_cq_score || 0}
              size="230px"
              thickness="2px"
              color={AppColors.primary}
            >
              <CircularProgressLabel>
                <VStack justifyContent={'center'} position={'relative'}>
                  <HStack position={'absolute'} top={-1}>
                    {cq_score?.cq_score_diff >= 0 ? (
                      <TriangleIcon />
                    ) : (
                      <TriangleRedIcon />
                    )}
                    <Text fontSize={'md'}>
                      {convertToPositiveDollar(cq_score.cq_score_diff)}
                    </Text>
                  </HStack>

                  <HStack>
                    <Text
                      fontFamily={FONT_FAMILY.ptSans}
                      mt={0}
                      fontWeight="md"
                    >
                      {cq_score?.current_cq_score || 0}
                    </Text>
                    <Text fontSize={'15px'} color={AppColors.lightGrey}>
                      %
                    </Text>
                  </HStack>

                  <Box position={'absolute'} top={20}>
                    <Text fontSize={'13px'} color={AppColors.lightGrey}>
                      Overall Accuracy
                    </Text>
                  </Box>
                </VStack>
              </CircularProgressLabel>
            </CircularProgress>
          </VStack>

          {/* footer */}
          <Box mb={2} ml={10}>
            <Text
              fontSize="35px"
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={600}
            >
              {cq_score?.grade || 0}
            </Text>
            <Text
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.lightGrey}
              fontSize={'sm'}
            >
              RI Score
            </Text>
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };
  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!cq_score) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };
  return renderMainContent();
}

ChartAccuracyComponents.propTypes = {
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  cq_score: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default ChartAccuracyComponents;
