import {
    Box,
    Text,
} from '@chakra-ui/react';

const TooltipContent = ({data}) => {
    return <Box position={'absolute'} top={5} left={1} width={400} bg={'white'} borderRadius={'xl'} boxShadow='xl' p={3}>
        {data.map((data, i) => {
            return <Box key={`${i}-user-type`} mt={2}>
                <Text fontSize={'xs'} fontWeight='bold'>
                    {data.title}
                </Text>
                <Text fontSize={'xs'} >
                    {data.description}
                </Text>
            </Box>
        })}
    </Box>
}
export default TooltipContent